import { FC, SVGProps } from "react";

export interface IconProps extends SVGProps<SVGSVGElement> {
  size?: number | string;
}

export interface SmartIconProps extends IconProps {
  isActive?: boolean;
}

const Icon: FC<IconProps> = ({ size = "20", ...rest }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox={`0 0 ${size} ${size}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  />
);

export default Icon;
