import { FC } from "react";
import {
  HStack,
  // Menu, MenuItem, MenuList
} from "@chakra-ui/react";

import { IMember } from "../../types/room";
import VCard from "../ui/VCard";
// import MeatballsMenuButton from "../ui/MeatballsMenuButton";

export interface IMemberProps {
  member: IMember;
}

const Member: FC<IMemberProps> = ({ member }) => (
  <HStack justify="space-between">
    <VCard uid={member.id} nickname={member.nickname} />

    {/* <Menu placement="bottom-end">
      <MeatballsMenuButton />
      <MenuList>
        <MenuItem>Kick</MenuItem>
      </MenuList>
    </Menu> */}
  </HStack>
);

export default Member;
