import create from "zustand";
import { devtools } from "zustand/middleware";
import { nanoid } from "nanoid";

import {
  TSnackbarAlert,
  ISnackbarAlertStatus,
  ISnackbarAlertQuestion,
  ISnackbarAlertProgress,
} from "../types/snackbar";

interface ISnackbartate {
  alerts: TSnackbarAlert[];
  addAlert: (
    alert: Omit<TSnackbarAlert, "id"> & Partial<Pick<TSnackbarAlert, "id">>
  ) => void;
  addQuestionAlert: (
    alert: Omit<ISnackbarAlertQuestion, "id" | "type"> &
      Partial<Pick<ISnackbarAlertQuestion, "id">>
  ) => string;
  addProgressAlert: (
    alert: Omit<ISnackbarAlertProgress, "id" | "type"> &
      Partial<Pick<ISnackbarAlertProgress, "id">>
  ) => string;
  addStatusAlert: (
    alert: Omit<ISnackbarAlertStatus, "id" | "type"> &
      Partial<Pick<TSnackbarAlert, "id">>
  ) => void;
  removeAlert: (id: TSnackbarAlert["id"]) => void;
  updateAlert: <T = TSnackbarAlert>(
    id: TSnackbarAlert["id"],
    alert: Partial<T>
  ) => void;
}

export const useSnackbarStore = create<ISnackbartate>()(
  devtools(
    (set, get) => ({
      alerts: [],
      addAlert: ({ duration = 5000, isClosable = true, ...alert }) =>
        set(({ alerts }) => ({
          alerts: [
            ...alerts,
            {
              id: nanoid(),
              duration,
              isClosable,
              ...alert,
            } as TSnackbarAlert,
          ],
        })),
      addQuestionAlert: ({
        duration = undefined,
        isClosable = false,
        ...alert
      }) => {
        const id = nanoid();

        set(({ alerts }) => ({
          alerts: [
            ...alerts,
            {
              id,
              type: "question",
              duration,
              isClosable,
              ...alert,
            },
          ],
        }));

        return id;
      },
      addProgressAlert: ({
        duration = undefined,
        isClosable = false,
        ...alert
      }) => {
        const id = nanoid();

        set(({ alerts }) => ({
          alerts: [
            ...alerts,
            {
              id,
              type: "progress",
              duration,
              isClosable,
              ...alert,
            },
          ],
        }));

        return id;
      },
      addStatusAlert: ({ duration = 5000, isClosable = true, ...alert }) =>
        set(({ alerts }) => ({
          alerts: [
            ...alerts,
            {
              id: nanoid(),
              type: "status",
              duration,
              isClosable,
              ...alert,
            },
          ],
        })),
      removeAlert: (id) =>
        set(({ alerts }) => ({
          alerts: alerts.filter((alert) => alert.id !== id),
        })),
      updateAlert: (id, alert) =>
        set(({ alerts }) => ({
          alerts: alerts.map((a) => {
            if (a.id === id) {
              return {
                ...a,
                ...alert,
              } as TSnackbarAlert;
            }
            return a;
          }),
        })),
    }),
    { enabled: process.env.NODE_ENV === "development" }
  )
);

export const useAddAlert = () => useSnackbarStore((state) => state.addAlert);
export const useAddStatusAlert = () =>
  useSnackbarStore((state) => state.addStatusAlert);
export const useAddQuestionAlert = () =>
  useSnackbarStore((state) => state.addQuestionAlert);
export const useAddProgressAlert = () =>
  useSnackbarStore((state) => state.addProgressAlert);
