import { FC } from "react";
import { Center } from "@chakra-ui/react";

import FilesList from "./FilesList";
import UploadButton from "./UploadButton";

const MobileFiles: FC = () => (
  <>
    <FilesList p="4" justify="center" />

    <Center position="fixed" bottom="4" left="4" right="4" zIndex="500">
      <UploadButton />
    </Center>
  </>
);

export default MobileFiles;
