import { FC, useContext, useState } from "react";
import { Icon, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { EnterIcon } from "../icons";
import RoomSocketConnectionContext from "../../contexts/RoomSocketConnectionContext";

const ChatInput: FC = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const { sendChatMessage } = useContext(RoomSocketConnectionContext);

  const handleSubmit = () => {
    if (value.trim().length > 0) {
      setValue("");
      sendChatMessage(value);
    }
  };

  return (
    <InputGroup>
      <Input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleSubmit();
          }
        }}
        placeholder={t("room.chat.input.placeholder")}
        boxShadow="0 3px 7px 0 rgba(0, 0, 0, 0.32)"
      />
      <InputRightElement role="button" onClick={() => handleSubmit()}>
        <Icon as={EnterIcon} w="1.25em" h="1.25em" color="gray.300" />
      </InputRightElement>
    </InputGroup>
  );
};

export default ChatInput;
