import { FC } from "react";
import { Box, Flex, VStack } from "@chakra-ui/react";

import { useRoomStore } from "../../stores/roomStore";
import Member from "./Member";
import SharePanel from "./SharePanel";

const MobileMembers: FC = () => {
  const members = useRoomStore((state) => state.members);

  return (
    <>
      <Flex flex="1">
        <VStack spacing="4" pt="4" px="4" align="stretch" w="full">
          {members?.map((member) => (
            <Member key={member.id} member={member} />
          ))}
        </VStack>
      </Flex>
      <Box position="sticky" bottom="0" p="4" flexShrink="0">
        <SharePanel />
      </Box>
    </>
  );
};

export default MobileMembers;
