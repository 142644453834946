import axios from "axios";

import type {
  ICreateRoomPayload,
  IFinishIpfsUploadSessionPayload,
  IPublicRoom,
  IRoom,
} from "../types/room";

export const getPublicList = () =>
  axios.get<IPublicRoom[]>("/rooms/list/public");

export const findByName = (roomname: string) =>
  axios.get<IRoom>(`/rooms/list/name/${roomname}`);

export const create = (data: ICreateRoomPayload) => axios.post("/rooms", data);

export const initIpfsUploadSession = (roomId: string) =>
  axios.post<{ sessionToken: string }>(`/rooms/${roomId}/ipfs/session/init`);

export const uploadIpfsSessionChunk = (roomId: string, data: FormData) =>
  axios.post(`/rooms/${roomId}/ipfs/session/file`, data);

export const finishIpfsUploadSession = (
  roomId: string,
  data: IFinishIpfsUploadSessionPayload
) => axios.post<{ hash: string }>(`/rooms/${roomId}/ipfs/session/finish`, data);
