import create from "zustand";
import { devtools } from "zustand/middleware";

import {
  IFile,
  IMember,
  IRoom,
  IJoinRequest,
  TChatEntry,
  IGetRoomResponse,
  IFileAccessRequest,
  IChatActivityNotification,
  IMessage,
  IFileAccessTokenPayload,
} from "../types/room";

interface IFileTransferSession {
  id: string;
  access: {
    token: string;
    payload: IFileAccessTokenPayload;
  };
  progressValue?: number;
  config: RTCConfiguration;
  pendingIceCandiates: RTCIceCandidate[];
}

export interface ISenderFileTransferSession extends IFileTransferSession {
  role: "sender";
  sdp: string;
  status: "idle" | "active";
}

interface IReceiverFileTransferSession extends IFileTransferSession {
  role: "receiver";
}

export type TFileTransferSession =
  | ISenderFileTransferSession
  | IReceiverFileTransferSession;

interface IRoomState {
  room?: IRoom;
  files: IFile[];
  chatEntries: TChatEntry[];
  members: IMember[];
  joinRequests: IJoinRequest[];
  fileAccessRequests: IFileAccessRequest[];
  fileDownloaders: Record<IFile["id"], IMember["id"][]>;
  fileTransferSessions: TFileTransferSession[];
  leaveStatus?: "removed" | "declined-request";
  joinStatus?: "needs-password" | "waiting-response";
  setStateFromGetRoomResponse: (res: IGetRoomResponse) => void;
  addChatNotification: (
    activity: IChatActivityNotification["activity"],
    user: IMember
  ) => void;
  addChatMessage: (message: IMessage) => void;
  upsertFile: (file: IFile) => void;
  updateFile: (id: IFile["id"], props: Partial<Omit<IFile, "id">>) => void;
  removeFile: (id: IFile["id"]) => void;
  upsertMember: (member: IMember) => void;
  removeMember: (id: IMember["id"]) => void;
  addJoinRequestDoc: (req: IJoinRequest) => void;
  removeJoinRequest: (id: IJoinRequest["id"]) => void;
  setLeaveStatus: (status: Required<IRoomState["leaveStatus"]>) => void;
  setJoinStatus: (joinStatus: Required<IRoomState["joinStatus"]>) => void;
  addFileAccessRequest: (req: IFileAccessRequest) => void;
  removeFileAccessRequest: (id: IFileAccessRequest["id"]) => void;
  addFileDownloader: (fileId: IFile["id"], downloaderId: IMember["id"]) => void;
  removeFileDownloader: (
    fileId: IFile["id"],
    downloaderId: IMember["id"]
  ) => void;
  addFileTransferSession: (id: string, sess: TFileTransferSession) => void;
  updateFileTransferSession: (
    id: string,
    sess: Partial<TFileTransferSession>
  ) => void;
  removeFileTransferSession: (id: string) => void;
  reset: () => void;
}

const initialDataState: Pick<
  IRoomState,
  | "room"
  | "files"
  | "chatEntries"
  | "members"
  | "joinRequests"
  | "leaveStatus"
  | "joinStatus"
  | "fileAccessRequests"
  | "fileDownloaders"
  | "fileTransferSessions"
> = {
  room: undefined,
  files: [],
  chatEntries: [],
  members: [],
  joinRequests: [],
  leaveStatus: undefined,
  joinStatus: undefined,
  fileAccessRequests: [],
  fileDownloaders: {},
  fileTransferSessions: [],
};

const upsertItem = <T extends { id: any }>(arr: T[], item: T, push = true) =>
  arr.some((i) => i.id === item.id)
    ? arr.map((i) =>
        i.id === item.id
          ? {
              ...i,
              ...item,
            }
          : i
      )
    : push
    ? [...arr, item]
    : [item, ...arr];

export const useRoomStore = create<IRoomState>()(
  devtools(
    (set, get) => ({
      ...initialDataState,
      setStateFromGetRoomResponse: (res) =>
        set({
          room: res.room,
          files: res.files,
          members: res.members,
        }),
      addChatNotification: (activity, user) =>
        set(({ chatEntries }) => ({
          chatEntries: [
            ...chatEntries,
            {
              type: "notification",
              entity: {
                activity,
                user,
              },
            },
          ],
        })),
      addChatMessage: (entity) =>
        set(({ chatEntries }) => ({
          chatEntries: [
            ...chatEntries,
            {
              type: "message",
              entity,
            },
          ],
        })),
      upsertFile: (file) =>
        set(({ files }) => ({
          files: upsertItem(files, file, false),
        })),
      updateFile: (id, props) =>
        set(({ files }) => ({
          files: files.map((file) =>
            file.id === id ? { ...file, ...props } : file
          ),
        })),
      removeFile: (id) =>
        set(({ files, fileAccessRequests }) => ({
          files: files.filter((file) => file.id !== id),
          fileAccessRequests: fileAccessRequests.filter(
            (req) => req.fileId !== id
          ),
        })),
      upsertMember: (member) =>
        set(({ members }) => ({
          members: upsertItem(members, member),
        })),
      removeMember: (id) => {
        set(
          ({
            members,
            joinRequests,
            files,
            fileAccessRequests,
            fileTransferSessions,
            fileDownloaders,
          }) => ({
            members: members.filter((m) => m.id !== id),
            files: files.filter((file) => file.ownerId !== id),
            fileAccessRequests: fileAccessRequests.filter(
              (req) => req.client.id !== id
            ),
            fileTransferSessions: fileTransferSessions.filter(
              ({
                access: {
                  payload: { receiverId, senderId },
                },
              }) => receiverId !== id && senderId !== id
            ),
            fileDownloaders: (
              Object.entries(fileDownloaders).map(([fileId, downloaders]) => [
                fileId,
                downloaders.filter((downloaderId) => downloaderId !== id),
              ]) as [string, string[]][]
            ).reduce(
              (fileDownloaders, [fileId, downloaders]) => ({
                ...fileDownloaders,
                [fileId]: downloaders,
              }),
              {}
            ),
            joinRequests: joinRequests.filter((req) => req.client.id !== id),
          })
        );
      },
      addJoinRequestDoc: (req) =>
        set(({ joinRequests }) => ({
          joinRequests: [...joinRequests, req],
        })),
      removeJoinRequest: (id) =>
        set(({ joinRequests }) => ({
          joinRequests: joinRequests.filter((req) => req.id !== id),
        })),
      setLeaveStatus: (leaveStatus) => set({ leaveStatus }),
      setJoinStatus: (joinStatus) => set({ joinStatus }),
      addFileAccessRequest: (req) =>
        set(({ fileAccessRequests }) => ({
          fileAccessRequests: [...fileAccessRequests, req],
        })),
      removeFileAccessRequest: (id) => {
        set({
          fileAccessRequests: get().fileAccessRequests.filter(
            (req) => req.id !== id
          ),
        });
      },
      addFileDownloader: (fileId, downloaderId) =>
        set(({ fileDownloaders }) => ({
          fileDownloaders: {
            ...fileDownloaders,
            [fileId]:
              fileId in fileDownloaders
                ? fileDownloaders[fileId].includes(downloaderId)
                  ? fileDownloaders[fileId]
                  : [...fileDownloaders[fileId], downloaderId]
                : [downloaderId],
          },
        })),
      removeFileDownloader: (fileId, downloaderId) =>
        set(({ fileDownloaders }) => {
          const downloaders =
            fileId in fileDownloaders
              ? fileDownloaders[fileId].filter((id) => id !== downloaderId)
              : [];

          if (downloaders.length === 0) {
            delete fileDownloaders[fileId];
          } else {
            fileDownloaders[fileId] = downloaders;
          }

          return {
            fileDownloaders: {
              ...fileDownloaders,
            },
          };
        }),
      addFileTransferSession: (id, sess) =>
        set(({ fileTransferSessions }) => ({
          fileTransferSessions: [...fileTransferSessions, sess],
        })),
      updateFileTransferSession: (id, sess) =>
        // @ts-ignore
        set(({ fileTransferSessions }) => ({
          fileTransferSessions: fileTransferSessions.map((s) =>
            s.id === id ? { ...s, ...sess } : s
          ),
        })),
      removeFileTransferSession: (id) =>
        set(({ fileTransferSessions }) => ({
          fileTransferSessions: fileTransferSessions.filter(
            (sess) => sess.id !== id
          ),
        })),
      reset: () => set({ ...initialDataState }),
    }),
    { enabled: process.env.NODE_ENV === "development" }
  )
);

export const useRoomStats = () =>
  useRoomStore((state) => ({
    files: state.files?.length ?? 0,
    messages: state.chatEntries.filter((entry) => entry.type === "message")
      .length,
    members: state.members?.length ?? 0,
  }));
export const useIsRoomLoaded = () => useRoomStore((state) => !!state.room);
