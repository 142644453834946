import { FC, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Box, Flex, Heading } from "@chakra-ui/react";
import status from "http-status";
import log from "loglevel";
import type { AxiosError } from "axios";

import { findByName } from "../../controllers/rooms";
import { useProfileStore } from "../../stores/profileStore";
import { useViewStore } from "../../stores/viewStore";
import useMediaQueryDesktop from "../../hooks/useMediaQueryDesktop";
import RoomSocketConnectionProvider from "../../providers/RoomSocketConnectionProvider";
import SetupProfileModal from "../base/SetupProfileModal";
import RoomHeader from "../base/RoomHeader";
import Sidebar from "../base/Sidebar";
import FilesList from "../base/FilesList";
import ChatPanel from "../base/ChatPanel";
import MobileNavigation from "../base/MobileNavigation";
import MobileChat from "../base/MobileChat";
import MobileFiles from "../base/MobileFiles";
import MobileMembers from "../base/MobileMembers";
import RoomPasswordModal from "../base/RoomPasswordModal";
import { useAddStatusAlert } from "../../stores/snackbarStore";
import { useRoomStore } from "../../stores/roomStore";
import RoomWaitingResponseModal from "../base/RoomWaitingResponseModal";
import SnackbarFileRequests from "../base/SnackbarFileRequests";
import AdsSlider from "../base/AdsSlider";
import SnackbarFileTransfers from "../base/SnackbarFileTransfers";
import { IRoom } from "../../types/room";

const Room: FC = () => {
  const { name } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [room, setRoom] = useState<IRoom | undefined>(state?.room);
  const isProfileSetup = useProfileStore((state) => !!state.profile);
  const isDesktop = useMediaQueryDesktop();
  const activeTab = useViewStore((state) => state.activeTab);
  const ActiveTabContent = useMemo(
    () =>
      ({
        chat: MobileChat,
        files: MobileFiles,
        members: MobileMembers,
      }[activeTab]),
    [activeTab]
  );
  const addAlert = useAddStatusAlert();
  const leaveStatus = useRoomStore((state) => state.leaveStatus);
  const joinStatus = useRoomStore((state) => state.joinStatus);
  const resetRoomState = useRoomStore((state) => state.reset);
  const hasRoomAnyFiles = useRoomStore(
    (state) => (state.files?.length ?? 0) > 0
  );

  useEffect(() => {
    const findRoom = async () => {
      try {
        const { data } = await findByName(name!);
        setRoom(data);
      } catch (err) {
        if ((err as AxiosError).response?.status === status.NOT_FOUND) {
          addAlert({
            status: "error",
            content: t("alerts.error.room.notFound"),
          });
        } else {
          log.error(err);
          addAlert({
            status: "error",
            content: t("alerts.error.default"),
          });
        }

        navigate("/");
      }
    };

    if (room?.name !== name) {
      findRoom();
    }

    return () => {
      resetRoomState();
    };
  }, [name]);

  useEffect(() => {
    if (leaveStatus) {
      addAlert({
        status: "error",
        content: t(`alerts.error.room.leave.${leaveStatus}`),
      });
      navigate("/");
    }
  }, [leaveStatus]);

  return (
    <>
      <Helmet>
        <title>{t("room.title", { name })}</title>
      </Helmet>

      <RoomSocketConnectionProvider room={room}>
        <Box
          // boxSizing="content-box"
          w="full"
          h="full"
          // maxW="1392px"
          mx="auto"
          px={{ lg: "4" }}
          display="flex"
          flexDir="column"
          {...{
            [isDesktop ? "height" : "minHeight"]: "100vh",
          }}
        >
          <RoomHeader />
          {!isDesktop && <MobileNavigation />}
          {isDesktop ? (
            <Flex grow="1" overflow="hidden">
              <Box py="4" flexShrink="0" w="full" maxW="320px" minH="320px">
                <ChatPanel />
              </Box>

              <Box flex="1" p="4" overflow="auto">
                {hasRoomAnyFiles && (
                  <Heading mb="3" size="md">
                    {t("room.files.title")}
                  </Heading>
                )}
                <AdsSlider mb="3" />
                <FilesList />
              </Box>

              <Sidebar />
            </Flex>
          ) : (
            <>
              <AdsSlider />
              <ActiveTabContent />
            </>
          )}
        </Box>

        {isProfileSetup && !!room && (
          <>
            <RoomPasswordModal isOpen={joinStatus === "needs-password"} />
            <RoomWaitingResponseModal
              isOpen={joinStatus === "waiting-response"}
            />
          </>
        )}

        <SnackbarFileRequests />
        <SnackbarFileTransfers />
      </RoomSocketConnectionProvider>

      <SetupProfileModal isOpen={!isProfileSetup} />
    </>
  );
};

export default Room;
