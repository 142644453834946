import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const WarningIcon: FC<IconProps> = (props) => (
  <Icon size="24" {...props}>
    <defs>
      <radialGradient
        id="warning-icon-d"
        cx="69.98%"
        cy="15.562%"
        r="141.625%"
        fx="69.98%"
        fy="15.562%"
        gradientTransform="scale(-.81582 -1) rotate(-68.087 -.31 1.153)"
      >
        <stop offset="0%" stopColor="#F8CE71" />
        <stop offset="100%" stopColor="#FFA828" />
      </radialGradient>
      <radialGradient
        id="warning-icon-c"
        cx="73.523%"
        cy="9.275%"
        r="104.393%"
        fx="73.523%"
        fy="9.275%"
        gradientTransform="scale(-.81582 -1) rotate(-70.382 -.215 1.16)"
      >
        <stop offset="0%" stopColor="#C67923" />
        <stop offset="100%" stopColor="#94440F" />
      </radialGradient>
      <filter
        id="warning-icon-a"
        width="187.8%"
        height="207.6%"
        x="-43.9%"
        y="-43%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 1 0 0 0 0 0.596368015 0 0 0 0 0 0 0 0 0.428676792 0"
        />
      </filter>
      <path
        id="warning-icon-b"
        d="M13.059.504a3 3 0 0 1 .832.832l8.394 12.592a3 3 0 0 1-2.496 4.664H3a3 3 0 0 1-2.496-4.664L8.898 1.336a3 3 0 0 1 4.16-.832Zm-.665 13.088h-2v2h2v-2Zm0-8h-2v6h2v-6Z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(.606 2.408)">
      <use
        xlinkHref="#warning-icon-b"
        fill="#000"
        filter="url(#warning-icon-a)"
      />
      <path
        fill="url(#warning-icon-d)"
        stroke="url(#warning-icon-c)"
        // @ts-ignore
        strokeLinejoin="square"
        d="M12.781.92a2.5 2.5 0 0 1 .578.534l.116.16 8.394 12.591c.383.575.497 1.25.371 1.877a2.5 2.5 0 0 1-2.24 2l-.211.01H3c-.69 0-1.315-.28-1.768-.732a2.5 2.5 0 0 1-.422-2.974l.11-.18L9.314 1.613c.383-.575.963-.94 1.59-1.065a2.492 2.492 0 0 1 1.877.371Zm.113 12.172h-3v3h3v-3Zm0-8h-3v7h3v-7Z"
      />
    </g>
  </Icon>
);

export default WarningIcon;
