import { FC, useEffect } from "react";
import { Box, Flex, VStack } from "@chakra-ui/react";

import ChatInput from "./ChatInput";
import ChatEntiresList from "./ChatEntiresList";
import { useRoomStore } from "../../stores/roomStore";

const MobileChat: FC = () => {
  const chatEntiresCount = useRoomStore((state) => state.chatEntries.length);

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [chatEntiresCount]);

  return (
    <>
      <Flex flex="1" direction="column-reverse">
        <VStack spacing="3" pt="4" px="4" alignItems="stretch">
          <ChatEntiresList />
        </VStack>
      </Flex>
      <Box position="sticky" bottom="0" p="4" flexShrink="0">
        <ChatInput />
      </Box>
    </>
  );
};

export default MobileChat;
