import { FC } from "react";
import { Button, FormControl, FormErrorMessage, Input } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { AxiosError } from "axios";

import { findByName } from "../../controllers/rooms";
import { useAddStatusAlert } from "../../stores/snackbarStore";

export interface IJoinRoomFormProps {
  onJoin: (room: {
    id: string;
    name: string;
    ownerId: string;
    isProtected: boolean;
  }) => void;
}

interface IJoinRoomFormValues {
  name: string;
}

const JoinRoomForm: FC<IJoinRoomFormProps> = ({ onJoin }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
  } = useForm<IJoinRoomFormValues>();
  const addAlert = useAddStatusAlert();

  const onSubmit = async ({ name }: IJoinRoomFormValues) => {
    try {
      const { data: room } = await findByName(name);
      onJoin(room);
    } catch (err) {
      if ((err as AxiosError).response?.status === 404) {
        setError("name", {
          type: "notFound",
        });
      } else {
        console.error(err);
        addAlert({
          status: "error",
          content: t("alerts.error.default"),
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={!!errors.name}>
        <Input
          placeholder={t("home.join.placeholder")}
          autoCorrect="off"
          autoCapitalize="none"
          {...register("name", {
            required: true,
            minLength: 1,
            maxLength: 20,
            pattern: /^[a-z0-9-_]*$/i,
          })}
        />
        {errors.name && (
          <FormErrorMessage>
            {t(`validation.${errors.name.type}`, {
              field: t("home.join.label"),
            })}
          </FormErrorMessage>
        )}
      </FormControl>

      <Button type="submit" isLoading={isSubmitting} w="full" mt="3">
        {t("home.join.submit")}
      </Button>
    </form>
  );
};

export default JoinRoomForm;
