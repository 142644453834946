export const AVATAR_COLORS = [
  "#704EE1",
  "#C63036",
  "#D09D2B",
  "#B0D02B",
  "#66D02B",
  "#2BD081",
  "#2BD0AF",
  "#2BC1D0",
  "#2B8ED0",
  "#2F68E1",
  "#2F43E1",
  "#4E2FE1",
  "#752FE1",
  "#A52FE1",
  "#D56B3F",
  "#68A764",
  "#64A1A7",
  "#476EC2",
];
