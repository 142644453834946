import { FC } from "react";

import Icon, { SmartIconProps } from "./Icon";

const RequestSmartIcon: FC<SmartIconProps> = ({ isActive = true, ...rest }) => (
  <Icon size="24" {...rest}>
    <defs>
      <linearGradient
        x1="50%"
        y1="-17.541%"
        x2="50%"
        y2="53.419%"
        id="request-smart-icon-a"
      >
        <stop stopColor="#9C9696" offset="0%" />
        <stop stopColor="#6F6F6F" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="-17.541%"
        x2="50%"
        y2="53.419%"
        id="request-smart-icon-a-active"
      >
        <stop stopColor="#20C494" offset="0%" />
        <stop stopColor="#008C63" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        id="request-smart-icon-d"
      >
        <stop stopColor="#C8C8C8" offset="0%" />
        <stop stopColor="#979797" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        id="request-smart-icon-d-active"
      >
        <stop stopColor="#00F8BB" offset="0%" />
        <stop stopColor="#26AA7F" offset="100%" />
      </linearGradient>
      <filter
        x="-9.9%"
        y=".7%"
        width="119.9%"
        height="125.3%"
        filterUnits="objectBoundingBox"
        id="request-smart-icon-b"
      >
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation=".5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.203999126 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path
        d="m9.244 16.464 3.592-6.532a1 1 0 0 1 1.753 0l3.593 6.532a1 1 0 0 1-1.069 1.464l-3.208-.629a1 1 0 0 0-.384 0l-3.209.629a1 1 0 0 1-1.068-1.464Z"
        id="request-smart-icon-c"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect
        stroke={isActive ? "#26E1A4" : "#6A6A6A"}
        fill={isActive ? "#484848" : "#343434"}
        x=".5"
        y=".5"
        width="23"
        height="23"
        rx="5"
      />
      <rect
        fill={`url(#request-smart-icon-a${isActive ? "-active" : ""})`}
        x="5"
        y="5"
        width="9"
        height="9"
        rx="2"
      />
      <g transform="rotate(-40 13.713 13.339)">
        <use
          fill="#000"
          filter="url(#request-smart-icon-b)"
          xlinkHref="#request-smart-icon-c"
        />
        <path
          stroke={isActive ? "#23CD99" : "#9F9898"}
          d="M13.573 9.934c.464.088.532.156.578.239l3.593 6.532a.498.498 0 0 1-.535.732l-3.299-.646-3.694.646a.498.498 0 0 1-.534-.732l3.593-6.532a.498.498 0 0 1 .298-.24Z"
          // @ts-ignore
          strokeLinejoin="square"
          fill={`url(#request-smart-icon-d${isActive ? "-active" : ""})`}
        />
      </g>
    </g>
  </Icon>
);

export default RequestSmartIcon;
