import { FC } from "react";
import { RoomTransferProtocol } from "../../types/room";
import CloudSmartIcon from "./CloudSmartIcon";
import IpfsSmartIcon from "./IpfsSmartIcon";
import PeerSmartIcon from "./PeerSmartIcon";

import type { SmartIconProps } from "./Icon";

export const roomTransferProtocolIcons: Record<
  RoomTransferProtocol,
  FC<SmartIconProps>
> = {
  [RoomTransferProtocol.P2P]: PeerSmartIcon,
  [RoomTransferProtocol.Webtorrent]: CloudSmartIcon,
  [RoomTransferProtocol.IPFS]: IpfsSmartIcon,
};
