import create from "zustand";
import { devtools } from "zustand/middleware";
import log from "loglevel";

import { IAdBanner } from "../types/ads";
import { getBanners } from "../controllers/ads";

interface IAdsStoreState {
  isFetching: boolean;
  isFetched: boolean;
  adBanners: IAdBanner[];
  fetchAdBanners: () => Promise<void>;
}

export const useAdsStore = create<IAdsStoreState>()(
  devtools(
    (set) => ({
      isFetched: false,
      isFetching: false,
      adBanners: [],
      fetchAdBanners: async () => {
        set({ isFetching: true });
        try {
          const { data: adBanners } = await getBanners();
          set({ isFetched: true, isFetching: false, adBanners });
        } catch (err) {
          log.error(err);
          set({ isFetched: false, isFetching: false });
        }
      },
    }),
    { enabled: process.env.NODE_ENV === "development" }
  )
);
