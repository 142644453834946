import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const MeatballsIcon: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      d="M10 11.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm-6 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm12 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Icon>
);

export default MeatballsIcon;
