export enum RoomTransferProtocol {
  P2P = "p2p",
  IPFS = "ipfs",
  Webtorrent = "webtorrent",
}

export enum RoomAccess {
  All = "all",
  Password = "password",
  Request = "request",
}

export interface IGetRoomResponse {
  room: IRoom;
  files: {
    id: string;
    ownerId: string;
    name: string;
    ext?: string;
    size: number;
    protected?: boolean;
    uploadedAt: string;
  }[];
  members: {
    id: string;
    nickname: string;
  }[];
}

export interface IRoom {
  id: string;
  ownerId: string;
  name: string;
  transferProtocol: RoomTransferProtocol;
  access: RoomAccess;
  isProtected: boolean;
  allowUploadOthers: boolean;
}

export interface IPublicRoom
  extends Pick<IRoom, "id" | "name" | "transferProtocol"> {
  membersCount: number;
  filesCount: number;
}

export interface IFile {
  id: string;
  ownerId: string;
  name: string;
  size: number;
  protected?: boolean;
  magnetUri?: string;
  ipfsHash?: string;
  uploadedAt: Date | string;
}

export interface IMessageEntry {
  type: "message";
  entity: IMessage;
}

export interface IMessage {
  id: string;
  content: string;
  user: {
    id: string;
    nickname: string;
  };
  sentAt: Date | string;
}

export interface IChatActivityNotificationEntry {
  type: "notification";
  entity: IChatActivityNotification;
}

export interface IChatActivityNotification {
  activity: "join" | "leave";
  user: IMember;
}

export type TChatEntry = IMessageEntry | IChatActivityNotificationEntry;

export interface IMember {
  id: string;
  nickname: string;
}

export interface IJoinRequest {
  id: string;
  client: IMember;
}

export interface IFileAccessRequest {
  id: string;
  fileId: string;
  client: IMember;
}

export interface ICreateRoomPayload {
  name: string;
  password?: string;
  transferProtocol: RoomTransferProtocol;
  access: RoomAccess;
  allowUploadOthers: boolean;
}

export interface IFileAccessTokenPayload {
  role: "sender" | "receiver";
  fileId: string;
  roomId: string;
  senderId: string;
  receiverId: string;
}

export enum FileUploadingStatus {
  Started = "started",
  Finished = "finished",
}

export interface IFinishIpfsUploadSessionPayload {
  token: string;
  filename: string;
}
