import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { io, Socket } from "socket.io-client";
import log from "loglevel";
import { nanoid } from "nanoid";
import jwtDecode from "jwt-decode";
import { useTranslation } from "react-i18next";
import type Webtorrent from "webtorrent";
import * as Sentry from "@sentry/react";

import * as roomsApi from "../controllers/rooms";
import { useChatConnectionStore } from "../stores/chatConnectionStore";
import { useProfileStore } from "../stores/profileStore";
import RoomSocketConnectionContext, {
  IRoomSocketConnectionContextValue,
} from "../contexts/RoomSocketConnectionContext";
import {
  IFileAccessTokenPayload,
  IFile,
  IFileAccessRequest,
  IGetRoomResponse,
  IJoinRequest,
  IMember,
  IMessage,
  FileUploadingStatus,
  RoomTransferProtocol,
  IRoom,
} from "../types/room";
import {
  ISenderFileTransferSession,
  TFileTransferSession,
  // useIsRoomLoaded,
  useRoomStore,
} from "../stores/roomStore";
import {
  useAddProgressAlert,
  useAddStatusAlert,
  useSnackbarStore,
} from "../stores/snackbarStore";
import { TMessageResponse } from "../types/socket";
import { minmax } from "../utils/math";
import { downloadUrl } from "../utils/dom";

const IPFS_GATEWAY_URL_PREFIX = "https://ipfs.io/ipfs/";

const getFileSessId = (
  role: "receiver" | "sender",
  {
    fileId,
    senderId,
    receiverId,
  }: Pick<IFileAccessTokenPayload, "fileId" | "senderId" | "receiverId">
) => `${role}:${fileId}:${senderId}:${receiverId}`;

interface IRoomSocketConnectionProviderProps {
  room?: IRoom;
}

const RoomSocketConnectionProvider: FC<
  PropsWithChildren<IRoomSocketConnectionProviderProps>
> = ({ room, children }) => {
  const { t } = useTranslation();
  const addAlert = useAddStatusAlert();
  const addProgressAlert = useAddProgressAlert();
  // const isRoomLoaded = useIsRoomLoaded();
  const removeAlert = useSnackbarStore((state) => state.removeAlert);
  const profile = useProfileStore((state) => state.profile);
  const accessToken = useProfileStore((state) => state.accessToken);
  const status = useChatConnectionStore((state) => state.status);
  const setStatus = useChatConnectionStore((state) => state.setStatus);
  const setStateFromGetRoomResponse = useRoomStore(
    (state) => state.setStateFromGetRoomResponse
  );
  // const addChatNotification = useRoomStore(
  //   (state) => state.addChatNotification
  // );
  const files = useRoomStore((state) => state.files || []);
  const upsertFileDoc = useRoomStore((state) => state.upsertFile);
  const updateFileDoc = useRoomStore((state) => state.updateFile);
  const removeFileDoc = useRoomStore((state) => state.removeFile);
  const upsertMemberDoc = useRoomStore((state) => state.upsertMember);
  const removeMemberDoc = useRoomStore((state) => state.removeMember);
  const addJoinRequestDoc = useRoomStore((state) => state.addJoinRequestDoc);
  const removeJoinRequestDoc = useRoomStore((state) => state.removeJoinRequest);
  const setLeaveStatus = useRoomStore((state) => state.setLeaveStatus);
  const setJoinStatus = useRoomStore((state) => state.setJoinStatus);
  const addFileAccessRequestDoc = useRoomStore(
    (state) => state.addFileAccessRequest
  );
  const addFileDownloaderDoc = useRoomStore((state) => state.addFileDownloader);
  const removeFileDownloaderDoc = useRoomStore(
    (state) => state.removeFileDownloader
  );
  const addChatMessageDoc = useRoomStore((state) => state.addChatMessage);

  const fileTransferSessions = useRoomStore(
    (state) => state.fileTransferSessions
  );
  const addFileTransferSession = useRoomStore(
    (state) => state.addFileTransferSession
  );
  const updateFileTransferSession = useRoomStore(
    (state) => state.updateFileTransferSession
  );
  const removeFileTransferSession = useRoomStore(
    (state) => state.removeFileTransferSession
  );

  const webtorrentClientRef = useRef<Webtorrent.Instance | null>(null);
  // const ipfsClientRef = useRef<Web3Storage | null>(null);
  const socketRef = useRef<Socket | undefined>();
  const fileTransferConnsRef = useRef<Record<string, RTCPeerConnection>>({});
  const filesRef = useRef<Record<string, File>>({});

  const join: IRoomSocketConnectionContextValue["join"] = useCallback(
    ({ password, joinToken } = {}) =>
      new Promise((resolve, reject) => {
        socketRef.current!.emit(
          "join",
          { roomId: room!.id, password, joinToken },
          (
            res: TMessageResponse<
              {},
              { reason: "password" | "request-sent" | "room-not-found" }
            >
          ) => {
            if (!socketRef.current) {
              reject();
              return;
            }

            if (res.status === "error") {
              log.debug("SOCKET: join room error", socketRef.current!.id, res);
              if (res.payload.reason === "request-sent") {
                setJoinStatus("waiting-response");
              } else if (res.payload.reason === "room-not-found") {
                setLeaveStatus("removed");
              } else {
                addAlert({
                  status: "error",
                  content: t("alerts.error.default"),
                });
              }
              reject(res.payload);
              return;
            }
            log.debug("SOCKET: joined room", socketRef.current!.id, room!.id);

            setStatus("joined");
            // if (profile) {
            //   addChatNotification("join", {
            //     id: profile!.uid,
            //     nickname: profile!.nickname,
            //   });
            // }

            resolve();
          }
        );
      }),
    [room?.id, profile]
  );

  const remove: IRoomSocketConnectionContextValue["remove"] = useCallback(
    () =>
      new Promise((resolve, reject) => {
        socketRef.current!.emit("remove", { roomId: room!.id }, () => {
          if (!socketRef.current) {
            reject();
            return;
          }

          log.debug("SOCKET: room removed", socketRef.current!.id, room!.id);

          setLeaveStatus("removed");
          resolve();
        });
      }),
    [room?.id]
  );

  const responseToJoinRequest: IRoomSocketConnectionContextValue["responseToJoinRequest"] =
    useCallback(
      (req, action) =>
        new Promise((resolve, reject) => {
          socketRef.current!.emit(
            "join-request-response",
            {
              roomId: room!.id,
              clientId: req.client.id,
              action,
            },
            () => {
              removeJoinRequestDoc(req.id);
              resolve();
            }
          );
        }),
      [room?.id]
    );

  const loadRoom = useCallback(
    () =>
      new Promise<IGetRoomResponse>((resolve, reject) => {
        socketRef.current!.emit(
          "get-room",
          { roomId: room!.id },
          (res: IGetRoomResponse) => {
            if (!socketRef.current) {
              reject();
              return;
            }

            log.debug("Fetched room", res);
            setStateFromGetRoomResponse(res);
            resolve(res);
          }
        );
      }),
    [room?.id]
  );

  // const initIpfsClient = useCallback(async () => {
  //   if (!ipfsClientRef.current) {
  //     ipfsClientRef.current = axios.create({
  //       baseURL: "https://api.pinata.cloud",
  //       headers: {
  //         Authorization: `Bearer ${process.env.REACT_APP_PINATA_JWT}`,
  //       },
  //     });
  //   }
  //   return ipfsClientRef.current;
  // }, []);

  const initWebtorrent = useCallback(async () => {
    if (!webtorrentClientRef.current) {
      const { default: Webtorrent } = await import("webtorrent");
      const rtcConfig = await new Promise((resolve) => {
        socketRef.current!.emit(
          "get-rtc-config",
          { roomId: room!.id },
          (config: RTCConfiguration) => {
            log.debug("Got RTC config", config);

            resolve(config);
          }
        );
      });

      webtorrentClientRef.current = new Webtorrent({
        tracker: {
          rtcConfig,
        },
      });
    }
    return webtorrentClientRef.current!;
  }, [room?.id]);

  const addFile: IRoomSocketConnectionContextValue["addFile"] = useCallback(
    async (file, needsApproval) => {
      let fileBasePayload = {
        roomId: room!.id,
        name: file.name.slice(0, 100),
        ext: file.name.split(".").pop()?.slice(0, 10),
        size: file.size,
        needsApproval,
      };

      const emitAddFileEvent = (payload: any) =>
        new Promise<IFile>((resolve, reject) => {
          socketRef.current!.emit(
            "add-file",
            payload,
            ({ status, payload }: TMessageResponse<{ file: IFile }>) => {
              if (status === "success") {
                resolve(payload.file);
              } else {
                reject(payload);
              }
            }
          );
        });

      let addedFile: IFile;

      if (room!.transferProtocol === RoomTransferProtocol.P2P) {
        addedFile = await emitAddFileEvent(fileBasePayload);
        filesRef.current[addedFile.id] = file;
      } else if (room!.transferProtocol === RoomTransferProtocol.Webtorrent) {
        log.info("Seeding torrent for file", file);

        try {
          const webtorrent = await initWebtorrent();

          const magnetUri = await new Promise<string>((resolve, reject) => {
            const torrent = webtorrent.seed(
              file,
              {
                announce: [
                  "wss://tracker.btorrent.xyz",
                  "wss://tracker.openwebtorrent.com",
                ],
              },
              (torrent) => {
                log.info("Seeded torrent for file", file, torrent);
                resolve(torrent.magnetURI);
              }
            );

            torrent.on("error", (err) => reject(err));
          });

          await emitAddFileEvent({
            ...fileBasePayload,
            magnetUri,
          });
        } catch (err) {
          log.error("Error seeding torrent for file", file, err);
          throw err;
        }
        // initWebtorrent()
        //   .then((webtorrent) => {
        //     const torrent = webtorrent.seed(
        //       file,
        //       {
        //         announce: [
        //           "wss://tracker.btorrent.xyz",
        //           "wss://tracker.openwebtorrent.com",
        //         ],
        //       },
        //       (torrent) => {
        //         log.info("Seeded torrent for file", file, torrent);
        //         emitAddFileEvent({
        //           ...fileBasePayload,
        //           magnetUri: torrent.magnetURI,
        //         });
        //       }
        //     );

        //     torrent.on("error", (err) => {
        //       log.error("Error seeding torrent for file", file, err);
        //       reject(err);
        //     });
        //   })
        //   .catch(reject);
      } else if (room!.transferProtocol === RoomTransferProtocol.IPFS) {
        const alertId = addProgressAlert({
          content: t("alerts.fileSharing.ipfs"),
        });

        try {
          const {
            data: { sessionToken },
          } = await roomsApi.initIpfsUploadSession(room!.id);

          const chunkSize = 30 * 1024 * 1024;
          let offset = 0;

          while (offset < file.size) {
            const chunk = file.slice(offset, offset + chunkSize);

            const formData = new FormData();
            formData.append("file", chunk);
            formData.append("token", sessionToken);

            await roomsApi.uploadIpfsSessionChunk(room!.id, formData);

            offset += chunkSize;
          }

          const {
            data: { hash: ipfsHash },
          } = await roomsApi.finishIpfsUploadSession(room!.id, {
            token: sessionToken,
            filename: file.name,
          });

          addedFile = await emitAddFileEvent({ ...fileBasePayload, ipfsHash });
        } catch (err) {
          log.error("Error while adding file to IPFS node", err);
          throw err;
        } finally {
          removeAlert(alertId);
        }
      }

      upsertFileDoc(addedFile!);
      return addedFile!;
    },
    [room?.id, room?.transferProtocol]
  );

  const responseFileAccess: IRoomSocketConnectionContextValue["responseFileAccess"] =
    useCallback(
      (req, status) =>
        new Promise((resolve, reject) => {
          socketRef.current!.emit(
            "response-file-access",
            {
              roomId: room!.id,
              fileId: req.fileId,
              clientId: req.client.id,
              status,
            },
            () => {
              resolve();
            }
          );
        }),
      [room?.id]
    );

  const deleteFile: IRoomSocketConnectionContextValue["deleteFile"] =
    useCallback(
      (fileId) =>
        new Promise((resolve, reject) => {
          socketRef.current!.emit(
            "delete-file",
            {
              roomId: room!.id,
              fileId,
            },
            async () => {
              try {
                delete filesRef.current[fileId];
                removeFileDoc(fileId);

                resolve();
              } catch (err) {
                reject(err);
              }
            }
          );
        }),
      [room?.id]
    );

  const startFileDownload = useCallback(
    (accessToken: string, allFiles = files) => {
      const accessPayload = jwtDecode(accessToken) as IFileAccessTokenPayload;
      const file = allFiles.find((file) => file.id === accessPayload.fileId)!;

      if (!file) {
        log.warn("Missing file to download", accessPayload);
        return;
      }

      if (room!.transferProtocol === RoomTransferProtocol.Webtorrent) {
        socketRef.current!.emit(
          "get-file-magnet-uri",
          { accessToken },
          ({ status, payload }: TMessageResponse<{ magnetUri: string }>) => {
            if (status === "success") {
              const { magnetUri } = payload;

              updateFileDoc(file.id, { magnetUri });

              const alertId = addProgressAlert({
                content: t("alerts.fileDownload.torrent", { fileId: file.id }),
              });

              const handleComplete = (err?: string | Error) => {
                removeAlert(alertId);
                socketRef.current!.emit("update-file-uploadig-status", {
                  status: FileUploadingStatus.Finished,
                  roomId: room!.id,
                  fileId: file.id,
                });

                if (err) {
                  Sentry.captureException(err);
                  log.error("Error adding torrent", file, err);

                  addAlert({
                    status: "error",
                    content: t("alerts.error.default"),
                  });
                }
              };

              const handleTorrent = (torrent: Webtorrent.Torrent) => {
                const torrentFile = torrent.files.find(
                  (torrentFile) => torrentFile.name === file.name
                );
                if (!torrentFile) {
                  handleComplete();
                  log.warn("Missing torrent file to download", file);
                  return;
                }

                log.info("Got torrent for file", file, torrent);

                torrentFile.getBlobURL((err, blobUrl) => {
                  handleComplete(err);

                  if (err) {
                    log.warn("Error getting blob URL", file, torrentFile);
                    return;
                  }

                  log.info("Got torrent blob url for file", file, blobUrl);

                  downloadUrl(blobUrl!, file.name);
                  URL.revokeObjectURL(blobUrl!);
                });
              };

              socketRef.current!.emit("update-file-uploadig-status", {
                status: FileUploadingStatus.Started,
                roomId: room!.id,
                fileId: file.id,
              });

              initWebtorrent()
                .then((webtorrent) => {
                  const torrent = webtorrent.get(magnetUri);
                  if (torrent) {
                    handleTorrent(torrent);
                  } else {
                    webtorrent
                      .add(magnetUri, handleTorrent)
                      .on("error", handleComplete);
                  }
                })
                .catch(handleComplete);
            } else {
              // TODO:
            }
          }
        );
      } else if (room!.transferProtocol === RoomTransferProtocol.IPFS) {
        socketRef.current!.emit(
          "get-file-ipfs-hash",
          { accessToken },
          ({ status, payload }: TMessageResponse<{ ipfsHash: string }>) => {
            if (status === "success") {
              const { ipfsHash } = payload;

              updateFileDoc(file.id, { ipfsHash });
              downloadUrl(`${IPFS_GATEWAY_URL_PREFIX}${ipfsHash}`, file.name);
            } else {
              addAlert({
                status: "error",
                content: t("alerts.error.default"),
              });
            }
          }
        );
      } else if (room!.transferProtocol === RoomTransferProtocol.P2P) {
        socketRef.current!.emit(
          "get-rtc-config",
          { roomId: room!.id },
          (config: RTCConfiguration) => {
            log.debug("Got RTC config", config);

            const id = getFileSessId("receiver", accessPayload);

            addFileTransferSession(id, {
              id,
              role: "receiver",
              access: {
                token: accessToken,
                payload: accessPayload,
              },
              config,
              pendingIceCandiates: [],
            });
          }
        );
      }
    },
    [room?.id, room?.transferProtocol, files, t, addAlert]
  );

  const downloadFile: IRoomSocketConnectionContextValue["downloadFile"] =
    useCallback(
      (fileId) =>
        new Promise((resolve, reject) => {
          const file = files.find((file) => file.id === fileId);
          if (!file) {
            log.warn("Missing file to download", fileId);
            reject("Missing file");
            return;
          }

          if (file.ipfsHash) {
            downloadUrl(
              `${IPFS_GATEWAY_URL_PREFIX}${file.ipfsHash}`,
              file.name
            );
            return;
          } else if (file.magnetUri) {
            // TODO:
          }

          socketRef.current!.emit(
            "request-file-access",
            {
              roomId: room!.id,
              fileId,
            },
            ({ status, payload }: TMessageResponse<{ token?: string }>) => {
              if (status === "success") {
                const { token } = payload;
                if (token) {
                  startFileDownload(token);
                  resolve(true);
                } else {
                  addAlert({
                    status: "success",
                    content:
                      "This file is protected, request has been sent to the owner",
                  });
                  resolve(false);
                }
              } else {
                log.error("Error requesting file access", payload);
                addAlert({
                  status: "error",
                  content: t("alerts.error.default"),
                });
                reject(payload);
              }
            }
          );
        }),
      [room?.id, files, startFileDownload]
    );

  const sendChatMessage: IRoomSocketConnectionContextValue["sendChatMessage"] =
    useCallback(
      (content) =>
        new Promise((resolve, reject) => {
          socketRef.current!.emit(
            "chat-message",
            {
              roomId: room!.id,
              content,
            },
            ({ status, payload }: TMessageResponse<{ message: IMessage }>) => {
              if (status === "success") {
                addChatMessageDoc(payload.message);
                resolve(payload.message);
              } else {
                log.error("Erroring sending chat message", payload);
                addAlert({
                  status: "error",
                  content: t("alerts.error.default"),
                });
                reject(payload);
              }
            }
          );
        }),
      [room?.id, addAlert, t]
    );

  const value = useMemo(
    () => ({
      join,
      remove,
      responseToJoinRequest,
      addFile,
      responseFileAccess,
      deleteFile,
      downloadFile,
      sendChatMessage,
    }),
    [
      join,
      remove,
      responseToJoinRequest,
      addFile,
      responseFileAccess,
      deleteFile,
      downloadFile,
      sendChatMessage,
    ]
  );

  useEffect(() => {
    // * Wait for user to set profile info before allowing to connect to the room
    if (!accessToken || !room) {
      return;
    }

    socketRef.current = io(process.env.REACT_APP_WEBSOCKET_URL, {
      path: process.env.REACT_APP_WEBSOCKET_PATH,
      // transports: ["websocket"],
    });
    const socket = socketRef.current;

    setStatus("connecting");

    socket.on("connect", () => {
      log.debug("SOCKET: connected", socket.id);

      setStatus("connected");

      socket.emit("authenticate", { token: accessToken }, () => {
        log.debug("SOCKET: authenticated", socket.id);

        setStatus("authenticated");
      });
    });

    socket.on("connect_error", (err) => {
      log.error("SOCKET: connection error", socket.id, err);

      setStatus("disconnected", err);
    });

    socket.on("disconnect", () => {
      log.debug("SOCKET: disconnected");

      setStatus("disconnected");
    });

    socket.on("room-removed", () => {
      setLeaveStatus("removed");
    });

    socket.on("member-joined", ({ client }: { client: IMember }) => {
      log.debug("Member joined", client);

      upsertMemberDoc(client);
      // addChatNotification("join", client);
    });
    socket.on("member-left", ({ client }: { client: IMember }) => {
      log.debug("Member left", client);

      removeMemberDoc(client.id);
      // addChatNotification("leave", client);
    });

    socket.on("join-request", (req: Omit<IJoinRequest, "id">) => {
      log.debug("SOCKET: Join request", req);

      addJoinRequestDoc({ id: nanoid(), ...req });
    });
    socket.on("join-request-accepted", ({ token }: { token: string }) => {
      log.debug("SOCKET: Join request accepted", token);

      join({ joinToken: token });
    });
    socket.on("join-request-declined", () => {
      log.debug("SOCKET: Join request declined");

      setLeaveStatus("declined-request");
    });

    socket.on("file-added", (file: IFile) => {
      log.debug("Event file-added: ", file);
      upsertFileDoc(file);
    });
    socket.on("file-removed", ({ fileId }: { fileId: IFile["id"] }) => {
      log.debug("Event file-removed: ", fileId);
      removeFileDoc(fileId);
    });

    socket.on(
      "file-access-requested",
      (req: Omit<IFileAccessRequest, "id">) => {
        log.debug("Event file-access-requested: ", req);

        const file = useRoomStore
          .getState()
          .files.find((file) => file.id === req.fileId);
        if (!file) {
          log.warn("Requested nonexisting file: ", file);
          return;
        }

        addFileAccessRequestDoc({ id: nanoid(), ...req });
      }
    );
    socket.on("file-access-granted", ({ token }: { token: string }) => {
      log.debug("Event file-access-granted: ", token);

      addAlert({
        status: "success",
        content: t("alerts.success.fileAccessGranted"),
      });
      startFileDownload(token, useRoomStore.getState().files);
    });
    socket.on("file-access-denied", ({ fileId }: { fileId: string }) => {
      log.debug("Event file-access-denied: ", fileId);

      addAlert({
        status: "error",
        content: t("alerts.error.fileAccessDenied"),
      });
    });
    socket.on(
      "file-uploading-status",
      ({
        status,
        fileId,
        receiverId,
      }: {
        status: FileUploadingStatus;
        fileId: string;
        receiverId: string;
      }) => {
        log.debug("Event file-uploading-status: ", {
          status,
          fileId,
          receiverId,
        });

        if (status === FileUploadingStatus.Started) {
          addFileDownloaderDoc(fileId, receiverId);
        } else if (status === FileUploadingStatus.Finished) {
          removeFileDownloaderDoc(fileId, receiverId);
        }
      }
    );

    socket.on(
      "file-sdp-offered",
      ({
        accessToken,
        config,
        sdp,
      }: {
        roomId: string;
        client: IMember;
        accessToken: string;
        config: RTCConfiguration;
        sdp: string;
      }) => {
        log.debug("Event file-sdp-offered", {
          accessToken,
          config,
          sdp,
        });

        const accessPayload = jwtDecode(accessToken) as IFileAccessTokenPayload;
        const id = getFileSessId("sender", accessPayload);

        addFileTransferSession(id, {
          id,
          role: "sender",
          sdp,
          access: { token: accessToken, payload: accessPayload },
          status: "idle",
          config,
          pendingIceCandiates: [],
        });
      }
    );
    socket.on(
      "file-sdp-answered",
      async ({
        roomId,
        fileId,
        client,
        sdp,
      }: {
        roomId: string;
        fileId: string;
        client: IMember;
        sdp: string;
      }) => {
        log.debug("Event file-sdp-answered", { roomId, fileId, client, sdp });

        const sessId = getFileSessId("receiver", {
          fileId,
          senderId: client.id,
          receiverId: profile!.uid,
        });
        const fileTransferSession = useRoomStore
          .getState()
          .fileTransferSessions.find((sess) => sess.id === sessId);
        const peerConn = fileTransferConnsRef.current[sessId];

        if (!fileTransferSession || !peerConn) {
          log.warn(`Missing file transfer session id: `, sessId);
          return;
        }

        try {
          await peerConn.setRemoteDescription({ type: "answer", sdp });
        } catch (err) {
          log.error("Error setting remote SDP", err);
        }
      }
    );
    socket.on(
      "file-ice",
      async ({
        role,
        roomId,
        fileId,
        client,
        candidate,
      }: {
        role: "sender" | "receiver";
        roomId: string;
        fileId: string;
        client: IMember;
        candidate: RTCIceCandidate;
      }) => {
        log.debug("Event file-ice", {
          role,
          roomId,
          fileId,
          candidate,
        });

        const sessId = getFileSessId(
          role === "sender" ? "receiver" : "sender",
          {
            fileId,
            senderId: role === "sender" ? client.id : profile!.uid,
            receiverId: role === "receiver" ? client.id : profile!.uid,
          }
        );

        const peerConn = fileTransferConnsRef.current[sessId];
        if (peerConn) {
          try {
            await peerConn.addIceCandidate(candidate);
          } catch (err) {
            log.error("Error adding ICE candidate", err);
          }
        } else {
          const sess = useRoomStore
            .getState()
            .fileTransferSessions.find((sess) => sess.id === sessId);
          if (sess) {
            updateFileTransferSession(sess.id, {
              pendingIceCandiates: [...sess.pendingIceCandiates, candidate],
            });
          } else {
            log.warn(
              `Missing file transfer session id for ICE event: `,
              sessId
            );
          }
        }
      }
    );
    socket.on("chat-message-added", (message: IMessage) => {
      log.debug("Event chat-message-added", { message });

      addChatMessageDoc(message);
    });

    return () => {
      socket.disconnect();
    };
  }, [
    room?.id,
    // isRoomLoaded,
    accessToken,
  ]);

  useEffect(() => {
    const handleNewSession = async (sess: TFileTransferSession) => {
      const peerConn = new RTCPeerConnection(sess.config);
      fileTransferConnsRef.current[sess.id] = peerConn;

      let canceled = false;
      let channelOpened = false;
      let updatedTransferStatus = false;
      const file = files.find(
        (file) => file.id === sess.access.payload.fileId
      )!;

      const cancelTransfer = (err?: any) => {
        if (canceled) {
          return;
        }
        canceled = true;
        // if (!fileTransferConnsRef.current[sess.id]) {
        //   return;
        // }
        delete fileTransferConnsRef.current[sess.id];
        removeFileTransferSession(sess.id);
        peerConn.close();

        if (updatedTransferStatus && sess.role === "receiver") {
          socketRef.current!.emit("update-file-uploadig-status", {
            status: FileUploadingStatus.Finished,
            roomId: room!.id,
            fileId: sess.access.payload.fileId,
          });
        }

        if (err) {
          Sentry.captureException(err);
          addAlert({
            status: "error",
            content: err.message || t("alerts.error.default"),
          });
        }
      };

      peerConn.onconnectionstatechange = () => {
        log.debug("Peer connection state change", {
          connectionState: peerConn.connectionState,
          iceConnectionState: peerConn.iceConnectionState,
          iceGatheringState: peerConn.iceGatheringState,
        });
        if (peerConn.connectionState === "failed") {
          cancelTransfer(new Error("Connection failed"));
        }
      };
      peerConn.onicecandidateerror = (err) => {
        log.error("Error onicecandidateerror", err);
        if ((err as RTCPeerConnectionIceErrorEvent).errorCode < 700) {
          cancelTransfer(err);
        }
      };
      peerConn.onicecandidate = ({ candidate }) => {
        log.debug("Local peer connection candidate", candidate);
        socketRef.current?.emit("file-ice", {
          accessToken: sess.access.token,
          candidate,
        });
      };

      if (sess.role === "sender") {
        peerConn.ondatachannel = ({ channel }) => {
          log.debug("Peer connection new channel", channel);
          channel.binaryType = "arraybuffer";

          channel.onopen = () => {
            log.debug("Channel open");
            channelOpened = true;

            const file = filesRef.current[sess.access.payload.fileId];
            if (!file) {
              log.warn(
                "Send channel open but no file: ",
                sess.access.payload.fileId
              );
              return;
            }

            const chunkSize = 256 * 1024;
            const fileReader = new FileReader();
            let offset = 0;
            const readSlice = (o: number) => {
              fileReader.readAsArrayBuffer(file.slice(offset, o + chunkSize));
            };

            fileReader.onload = (e) => {
              const send = () => {
                if (channel.readyState !== "open") {
                  return;
                }

                channel.send(e.target!.result as ArrayBuffer);
                offset += (e.target!.result as ArrayBuffer).byteLength;
                if (offset < file.size) {
                  readSlice(offset);
                }

                updateFileTransferSession(sess.id, {
                  progressValue: minmax(
                    2.5,
                    100,
                    Math.floor((offset / file.size) * 100)
                  ),
                });
              };

              if (channel.bufferedAmount > channel.bufferedAmountLowThreshold) {
                channel.onbufferedamountlow = () => {
                  channel.onbufferedamountlow = null;
                  send();
                };
              } else {
                send();
              }
            };

            readSlice(0);
          };

          channel.onclose = (event) => {
            log.debug("Channel close", event);
            cancelTransfer();
          };

          channel.onerror = (ev) => {
            const err = (ev as { error?: Error & { sctpCauseCode: number } })
              .error;
            log.error("Channel error", err);
            cancelTransfer(err?.sctpCauseCode !== 12 ? err : undefined);
          };
        };

        try {
          let answer: RTCSessionDescriptionInit;
          try {
            await peerConn.setRemoteDescription({
              type: "offer",
              sdp: sess.sdp,
            });
            answer = await peerConn.createAnswer();
            await peerConn.setLocalDescription(answer);
          } catch (err) {
            log.error("Error creating answer SDP", err);
            cancelTransfer(err);
            return;
          }

          log.debug("Created local session description answer", answer);

          socketRef.current?.emit(
            "answer-file-sdp",
            { accessToken: sess.access.token, sdp: answer.sdp },
            ({ status, payload }: TMessageResponse) => {
              if (status === "error") {
                log.error("Error answering file access sdp", payload);
                cancelTransfer(payload);
              }
            }
          );
        } catch (err) {
          log.error("Failed to create local session description answer", err);
        }
      } else if (sess.role === "receiver") {
        let receiveBuffer: ArrayBuffer[] = [];
        let receivedSize = 0;

        const channel = peerConn.createDataChannel("file");
        channel.binaryType = "arraybuffer";

        channel.onopen = () => {
          log.debug("Channel open");
          channelOpened = true;
        };

        channel.onclose = (event) => {
          log.debug("Channel close", event);

          cancelTransfer();
        };

        channel.onerror = (ev) => {
          const err = (ev as { error?: Error & { sctpCauseCode: number } })
            .error;
          log.error("Channel error", err);
          cancelTransfer(err?.sctpCauseCode !== 12 ? err : undefined);
        };

        channel.onmessage = (event) => {
          log.debug("Channel message", event.data);
          const data = event.data as ArrayBuffer;

          receiveBuffer.push(data);
          receivedSize += data.byteLength;

          updateFileTransferSession(sess.id, {
            progressValue: minmax(
              2.5,
              100,
              Math.floor((receivedSize / file.size) * 100)
            ),
          });

          if (receivedSize >= file.size) {
            channel.close();

            const blobUrl = URL.createObjectURL(new Blob(receiveBuffer));
            downloadUrl(blobUrl, file.name);
            URL.revokeObjectURL(blobUrl);
          }
        };

        try {
          let offer: RTCSessionDescriptionInit;

          try {
            offer = await peerConn.createOffer();
            await peerConn.setLocalDescription(offer);
          } catch (err) {
            log.error("Error creating offer SDP", err);
            cancelTransfer(err);
            return;
          }

          log.debug("Created local session description", offer);

          socketRef.current!.emit(
            "offer-file-sdp",
            { accessToken: sess.access.token, sdp: offer.sdp },
            ({ status, payload }: TMessageResponse) => {
              if (status === "error") {
                log.error("Error offering file access sdp", payload);
                cancelTransfer(payload);
              }
            }
          );

          socketRef.current!.emit("update-file-uploadig-status", {
            status: FileUploadingStatus.Started,
            roomId: room!.id,
            fileId: sess.access.payload.fileId,
          });
          updatedTransferStatus = true;
        } catch (err) {
          log.error("Failed to create local session description", err);
        }
      }

      for (const candidate of sess.pendingIceCandiates) {
        await peerConn.addIceCandidate(candidate);
      }

      setTimeout(() => {
        if (!channelOpened) {
          cancelTransfer(new Error("Timeout"));
        }
      }, 30 * 1000);
    };

    for (const sess of fileTransferSessions) {
      if (sess.role === "receiver") {
        if (!(sess.id in fileTransferConnsRef.current)) {
          handleNewSession(sess);
        }
      }
    }

    const fileIdsToSend = Array.from(
      new Set(
        (
          fileTransferSessions.filter(
            (s) => s.role === "sender"
          ) as ISenderFileTransferSession[]
        ).map((s) => s.access.payload.fileId)
      )
    );
    for (const fileId of fileIdsToSend) {
      const fileSessions = fileTransferSessions.filter(
        (sess) =>
          sess.role === "sender" && sess.access.payload.fileId === fileId
      ) as ISenderFileTransferSession[];

      const maxActiveSessionsCount = 3;
      const activeSessionsCount = fileSessions.filter(
        (s) => s.status === "active"
      ).length;
      if (activeSessionsCount >= maxActiveSessionsCount) {
        break;
      }

      const sessionsToHandle = fileSessions
        .filter((s) => s.status !== "active")
        .slice(0, maxActiveSessionsCount - activeSessionsCount);
      for (const sess of sessionsToHandle) {
        updateFileTransferSession(sess.id, {
          status: "active",
          progressValue: 2.5,
        });
        handleNewSession(sess);
      }
    }

    for (const [sessId, peerConn] of Object.entries(
      fileTransferConnsRef.current
    )) {
      const sess = fileTransferSessions.find((sess) => sess.id === sessId);
      if (!sess) {
        delete fileTransferConnsRef.current[sessId];
        peerConn.close();
      }
    }
  }, [fileTransferSessions.length]);

  useEffect(() => {
    // * Autoconnect
    if (status === "authenticated" && room && accessToken && profile) {
      const isProtected = room.isProtected && room.ownerId !== profile.uid;
      setJoinStatus(isProtected ? "needs-password" : undefined);

      if (!isProtected) {
        join();
      }
    }
  }, [status, room?.id, accessToken, profile?.uid]);

  useEffect(() => {
    if (status === "joined" && room) {
      // * Load room when joined
      loadRoom();

      // * Hide room password or request dialog when joined
      setJoinStatus(undefined);
    }
  }, [status, room?.id]);

  useEffect(() => {
    const alertId = "connection-error";

    if (status === "disconnected") {
      let timeout = setTimeout(() => {
        addAlert({
          id: alertId,
          status: "error",
          content: t("alerts.error.connection"),
          duration: undefined,
          isClosable: false,
        });
      }, 500);

      if (profile) {
        for (const file of files) {
          if (file.ownerId === profile.uid) {
            removeFileDoc(file.id);
            delete filesRef.current[file.id];

            for (const [sessId, peerConn] of Object.entries(
              fileTransferConnsRef.current
            )) {
              removeFileTransferSession(sessId);
              peerConn.close();
            }
          }
        }
      }

      return () => {
        clearTimeout(timeout);
      };
    } else {
      removeAlert(alertId);
    }
  }, [status]);

  return (
    <RoomSocketConnectionContext.Provider value={value}>
      {children}
    </RoomSocketConnectionContext.Provider>
  );
};

export default RoomSocketConnectionProvider;
