import { FC } from "react";
import { Outlet } from "react-router-dom";

import Snackbar from "./Snackbar";

const Layout: FC = () => (
  <>
    <Outlet />
    <Snackbar />
  </>
);

export default Layout;
