import { FC } from "react";
import { Box, Flex, Icon, useToken } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { useViewStore } from "../../stores/viewStore";
import { TView } from "../../types/view";
import { hexToHexAlpha } from "../../utils/colors";
import { ChatIcon, DocIcon, UserIcon } from "../icons";
import { useRoomStats } from "../../stores/roomStore";

const MobileNavigation: FC = () => {
  const { t } = useTranslation();
  const { activeTab, setActiveTab } = useViewStore();
  const stats = useRoomStats();
  const tabs: { value: TView; icon: FC; stat: number }[] = [
    { value: "files", icon: DocIcon, stat: stats.files },
    { value: "chat", icon: ChatIcon, stat: stats.messages },
    { value: "members", icon: UserIcon, stat: stats.members },
  ];
  const brandColor = useToken("colors", "brand.500");

  return (
    <Flex
      bg="gray.800"
      position="sticky"
      top="0"
      zIndex="1000"
      pt="1"
      align="stretch"
      boxShadow="0 3px 7px 0 rgba(0, 0, 0, 0.32)"
    >
      {tabs.map(({ value: tab, icon, stat }, i) => {
        const isActive = tab === activeTab;

        return (
          <Flex
            key={tab}
            onClick={() => setActiveTab(tab)}
            as="button"
            flex="1"
            justify="center"
            align="center"
            whiteSpace="nowrap"
            color={isActive ? "brand.500" : "gray.500"}
            bg={isActive ? hexToHexAlpha(brandColor, 0.1) : "transparent"}
            borderBottom="1px"
            borderColor={isActive ? "brand.500" : "gray.700"}
            borderTopLeftRadius={i > 0 ? "md" : undefined}
            borderTopRightRadius={i < tabs.length - 1 ? "md" : undefined}
            fontSize="medium"
            fontWeight={isActive ? "medium" : "regular"}
            px="2"
            py="2"
          >
            <Icon as={icon} mr="2" flexShrink="0" />
            {t(`room.navigation.${tab}`)}
            {stat !== 0 && (
              <Box
                as="span"
                ml="1.5"
                bg="gray.900"
                color="gray.300"
                borderRadius="base"
                px="1"
                py="1"
                fontSize="xs"
                lineHeight="3"
                fontWeight="medium"
              >
                {stat}
              </Box>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};

export default MobileNavigation;
