import { useEffect } from "react";
import { useAdsStore } from "../stores/adsStore";

const useFetchAdBanners = () => {
  const { isFetching, isFetched, fetchAdBanners } = useAdsStore(
    (state) => state
  );

  useEffect(() => {
    if (!isFetching && !isFetched) {
      fetchAdBanners();
    }
  }, [isFetching, isFetched]);
};

export default useFetchAdBanners;
