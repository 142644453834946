import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const PointIcon: FC<IconProps> = (props) => (
  <Icon {...props}>
    <defs>
      <filter
        id="point-icon-a"
        width="250%"
        height="250%"
        x="-75%"
        y="-75%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.148898333   0 0 0 0 0.883361518   0 0 0 0 0.641910613  0 0 0 0.432637675 0"
        />
      </filter>
      <circle id="point-icon-b" cx="8" cy="8" r="4" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(2 2)">
      <use xlinkHref="#point-icon-b" fill="#000" filter="url(#point-icon-a)" />
      <use xlinkHref="#point-icon-b" fill="#26E1A4" />
      <circle cx="8" cy="8" r="7.5" stroke="#26E1A4" opacity=".4" />
    </g>
  </Icon>
);

export default PointIcon;
