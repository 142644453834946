import { useRoomStore } from "../stores/roomStore";
import { useProfileStore } from "../stores/profileStore";

const useRoomOwner = () => {
  const ownerId = useRoomStore((state) => state.room?.ownerId);
  const userId = useProfileStore((state) => state.profile?.uid);
  return userId && ownerId === userId;
};

export default useRoomOwner;
