import { FC } from "react";

import Icon, { IconProps } from "./Icon";

export const StepOne: FC<IconProps> = (props) => (
  <Icon size="64" {...props}>
    <defs>
      <radialGradient
        id="info-step-one-b"
        cx="70.848%"
        cy="-12.001%"
        r="100.498%"
        fx="70.848%"
        fy="-12.001%"
        gradientTransform="scale(-.7561 -1) rotate(-74.075 .045 1.09)"
      >
        <stop offset="0%" stopColor="#474747" />
        <stop offset="100%" stopColor="#242424" />
      </radialGradient>
      <radialGradient
        id="info-step-one-e"
        cx="53.32%"
        cy="-3.539%"
        r="130.808%"
        fx="53.32%"
        fy="-3.539%"
        gradientTransform="matrix(-.0888 .99526 -.90871 -.09726 .548 -.57)"
      >
        <stop offset="0%" stopColor="#645D5D" />
        <stop offset="100%" stopColor="#272727" />
      </radialGradient>
      <filter
        id="info-step-one-c"
        width="165.2%"
        height="171.4%"
        x="-32.6%"
        y="-21.4%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="6" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="4"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.272973121 0"
        />
      </filter>
      <filter
        id="info-step-one-f"
        width="405.7%"
        height="240.7%"
        x="-152.8%"
        y="-70.3%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0.148898333 0 0 0 0 0.883361518 0 0 0 0 0.641910613 0 0 0 0.307719624 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <path id="info-step-one-a" d="M3 7h41v31H3z" />
      <path
        id="info-step-one-d"
        d="M40 0a6 6 0 0 1 6 6v30a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6a6 6 0 0 1 6-6h34Zm3 10H3v26a3 3 0 0 0 2.824 2.995L6 39h34a3 3 0 0 0 2.995-2.824L43 36V10Z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(12 9)">
        <use xlinkHref="#info-step-one-a" fill="#D8D8D8" />
        <use xlinkHref="#info-step-one-a" fill="url(#info-step-one-b)" />
      </g>
      <path fill="#2E2E2E" d="M15 19h40v3H15z" />
      <g transform="translate(12 9)">
        <use
          xlinkHref="#info-step-one-d"
          fill="#000"
          filter="url(#info-step-one-c)"
        />
        <use xlinkHref="#info-step-one-d" fill="url(#info-step-one-e)" />
      </g>
      <path
        fill="#252424"
        d="M17.5 16a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm5 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm5 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
      />
      <g fill="#999" fillRule="nonzero">
        <path d="M31.807 37.027h-6.61L23.814 41h-3.082l6.446-17.063h2.66L36.295 41H33.2l-1.394-3.973Zm-5.778-2.39h4.946l-2.473-7.078-2.473 7.078ZM45.53 41c-.126-.242-.235-.637-.329-1.184-.906.946-2.015 1.418-3.328 1.418-1.273 0-2.312-.363-3.117-1.09-.805-.726-1.207-1.624-1.207-2.695 0-1.351.502-2.388 1.506-3.111 1.004-.723 2.44-1.084 4.306-1.084h1.746v-.832c0-.656-.183-1.182-.55-1.576-.368-.395-.926-.592-1.676-.592-.649 0-1.18.162-1.594.486-.414.324-.621.737-.621 1.237h-2.848c0-.696.23-1.346.692-1.952.46-.605 1.088-1.08 1.88-1.423.794-.344 1.678-.516 2.655-.516 1.484 0 2.668.373 3.55 1.12.884.745 1.337 1.794 1.36 3.146v5.718c0 1.14.16 2.051.48 2.73v.2H45.53Zm-3.13-2.05a3.24 3.24 0 0 0 1.588-.41 2.74 2.74 0 0 0 1.12-1.102v-2.391h-1.536c-1.054 0-1.847.183-2.379.55-.53.368-.797.887-.797 1.56 0 .546.182.982.545 1.306.364.324.85.486 1.46.486Z" />
      </g>
      <g
        fill="#26E1A4"
        fillRule="nonzero"
        filter="url(#info-step-one-f)"
        transform="translate(6.328 32.25)"
      >
        <path d="M10.469 22.75H5.188V5.938L0 7.453v-4L9.984 0h.485z" />
      </g>
    </g>
  </Icon>
);

export const StepTwo: FC<IconProps> = (props) => (
  <Icon size="64" {...props}>
    <defs>
      <filter
        id="info-step-three-a"
        width="284.2%"
        height="252.2%"
        x="-92.1%"
        y="-54.3%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="5" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="5"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.272973121 0"
        />
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2" />
        <feColorMatrix
          in="shadowOffsetOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 0.160537988 0 0 0 0 0.156918734 0 0 0 0 0.153837532 0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
      <filter
        id="info-step-three-d"
        width="231.6%"
        height="208.7%"
        x="-65.8%"
        y="-32.6%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur
          in="SourceAlpha"
          result="shadowBlurInner1"
          stdDeviation="2"
        />
        <feOffset dy="1" in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.953747809 0 0 0 0 0.953747809 0 0 0 0 0.953747809 0 0 0 0.107736014 0"
        />
      </filter>
      <filter
        id="info-step-three-e"
        width="281%"
        height="246.2%"
        x="-90.5%"
        y="-53.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="5" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="5.5"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.272973121 0"
        />
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2" />
        <feColorMatrix
          in="shadowOffsetOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 0.160537988 0 0 0 0 0.156918734 0 0 0 0 0.153837532 0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
      <filter
        id="info-step-three-h"
        width="228.6%"
        height="203.8%"
        x="-64.3%"
        y="-32.7%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur
          in="SourceAlpha"
          result="shadowBlurInner1"
          stdDeviation="2"
        />
        <feOffset dy="1" in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.953747809 0 0 0 0 0.953747809 0 0 0 0 0.953747809 0 0 0 0.107736014 0"
        />
      </filter>
      <filter
        id="info-step-three-i"
        width="293.6%"
        height="236.8%"
        x="-96.8%"
        y="-68.4%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0.148898333 0 0 0 0 0.883361518 0 0 0 0 0.641910613 0 0 0 0.307719624 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <radialGradient
        id="info-step-three-c"
        cx="67.001%"
        cy="12.964%"
        r="158.023%"
        fx="67.001%"
        fy="12.964%"
        gradientTransform="scale(-1 -.82609) rotate(-85.784 -.154 .708)"
      >
        <stop offset="0%" stopColor="#645D5D" />
        <stop offset="100%" stopColor="#272727" />
      </radialGradient>
      <radialGradient
        id="info-step-three-g"
        cx="67.001%"
        cy="12.964%"
        r="161.603%"
        fx="67.001%"
        fy="12.964%"
        gradientTransform="scale(-1 -.8077) rotate(-85.878 -.156 .705)"
      >
        <stop offset="0%" stopColor="#645D5D" />
        <stop offset="100%" stopColor="#272727" />
      </radialGradient>
      <path
        id="info-step-three-b"
        d="M48.176 6 56 13.667V26.81c0 1.21-1 2.19-2.235 2.19h-14.53C38.001 29 37 28.02 37 26.81V8.19C37 6.98 38 6 39.235 6h8.941Zm0 2.19v3.925c0 .857.695 1.552 1.552 1.552h4.037L48.176 8.19Z"
      />
      <path
        id="info-step-three-f"
        d="M31.353 19 40 27.667v14.857A2.473 2.473 0 0 1 37.53 45H21.47A2.473 2.473 0 0 1 19 42.524V21.476A2.473 2.473 0 0 1 21.47 19h9.883Zm0 2.476v4.457c0 .958.776 1.734 1.733 1.734h4.443l-6.176-6.19Z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <use
          xlinkHref="#info-step-three-b"
          fill="#000"
          filter="url(#info-step-three-a)"
        />
        <use xlinkHref="#info-step-three-b" fill="url(#info-step-three-c)" />
        <use
          xlinkHref="#info-step-three-b"
          fill="#000"
          filter="url(#info-step-three-d)"
        />
      </g>
      <g fillRule="nonzero">
        <use
          xlinkHref="#info-step-three-f"
          fill="#000"
          filter="url(#info-step-three-e)"
        />
        <use xlinkHref="#info-step-three-f" fill="url(#info-step-three-g)" />
        <use
          xlinkHref="#info-step-three-f"
          fill="#000"
          filter="url(#info-step-three-h)"
        />
      </g>
      <g
        fill="#26E1A4"
        fillRule="nonzero"
        filter="url(#info-step-three-i)"
        transform="translate(6 32)"
      >
        <path d="M16.344 23.078H.469v-3.437l7.312-7.688c1.802-2.052 2.703-3.682 2.703-4.89 0-.98-.213-1.724-.64-2.235-.427-.51-1.047-.766-1.86-.766-.802 0-1.453.342-1.953 1.024-.5.682-.75 1.534-.75 2.555H0c0-1.396.349-2.685 1.047-3.868A7.341 7.341 0 0 1 3.953 1C5.193.333 6.578 0 8.11 0c2.459 0 4.352.568 5.68 1.703 1.328 1.136 1.992 2.766 1.992 4.89 0 .897-.166 1.769-.5 2.618-.333.849-.851 1.74-1.554 2.672-.704.932-1.836 2.18-3.399 3.742l-2.937 3.39h8.953v4.063Z" />
      </g>
      <path
        stroke="#999"
        strokeWidth="2"
        d="M33 9.167h-7a3 3 0 0 0-3 3v3M44 41.167h6a3 3 0 0 0 3-3v-3"
      />
    </g>
  </Icon>
);

export const StepThree: FC<IconProps> = (props) => (
  <Icon size="64" {...props}>
    <defs>
      <filter
        id="info-step-two-a"
        width="200%"
        height="183.3%"
        x="-50%"
        y="-28.3%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3.5"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.272973121 0"
        />
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter2" />
        <feColorMatrix
          in="shadowOffsetOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 0.160537988 0 0 0 0 0.156918734 0 0 0 0 0.153837532 0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
      <filter
        id="info-step-two-d"
        width="172%"
        height="160%"
        x="-36%"
        y="-16.7%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur
          in="SourceAlpha"
          result="shadowBlurInner1"
          stdDeviation="1.5"
        />
        <feOffset dy="1" in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.953747809 0 0 0 0 0.953747809 0 0 0 0 0.953747809 0 0 0 0.107736014 0"
        />
      </filter>
      <filter
        id="info-step-two-e"
        width="200%"
        height="183.3%"
        x="-50%"
        y="-29.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="5" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="5"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.272973121 0"
        />
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2" />
        <feColorMatrix
          in="shadowOffsetOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 0.160537988 0 0 0 0 0.156918734 0 0 0 0 0.153837532 0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
      <filter
        id="info-step-two-h"
        width="171.4%"
        height="159.5%"
        x="-35.7%"
        y="-17.9%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur
          in="SourceAlpha"
          result="shadowBlurInner1"
          stdDeviation="2"
        />
        <feOffset dy="1" in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.953747809 0 0 0 0 0.953747809 0 0 0 0 0.953747809 0 0 0 0.107736014 0"
        />
      </filter>
      <filter
        id="info-step-two-i"
        width="295.8%"
        height="238.7%"
        x="-97.9%"
        y="-69.3%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0.148898333 0 0 0 0 0.883361518 0 0 0 0 0.641910613 0 0 0 0.307719624 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <radialGradient
        id="info-step-two-c"
        cx="67.001%"
        cy="12.964%"
        r="156.657%"
        fx="67.001%"
        fy="12.964%"
        gradientTransform="matrix(-.07415 .83104 -.99725 -.0618 .849 -.42)"
      >
        <stop offset="0%" stopColor="#645D5D" />
        <stop offset="100%" stopColor="#272727" />
      </radialGradient>
      <radialGradient
        id="info-step-two-g"
        cx="67.001%"
        cy="12.964%"
        r="156.657%"
        fx="67.001%"
        fy="12.964%"
        gradientTransform="matrix(-.07415 .83104 -.99725 -.0618 .849 -.42)"
      >
        <stop offset="0%" stopColor="#645D5D" />
        <stop offset="100%" stopColor="#272727" />
      </radialGradient>
      <path
        id="info-step-two-b"
        d="M41.5 21.882c6.539 0 11.91 4.92 12.5 11.202a28.016 28.016 0 0 1-11.962 2.911L41.5 36c-1.104 0-2.192-.063-3.262-.186a20.588 20.588 0 0 0-5.387-10.533 12.615 12.615 0 0 1 8.357-3.395l.292-.004Zm-12.316 9.951c.399.58.754 1.192 1.06 1.83A25.8 25.8 0 0 1 29 33.085c.038-.406.096-.806.173-1.2l.011-.052ZM41.5 6c3.962 0 7.174 3.16 7.174 7.059 0 3.825-3.092 6.94-6.95 7.055l-.224.004c-3.962 0-7.174-3.16-7.174-7.06 0-3.824 3.092-6.938 6.95-7.055L41.5 6Z"
      />
      <path
        id="info-step-two-f"
        d="M17.5 22.235c9.154 0 16.674 6.887 17.5 15.683a39.223 39.223 0 0 1-16.747 4.075L17.5 42A39.27 39.27 0 0 1 0 37.92c.813-8.666 8.122-15.479 17.09-15.68l.41-.005ZM17.5 0c5.546 0 10.043 4.424 10.043 9.882 0 5.355-4.328 9.715-9.73 9.878l-.313.005c-5.547 0-10.043-4.425-10.043-9.883 0-5.355 4.328-9.715 9.73-9.877L17.5 0Z"
      />
    </defs>
    <g fill="none" fillRule="nonzero">
      <g transform="translate(10 8)">
        <use
          xlinkHref="#info-step-two-b"
          fill="#000"
          filter="url(#info-step-two-a)"
        />
        <use xlinkHref="#info-step-two-b" fill="url(#info-step-two-c)" />
        <use
          xlinkHref="#info-step-two-b"
          fill="#000"
          filter="url(#info-step-two-d)"
        />
      </g>
      <g transform="translate(10 8)">
        <use
          xlinkHref="#info-step-two-f"
          fill="#000"
          filter="url(#info-step-two-e)"
        />
        <use xlinkHref="#info-step-two-f" fill="url(#info-step-two-g)" />
        <use
          xlinkHref="#info-step-two-f"
          fill="#000"
          filter="url(#info-step-two-h)"
        />
      </g>
      <g
        fill="#26E1A4"
        filter="url(#info-step-two-i)"
        transform="translate(6 32)"
      >
        <path d="M5.469 9.5h2.484c1.969 0 2.953-.964 2.953-2.89 0-.75-.234-1.363-.703-1.837s-1.13-.71-1.984-.71c-.698 0-1.305.203-1.82.609-.516.406-.774.911-.774 1.516H.359c0-1.198.334-2.266 1-3.204.667-.937 1.592-1.669 2.774-2.195C5.315.263 6.615 0 8.03 0 10.562 0 12.552.578 14 1.734c1.448 1.157 2.172 2.745 2.172 4.766 0 .98-.3 1.904-.899 2.773-.599.87-1.47 1.576-2.617 2.118 1.209.437 2.156 1.101 2.844 1.992.688.89 1.031 1.992 1.031 3.305 0 2.03-.781 3.656-2.343 4.875-1.563 1.218-3.615 1.828-6.157 1.828-1.49 0-2.872-.284-4.148-.852-1.276-.568-2.242-1.354-2.899-2.36C.328 19.175 0 18.032 0 16.75h5.297c0 .698.281 1.302.844 1.813.562.51 1.255.765 2.078.765.927 0 1.666-.258 2.219-.773.552-.516.828-1.175.828-1.977 0-1.146-.287-1.958-.86-2.437-.573-.48-1.364-.72-2.375-.72H5.47V9.5Z" />
      </g>
    </g>
  </Icon>
);

export const stepsIcons = [StepOne, StepTwo, StepThree];
