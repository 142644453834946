import { FC } from "react";
import {
  Box,
  HStack,
  // Menu, MenuItem, MenuList,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Avatar from "../ui/Avatar";
// import MeatballsMenuButton from "../ui/MeatballsMenuButton";
import { IMessage } from "../../types/room";

interface IMessageProps {
  msg: IMessage;
  isOwner?: boolean;
}

const Message: FC<IMessageProps> = ({
  msg: { content, user, sentAt },
  isOwner,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <HStack spacing="2">
        <Avatar nickname={user.nickname} size="8" colorHash={user.id} />
        <HStack flex="1" justify="space-between">
          <Box minH="8">
            <Text mb="0.5" noOfLines={1} fontWeight="600" lineHeight="4">
              {user.nickname}
              {isOwner && (
                <Text as="i" fontSize="sm" fontWeight="600" color="brand.500">
                  {` (${t("common.you")})`}
                </Text>
              )}
            </Text>
            <Text noOfLines={1} fontSize="sm" lineHeight="5" color="gray.500">
              #{user.id} • {moment(sentAt).format("LT").replace(" ", "")}
            </Text>
          </Box>

          {/* <Menu placement="bottom-end">
            <MeatballsMenuButton />
            <MenuList>
              <MenuItem>Delete</MenuItem>
            </MenuList>
          </Menu> */}
        </HStack>
      </HStack>

      <Box p="2" mt="2" ml="10" bg="gray.700" borderRadius="md">
        {content}
      </Box>
    </Box>
  );
};

export default Message;
