import { FC } from "react";

import Icon, { SmartIconProps } from "./Icon";

const PasswordSmartIcon: FC<SmartIconProps> = ({
  isActive = true,
  ...props
}) => (
  <Icon size="24" {...props}>
    <defs>
      <linearGradient
        x1="50%"
        y1="-17.541%"
        x2="50%"
        y2="95.986%"
        id="password-smart-icon-a-active"
      >
        <stop stopColor="#6EEFBB" offset="0%" />
        <stop stopColor="#4B9777" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="-17.541%"
        x2="50%"
        y2="95.986%"
        id="password-smart-icon-a"
      >
        <stop stopColor="#AEAEAE" offset="0%" />
        <stop stopColor="#AAA3A3" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        id="password-smart-icon-d-active"
      >
        <stop stopColor="#70F4BF" offset="0%" />
        <stop stopColor="#53A782" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        id="password-smart-icon-d"
      >
        <stop stopColor="#C8C8C8" offset="0%" />
        <stop stopColor="#979797" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="-17.541%"
        x2="50%"
        y2="95.986%"
        id="password-smart-icon-e-active"
      >
        <stop stopColor="#5DC197" offset="0%" />
        <stop stopColor="#3A8A66" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="-17.541%"
        x2="50%"
        y2="95.986%"
        id="password-smart-icon-e"
      >
        <stop stopColor="#9C9696" offset="0%" />
        <stop stopColor="#6F6F6F" offset="100%" />
      </linearGradient>
      <filter
        x="-25%"
        y="-12.5%"
        width="150%"
        height="150%"
        filterUnits="objectBoundingBox"
        id="password-smart-icon-b"
      >
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation=".5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.390870848 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path
        d="M8 8a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm0 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
        id="password-smart-icon-c"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect
        stroke={isActive ? "#26E1A4" : "#6A6A6A"}
        fill={isActive ? "#484848" : "#343434"}
        x=".5"
        y=".5"
        width="23"
        height="23"
        rx="5"
      />
      <rect
        fill={`url(#password-smart-icon-a${isActive ? "-active" : ""})`}
        fillRule="nonzero"
        x="10"
        y="11"
        width="11"
        height="2"
        rx="1"
      />
      <g fillRule="nonzero">
        <use
          fill="#000"
          filter="url(#password-smart-icon-b)"
          xlinkHref="#password-smart-icon-c"
        />
        <use
          fill={`url(#password-smart-icon-d${isActive ? "-active" : ""})`}
          xlinkHref="#password-smart-icon-c"
        />
      </g>
      <path
        d="M16 13h4v1.5a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5V13Z"
        fill={`url(#password-smart-icon-e${isActive ? "-active" : ""})`}
        fillRule="nonzero"
      />
    </g>
  </Icon>
);

export default PasswordSmartIcon;
