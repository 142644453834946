import {
  Box,
  HStack,
  Icon,
  Spinner,
  Text,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import usePublicRooms from "../../hooks/useFetchPublicRooms";
import { DocIcon, UserIcon } from "../icons";
import { roomTransferProtocolIcons } from "../icons/roomTransferProtocolIcons";
import HomeSectionHeading from "./HomeSectionHeading";

const PublicRoomsSection: FC = () => {
  const { t } = useTranslation();
  const { rooms, fetchPublicRooms } = usePublicRooms();

  useEffect(() => {
    fetchPublicRooms();
  }, []);

  return rooms && rooms.length > 0 ? (
    <Box id="public-spaces" as="section">
      <HomeSectionHeading
        mb="5"
        dangerouslySetInnerHTML={{
          __html: t("home.public.title"),
        }}
      />

      {rooms ? (
        <Wrap mt="6" justify={{ base: "center", md: "start" }} spacing="4">
          {rooms.map((room) => (
            <Box
              key={room.id}
              as={RouterLink}
              to={`/${room.name}`}
              p="4"
              border="1px"
              maxW={{
                base: "calc(50% - var(--chakra-space-4))",
                md: "216px",
              }}
              w="full"
              borderColor="gray.800"
              bg="gray.900"
              borderRadius="md"
              transition="border-color 150ms ease-out"
              _hover={{
                borderColor: "brand.700",
              }}
            >
              <VStack align="flex-start" spacing="2">
                <Icon
                  as={roomTransferProtocolIcons[room.transferProtocol]}
                  isActive
                  w="7"
                  h="auto"
                />

                <Text fontWeight="medium" fontSize="lg">
                  {room.name}
                </Text>

                <HStack spacing="1.5" color="gray.500">
                  <Icon as={UserIcon} w="4" h="auto" />
                  <Text fontWeight="medium" fontSize="sm">
                    {t("home.public.item.members", {
                      count: room.membersCount,
                    })}
                  </Text>
                </HStack>

                <HStack spacing="1.5" color="gray.500">
                  <Icon as={DocIcon} w="4" h="auto" />
                  <Text fontWeight="medium" fontSize="sm">
                    {t("home.public.item.files", { count: room.filesCount })}
                  </Text>
                </HStack>
              </VStack>
            </Box>
          ))}
        </Wrap>
      ) : (
        <Spinner size="lg" display="block" mx="auto" mt="6" mb="2" />
      )}
    </Box>
  ) : null;
};

export default PublicRoomsSection;
