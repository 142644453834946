import { FC, SVGProps } from "react";

const LogoGlyph: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="204"
    height="38"
    viewBox="0 0 204 38"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="currentColor" fillRule="evenodd">
      <text fontFamily="Lexend Deca" fontSize="24" fontWeight="600">
        <tspan x="47" y="27">
          FileSwap.org
        </tspan>
      </text>
      <path
        d="m29.202 24.431 4.906 1.69-.983 2.834-4.907-1.69.984-2.834ZM2.84 17.684l4.907 1.69-.838 2.417 1.96.636-5.874 2.994L0 19.546l1.97.64.869-2.502Zm28.002 2.023 4.907 1.69-.984 2.834-4.907-1.689.984-2.835Zm-15.418 4.327-.075.2-1.109 3.21a3.436 3.436 0 0 0 3.06 4.553l.188.005h7.243c1.043 0 1.98-.624 2.385-1.573l.066-.171.382-1.104 4.907 1.69-.386 1.115a7.784 7.784 0 0 1-7.079 5.228l-.275.005h-7.243A8.626 8.626 0 0 1 9.224 26.09l.11-.342 1.11-3.21a9.71 9.71 0 0 1 8.895-6.534l.282-.004a4.52 4.52 0 0 0 4.198-2.844l.074-.2 1.11-3.21a3.436 3.436 0 0 0-3.06-4.552l-.188-.005h-7.243c-1.044 0-1.98.624-2.385 1.573l-.066.17-.382 1.104-4.907-1.69.386-1.114A7.784 7.784 0 0 1 14.237.005L14.512 0h7.243a8.626 8.626 0 0 1 8.263 11.1l-.11.343-1.11 3.21a9.71 9.71 0 0 1-8.896 6.534l-.281.004a4.52 4.52 0 0 0-4.198 2.843Zm20.825-12.263 2.994 5.875-1.97-.64-.869 2.501-4.907-1.689.838-2.417-1.96-.637 5.874-2.993Zm-31.77 1.19 4.907 1.688-.984 2.835-4.907-1.69.984-2.834Zm1.639-4.725 4.907 1.69-.984 2.834-4.906-1.69.983-2.834Z"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default LogoGlyph;
