import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const PeerIcon: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.087418 3v3.028042H16.5c.552285 0 1 .447716 1 1v2h-15L9.087418 3Zm1.825164 14.028042V14H3.5c-.552285 0-1-.447715-1-1v-2h15l-6.587418 6.028042Z"
    />
  </Icon>
);

export default PeerIcon;
