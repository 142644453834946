import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const UsersSmartIcon: FC<IconProps> = (props) => (
  <Icon width="82px" height="82px" viewBox="0 0 82 82" {...props}>
    <defs>
      <radialGradient
        id="users-smart-icon-a"
        cx="50%"
        cy="-13.398096%"
        r="243.049774%"
        fx="50%"
        fy="-13.398096%"
        gradientTransform="scale(-.447368 -1) rotate(-88.941586 -.172345 .823905)"
      >
        <stop offset="0%" stopColor="#645D5D" />
        <stop offset="100%" stopColor="#272727" />
      </radialGradient>
      <filter
        id="users-smart-icon-b"
        width="195.8%"
        height="176.7%"
        x="-47.9%"
        y="-31.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3.5"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.148898333   0 0 0 0 0.883361518   0 0 0 0 0.641910613  0 0 0 0.307719624 0"
        />
      </filter>
      <path
        id="users-smart-icon-c"
        d="M14.117647 2 24 12v17.142857C24 30.720814 22.735863 32 21.17647 32H2.82353C1.264136 32 0 30.720814 0 29.142857V4.857143C0 3.279186 1.264137 2 2.82353 2h11.294117Zm0 2.857143H2.82353v24.285714h18.352942L21.176 12h-3.43875c-1.258615 0-1.71502-.131048-2.17515-.377128-.46013-.246081-.821244-.607194-1.067324-1.067325-.246081-.46013-.377129-.916535-.377129-2.17515V4.857143Z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <path
          fill="#242424"
          d="M31 67.695488c-4.040103 2.029646-8.585093 3.20781-13.397304 3.298816L16.999795 71C11.964595 71 7.208574 69.809839 3 67.696635c.037345-.402734.037345-1.0694 0-2h28c-.0437.861671-.0437 1.527956 0 1.998853ZM16.999795 37c4.437351 0 8.034536 3.581722 8.034536 8 0 4.334914-3.462723 7.864543-7.784281 7.996193L16.999795 53c-4.437352 0-8.034536-3.581722-8.034536-8 0-4.334914 3.462722-7.864543 7.78428-7.996193L16.999796 37ZM79 67.695488c-4.040103 2.029646-8.585093 3.20781-13.397304 3.298816L64.999795 71C59.964595 71 55.208573 69.809839 51 67.696635c.037345-.402734.037345-1.0694 0-2h28c-.0437.861671-.0437 1.527956 0 1.998853ZM64.999795 37c4.437351 0 8.034535 3.581722 8.034535 8 0 4.334914-3.462722 7.864543-7.78428 7.996193L64.999795 53c-4.437352 0-8.034536-3.581722-8.034536-8 0-4.334914 3.462722-7.864543 7.78428-7.996193L64.999796 37Z"
        />
        <path
          fill="url(#users-smart-icon-a)"
          d="M13.999795 18C21.32339 18 27.339225 23.575129 28 30.695488c-4.040103 2.029646-8.585093 3.20781-13.397304 3.298816L13.999795 34C8.964595 34 4.208574 32.809839 0 30.696635c.650508-7.015215 6.497814-12.529869 13.67276-12.692923L13.999795 18Zm0-18c4.437351 0 8.034535 3.581722 8.034535 8 0 4.334914-3.462722 7.864543-7.78428 7.996193L13.999795 16c-4.437352 0-8.034536-3.581722-8.034536-8 0-4.334914 3.462722-7.864543 7.78428-7.996193L13.999796 0Zm48 18C69.32339 18 75.339225 23.575129 76 30.695488c-4.040103 2.029646-8.585093 3.20781-13.397304 3.298816L61.999795 34C56.964595 34 52.208574 32.809839 48 30.696635c.650508-7.015215 6.497814-12.529869 13.67276-12.692923L61.999795 18Zm0-18c4.437351 0 8.034535 3.581722 8.034535 8 0 4.334914-3.462722 7.864543-7.78428 7.996193L61.999795 16c-4.437352 0-8.034536-3.581722-8.034536-8 0-4.334914 3.462722-7.864543 7.78428-7.996193L61.999796 0Z"
          transform="translate(3 35)"
        />
      </g>
      <g transform="translate(29 12)">
        <use
          xlinkHref="#users-smart-icon-c"
          fill="#000"
          filter="url(#users-smart-icon-b)"
        />
        <use xlinkHref="#users-smart-icon-c" fill="#365347" />
      </g>
      <path
        fill="#26E1A4"
        d="M43.117647 12 53 22v17.142857C53 40.720814 51.735863 42 50.17647 42H31.82353C30.264136 42 29 40.720814 29 39.142857V14.857143C29 13.279186 30.264137 12 31.82353 12h11.294117Zm0 2.857143H31.82353v24.285714h18.352942L50.176 22h-3.43875c-1.258615 0-1.71502-.131048-2.17515-.377129-.46013-.24608-.821244-.607193-1.067324-1.067324-.246081-.46013-.377129-.916535-.377129-2.17515v-3.523254Z"
      />
    </g>
  </Icon>
);

export default UsersSmartIcon;
