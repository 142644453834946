import { FC } from "react";
import { Box, Flex, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import Panel from "../ui/Panel";
import ChatInput from "./ChatInput";
import ChatEntiresList from "./ChatEntiresList";

const ChatPanel: FC = () => {
  const { t } = useTranslation();

  return (
    <Panel
      title={t("room.chat.title")}
      noPadding
      h="full"
      display="flex"
      flexDirection="column"
    >
      <Flex h="full" direction="column" maxHeight="100%">
        <Flex flex="1" direction="column-reverse" overflow="auto">
          <VStack spacing="3" pt="4" px="3" alignItems="stretch">
            <ChatEntiresList />
          </VStack>
        </Flex>

        <Box flexShrink="0" p="2.5">
          <ChatInput />
        </Box>
      </Flex>
    </Panel>
  );
};

export default ChatPanel;
