import { FC } from "react";

import Icon, { SmartIconProps } from "./Icon";

const PeerSmartIcon: FC<SmartIconProps> = ({ isActive, ...rest }) => (
  <Icon width="32px" height="32px" viewBox="0 0 32 32" {...rest}>
    <defs>
      <linearGradient
        id="peer-smart-icon-c"
        x1="50%"
        x2="50%"
        y1="-17.541054%"
        y2="65.180299%"
      >
        <stop offset="0%" stopColor="#20C494" />
        <stop offset="100%" stopColor="#008C63" />
      </linearGradient>
      <linearGradient
        id="peer-smart-icon-f"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#00F8BB" />
        <stop offset="100%" stopColor="#26AA7F" />
      </linearGradient>
      <linearGradient
        id="peer-smart-icon-g"
        x1="27.180509%"
        x2="74.312808%"
        y1="-5.822543%"
        y2="78.834402%"
      >
        <stop offset="0%" stopColor="#C9C9C9" />
        <stop offset="100%" stopColor="#6B6969" />
      </linearGradient>
      <path
        id="peer-smart-icon-b"
        d="M13 26v2H8c-2.209139 0-4-1.790861-4-4V6c0-2.209139 1.790861-4 4-4h10.5L26 9.683406V17h-2v-5h-5.4c-1.43594 0-2.6-1.16406-2.6-2.6V4H8c-1.054362 0-1.918165.815878-1.994514 1.850738L6 6v18c0 1.054362.815878 1.918165 1.850738 1.994514L8 26h5Z"
      />
      <path
        id="peer-smart-icon-e"
        d="M22.587418 17v3.028042H30c.552285 0 1 .447716 1 1v2H16L22.587418 17Zm1.825164 14.028042V28H17c-.552285 0-1-.447715-1-1v-2h15l-6.587418 6.028042Z"
      />
      <radialGradient
        id="peer-smart-icon-a"
        cx="91.503067%"
        cy="84.465831%"
        r="135.416001%"
        fx="91.503067%"
        fy="84.465831%"
        gradientTransform="scale(-1 -.846154) rotate(47.490047 2.094637 -2.156843)"
      >
        <stop offset="0%" stopColor="#0F5F46" />
        <stop offset="100%" stopColor="#19E3A7" />
      </radialGradient>
      <filter
        id="peer-smart-icon-d"
        width="180%"
        height="185.5%"
        x="-40%"
        y="-42.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.148898333   0 0 0 0 0.883361518   0 0 0 0 0.641910613  0 0 0 0.299306163 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      {isActive ? (
        <>
          <use xlinkHref="#peer-smart-icon-b" fill="url(#peer-smart-icon-a)" />
          <use xlinkHref="#peer-smart-icon-b" fill="url(#peer-smart-icon-c)" />
          <use
            xlinkHref="#peer-smart-icon-e"
            fill="#000"
            filter="url(#peer-smart-icon-d)"
          />
          <use xlinkHref="#peer-smart-icon-e" fill="url(#peer-smart-icon-f)" />
        </>
      ) : (
        <>
          <use xlinkHref="#peer-smart-icon-b" fill="url(#peer-smart-icon-g)" />
          <use xlinkHref="#peer-smart-icon-e" fill="#C2C2C2" />
        </>
      )}
    </g>
  </Icon>
);

export default PeerSmartIcon;
