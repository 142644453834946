import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const ExternalLinkIcon: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      d="m16.176 4.95.007.126v7.315a1 1 0 0 1-1.992.125l-.008-.125V7.489L5.87 15.804a1 1 0 0 1-1.492-1.327l.078-.087 8.314-8.315h-4.9a1 1 0 0 1-.993-.874l-.008-.125a1 1 0 0 1 .875-.993l.125-.007h7.315a1 1 0 0 1 .993.874Z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </Icon>
);

export default ExternalLinkIcon;
