import React, { useState } from "react";
import { Box, BoxProps } from "@chakra-ui/react";

import useRotate from "../../hooks/useRotate";
import { minmax } from "../../utils/math";

export interface SliderProps extends BoxProps {
  viewClassname?: string;
  gutter?: number;
  timeout?: number;
}

const Slider: React.FC<SliderProps> = ({
  viewClassname,
  gutter = 0,
  timeout = 3000,
  className,
  children,
  ...rest
}) => {
  const slidesCount = React.Children.count(children);
  const [slideIndex, setSlideIndex] = useState(0);
  const activeSlideIndex = minmax(0, slidesCount, slideIndex);

  useRotate(slidesCount, timeout, activeSlideIndex, setSlideIndex);

  return (
    <Box overflow="hidden" {...rest}>
      <Box
        as="ul"
        style={{
          width: `calc(${100 * slidesCount}% + ${gutter * slidesCount}px)`,
          transform: `translate3d(-${
            (activeSlideIndex / slidesCount) * 100
          }%, 0, 0)`,
          willChange: "transform",
          touchAction: "pan-y",
        }}
        h="full"
        overflow="hidden"
        display="flex"
        alignItems="center"
        transition="transform 0.5s ease-out"
        listStyleType="none"
      >
        {React.Children.map(children, (child, index) => (
          <Box
            as="li"
            key={index}
            style={{
              width: `${(1 / slidesCount) * 100}%`,
              marginRight: gutter,
            }}
          >
            {child}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Slider;
