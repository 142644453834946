import { FC } from "react";

import Icon, { SmartIconProps } from "./Icon";

const UserSmartIcon: FC<SmartIconProps> = ({ isActive = true, ...rest }) => (
  <Icon size="24" {...rest}>
    <defs>
      <linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        id="user-smart-icon-a-active"
      >
        <stop stopColor="#5DBD91" offset="0%" />
        <stop stopColor="#6BECAF" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        id="user-smart-icon-a"
      >
        <stop stopColor="#B7B1B1" offset="0%" />
        <stop stopColor="#9A9696" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        id="user-smart-icon-d-active"
      >
        <stop stopColor="#74FFC9" offset="0%" />
        <stop stopColor="#59B288" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        id="user-smart-icon-d"
      >
        <stop stopColor="#C4C4C4" offset="0%" />
        <stop stopColor="#9A9A9A" offset="100%" />
      </linearGradient>
      <filter
        x="-68.8%"
        y="-43.8%"
        width="237.5%"
        height="237.5%"
        filterUnits="objectBoundingBox"
        id="user-smart-icon-b"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="1.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.334380463 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <circle id="user-smart-icon-c" cx="6" cy="4" r="4" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect
        stroke={isActive ? "#26E1A4" : "#6A6A6A"}
        fill={isActive ? "#484848" : "#343434"}
        x=".5"
        y=".5"
        width="23"
        height="23"
        rx="5"
      />
      <g transform="translate(6 4)">
        <ellipse
          fill={`url(#user-smart-icon-a${isActive ? "-active" : ""})`}
          cx="6"
          cy="11"
          rx="6"
          ry="4"
        />
        <use
          fill="#000"
          filter="url(#user-smart-icon-b)"
          xlinkHref="#user-smart-icon-c"
        />
        <circle
          stroke={isActive ? "#62CA9C" : "#9F9898"}
          // @ts-ignore
          strokeLinejoin="square"
          fill={`url(#user-smart-icon-d${isActive ? "-active" : ""})`}
          cx="6"
          cy="4"
          r="3.5"
        />
      </g>
    </g>
  </Icon>
);

export default UserSmartIcon;
