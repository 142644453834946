import { FC, useContext } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import log from "loglevel";

import { PasswordSmartIcon } from "../icons";
import Alert from "../ui/Alert";
import RoomSocketConnectionContext from "../../contexts/RoomSocketConnectionContext";
import { useAddStatusAlert } from "../../stores/snackbarStore";

interface IRoomPasswordFormValues {
  password: string;
}

const RoomPasswordForm: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
  } = useForm<IRoomPasswordFormValues>({ defaultValues: { password: "" } });
  const { t } = useTranslation();
  const addStatusAlert = useAddStatusAlert();
  const { join } = useContext(RoomSocketConnectionContext);

  const onSubmit = async ({ password }: IRoomPasswordFormValues) => {
    try {
      await join({ password });
    } catch (err) {
      log.error(err);

      if ((err as { reason: "password" }).reason === "password") {
        setError("password", { type: "invalid" });
      } else {
        addStatusAlert({ status: "error", content: t("alerts.error.default") });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="4" pb="2" align="stretch">
        <Alert icon={PasswordSmartIcon}>{t("room.passwordForm.info")}</Alert>

        <FormControl isInvalid={!!errors.password}>
          <Input
            placeholder={t("room.passwordForm.password.placeholder")}
            type="password"
            autoComplete="off"
            {...register("password", {
              required: true,
              maxLength: 30,
            })}
          />
          {errors.password && (
            <FormErrorMessage>
              {t(`validation.${errors.password.type}`, {
                field: t("common.password"),
              })}
            </FormErrorMessage>
          )}
        </FormControl>

        <Button type="submit" isLoading={isSubmitting}>
          {t("room.passwordForm.submit")}
        </Button>
      </VStack>
    </form>
  );
};

export default RoomPasswordForm;
