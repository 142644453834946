import { FC } from "react";

import Icon, { SmartIconProps } from "./Icon";

const CloudSmartIcon: FC<SmartIconProps> = ({ isActive, ...rest }) => (
  <Icon width="32px" height="32px" viewBox="0 0 32 32" {...rest}>
    <defs>
      <linearGradient
        id="cloud-smart-icon-c"
        x1="50%"
        x2="50%"
        y1="-17.541054%"
        y2="65.180299%"
      >
        <stop offset="0%" stopColor="#20C494" />
        <stop offset="100%" stopColor="#008C63" />
      </linearGradient>
      <linearGradient
        id="cloud-smart-icon-g"
        x1="27.180509%"
        x2="74.312808%"
        y1="-5.822543%"
        y2="78.834402%"
      >
        <stop offset="0%" stopColor="#C9C9C9" />
        <stop offset="100%" stopColor="#6B6969" />
      </linearGradient>
      <filter
        id="cloud-smart-icon-a"
        width="166.7%"
        height="209.1%"
        x="-33.3%"
        y="-54.5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.148898333   0 0 0 0 0.883361518   0 0 0 0 0.641910613  0 0 0 0.299306163 0"
        />
      </filter>
      <path
        id="cloud-smart-icon-b"
        d="M28 29h.5c1.932997 0 3.5-1.567003 3.5-3.5S30.432997 22 28.5 22c-.235903 0-.466355.023339-.689173.067833C27.181783 19.724957 25.042334 18 22.5 18c-3.037566 0-5.5 2.462434-5.5 5.5 0 .187961.009429.37372.027839.55683C16.858216 24.019313 16.681363 24 16.5 24c-1.380712 0-2.5 1.119288-2.5 2.5s1.119288 2.5 2.5 2.5H28Z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use
        xlinkHref="#cloud-smart-icon-b"
        fill="#000"
        filter="url(#cloud-smart-icon-a)"
      />
      <use
        xlinkHref="#cloud-smart-icon-b"
        fill={isActive ? "#26E1A4" : "#C2C2C2"}
      />
      <path
        fill={
          isActive ? "url(#cloud-smart-icon-c)" : "url(#cloud-smart-icon-g)"
        }
        d="M11.023106 26c-.040446.610267-.040446 1.057146 0 1.340637.040446.28349.122884.503278.247314.659363H8c-2.209139 0-4-1.790861-4-4V6c0-2.209139 1.790861-4 4-4h10.5L26 9.683406V16c-.397114-.36916-.72088-.630665-.971298-.784513-.250417-.153848-.593318-.30245-1.028702-.445807V12h-5.4c-1.43594 0-2.6-1.16406-2.6-2.6V4H8c-1.054362 0-1.918165.815878-1.994514 1.850738L6 6v18c0 1.054362.815878 1.918165 1.850738 1.994514L8 26h3.023106Z"
      />
    </g>
  </Icon>
);

export default CloudSmartIcon;
