import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const LogoIcon: FC<IconProps> = (props) => (
  <Icon size="46" {...props}>
    <defs>
      <filter
        x="-17.8%"
        y="-13.4%"
        width="135.7%"
        height="137.6%"
        filterUnits="objectBoundingBox"
        id="logo-icon-a"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="2"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0.423529412 0 0 0 0 0.870588235 0 0 0 0 0.658823529 0 0 0 0.432637675 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path
        d="m29.537 25.837 4.906 1.69-.983 2.834-4.907-1.69.984-2.834ZM3.174 19.089l4.907 1.69-.838 2.417 1.96.636-5.875 2.994-2.993-5.875 1.97.64.869-2.502Zm28.002 2.024 4.907 1.69-.984 2.834-4.907-1.69.984-2.834ZM15.758 25.44l-.075.2-1.109 3.21a3.436 3.436 0 0 0 3.059 4.552l.189.005h7.242c1.044 0 1.98-.624 2.386-1.573l.066-.171.382-1.103 4.907 1.69-.386 1.114a7.784 7.784 0 0 1-7.08 5.228l-.275.005h-7.242a8.626 8.626 0 0 1-8.263-11.101l.11-.342 1.11-3.21a9.71 9.71 0 0 1 8.895-6.534l.282-.004a4.52 4.52 0 0 0 4.198-2.844l.074-.2 1.11-3.21a3.436 3.436 0 0 0-3.06-4.552l-.188-.005h-7.243c-1.044 0-1.98.624-2.385 1.573l-.066.171-.382 1.103-4.907-1.69.386-1.114a7.784 7.784 0 0 1 7.079-5.228l.275-.005h7.243a8.626 8.626 0 0 1 8.263 11.101l-.11.342-1.11 3.21a9.71 9.71 0 0 1-8.896 6.534l-.281.004a4.52 4.52 0 0 0-4.198 2.844Zm20.825-12.264 2.994 5.875-1.971-.64-.868 2.502-4.907-1.69.838-2.417-1.961-.636 5.875-2.994Zm-31.77 1.19 4.907 1.688-.984 2.835-4.907-1.69.984-2.834ZM6.452 9.64l4.907 1.69-.984 2.834-4.906-1.69.983-2.834Z"
        id="logo-icon-b"
      />
    </defs>
    <g transform="translate(3 1)" fillRule="nonzero" fill="none">
      <use fill="#000" filter="url(#logo-icon-a)" xlinkHref="#logo-icon-b" />
      <use fill="#26E1A4" xlinkHref="#logo-icon-b" />
    </g>
  </Icon>
);

export default LogoIcon;
