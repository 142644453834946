import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Center,
  Heading,
  Text,
  Wrap,
  WrapProps,
  WrapItem,
  Spinner,
} from "@chakra-ui/react";

import FileItem from "./FileItem";
import UploadArea from "./UploadArea";
import { useIsRoomLoaded, useRoomStore } from "../../stores/roomStore";
import { IMember } from "../../types/room";

interface IFilesListProps extends WrapProps {}

const FilesList: FC<IFilesListProps> = (props) => {
  const { t } = useTranslation();
  const isRoomLoaded = useIsRoomLoaded();
  const files = useRoomStore((state) => {
    const sortedFiles =
      state.files
        ?.slice()
        .sort(
          (a, b) =>
            new Date(b.uploadedAt).getTime() - new Date(a.uploadedAt).getTime()
        ) || [];

    const fileDescriptiors = sortedFiles.map((file) => {
      // const downloaders = state.fileDownloaders[file.id] ?? [];

      return {
        file,
        downloaders: (state.fileDownloaders[file.id] ?? [])
          .map((id) => state.members.find((m) => m.id === id))
          .filter((m) => !!m) as IMember[],
      };
    });

    return fileDescriptiors;
  });

  return (
    <>
      {isRoomLoaded ? (
        files.length > 0 ? (
          <Wrap spacing="3" overflow="visible" {...props}>
            {files.map(({ file, downloaders }) => (
              <WrapItem key={file.id}>
                <FileItem file={file} downloaders={downloaders} />
              </WrapItem>
            ))}
          </Wrap>
        ) : (
          <Box p="8" textAlign="center">
            <Center>
              <svg width="249" height="115" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <filter
                    x="-29.2%"
                    y="-14.2%"
                    width="158.4%"
                    height="128.4%"
                    filterUnits="objectBoundingBox"
                    id="a"
                  >
                    <feOffset
                      dy="1"
                      in="SourceAlpha"
                      result="shadowOffsetOuter1"
                    />
                    <feGaussianBlur
                      stdDeviation="3"
                      in="shadowOffsetOuter1"
                      result="shadowBlurOuter1"
                    />
                    <feColorMatrix
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.206430288 0"
                      in="shadowBlurOuter1"
                      result="shadowMatrixOuter1"
                    />
                    <feMerge>
                      <feMergeNode in="shadowMatrixOuter1" />
                      <feMergeNode in="SourceGraphic" />
                    </feMerge>
                  </filter>
                  <filter
                    x="-11.7%"
                    y="-28.1%"
                    width="123.4%"
                    height="156.2%"
                    filterUnits="objectBoundingBox"
                    id="b"
                  >
                    <feGaussianBlur stdDeviation="3" in="SourceGraphic" />
                  </filter>
                </defs>
                <g fill="none" fillRule="evenodd">
                  <path
                    d="M249 78.857c0 2.84-2.318 5.143-5.176 5.143h-33.648c-2.858 0-5.176-2.303-5.176-5.143V35.143c0-2.84 2.318-5.143 5.176-5.143h19.638c.684 0 1.34.27 1.826.753l16.595 16.487a2.59 2.59 0 0 1 .765 1.838v29.78Z"
                    fill="#212121"
                  />
                  <path
                    d="m231.261 30.376 17.357 17.244c.244.243.382.574.382.919v30.318c0 2.84-2.318 5.143-5.176 5.143h-33.648c-2.858 0-5.176-2.303-5.176-5.143V35.143c0-2.84 2.318-5.143 5.176-5.143h20.172c.342 0 .67.135.913.376ZM237 48.534l-.352-.002-.637-.016c-1.187-.047-1.88-.188-2.453-.414l-.278-.12a6.133 6.133 0 0 1-.268-.135 4.688 4.688 0 0 1-1.957-1.944 6.062 6.062 0 0 1-.135-.267l-.121-.276c-.228-.568-.37-1.257-.417-2.437l-.016-.632-.02-11.005h-20.17c-2.067 0-3.757 1.605-3.875 3.63l-.007.227v43.714c0 2.054 1.616 3.733 3.654 3.85l.228.007h33.648c2.067 0 3.757-1.605 3.875-3.63l.007-.227V48.534H237Zm-5.304-15.906-.038 9.313.002.322.012.578.025.498c.06.846.193 1.346.432 1.827l.068.13c.33.614.807 1.089 1.425 1.417.527.28 1.042.43 1.97.496l.502.025.28.008.626.006 9.373-.039-14.677-14.581Z"
                    fill="#343434"
                    fillRule="nonzero"
                  />
                  <text
                    fontFamily="Roboto-Bold, Roboto"
                    fontSize="15.543"
                    fontWeight="bold"
                    fill="#6A6A6A"
                    transform="translate(205 30)"
                  >
                    <tspan x="7.478" y="37.143">
                      TSX
                    </tspan>
                  </text>
                  <path
                    d="M44 78.857C44 81.697 41.682 84 38.824 84H5.176C2.318 84 0 81.697 0 78.857V35.143C0 32.303 2.318 30 5.176 30h19.638c.684 0 1.34.27 1.826.753L43.235 47.24A2.59 2.59 0 0 1 44 49.078v29.78Z"
                    fill="#212121"
                  />
                  <path
                    d="M26.261 30.376 43.618 47.62c.244.243.382.574.382.919v30.318C44 81.697 41.682 84 38.824 84H5.176C2.318 84 0 81.697 0 78.857V35.143C0 32.303 2.318 30 5.176 30h20.172c.342 0 .67.135.913.376ZM32 48.534l-.352-.002-.637-.016c-1.187-.047-1.88-.188-2.453-.414l-.278-.12a6.133 6.133 0 0 1-.268-.135 4.688 4.688 0 0 1-1.957-1.944 6.062 6.062 0 0 1-.135-.267l-.121-.276c-.228-.568-.37-1.257-.417-2.437l-.016-.632-.02-11.005H5.175c-2.067 0-3.757 1.605-3.875 3.63l-.007.227v43.714c0 2.054 1.616 3.733 3.654 3.85l.228.007h33.648c2.067 0 3.757-1.605 3.875-3.63l.007-.227V48.534H32Zm-5.304-15.906-.038 9.313.002.322.012.578.025.498c.06.846.193 1.346.432 1.827l.068.13c.33.614.807 1.089 1.425 1.417.527.28 1.042.43 1.97.496l.502.025.28.008.626.006 9.373-.039-14.677-14.581Z"
                    fill="#343434"
                    fillRule="nonzero"
                  />
                  <text
                    fontFamily="Roboto-Bold, Roboto"
                    fontSize="15.543"
                    fontWeight="bold"
                    fill="#6A6A6A"
                    transform="translate(0 30)"
                  >
                    <tspan x="7.06" y="37.143">
                      ENV
                    </tspan>
                  </text>
                  <g>
                    <path
                      d="M92 84.524A6.473 6.473 0 0 1 85.53 91H43.47C39.898 91 37 88.1 37 84.524V29.476A6.473 6.473 0 0 1 43.47 23h24.54c.86 0 1.684.342 2.292.95l20.75 20.768c.607.608.948 1.43.948 2.29v37.516Z"
                      fill="#212121"
                    />
                    <path
                      d="m69.827 23.475 21.7 21.718c.303.303.473.715.473 1.144v38.187A6.473 6.473 0 0 1 85.53 91H43.47C39.898 91 37 88.1 37 84.524V29.476A6.473 6.473 0 0 1 43.47 23h25.212c.43 0 .842.17 1.145.475ZM77 46.339l-.44-.003-.796-.02c-1.484-.06-2.351-.236-3.067-.521l-.347-.152a7.644 7.644 0 0 1-.335-.17 5.882 5.882 0 0 1-2.446-2.447 7.656 7.656 0 0 1-.17-.336l-.15-.347c-.286-.716-.462-1.584-.522-3.07l-.02-.796-.025-13.858H43.47a4.855 4.855 0 0 0-4.845 4.572l-.008.285v55.048a4.856 4.856 0 0 0 4.567 4.849l.286.008h42.058a4.855 4.855 0 0 0 4.845-4.572l.008-.285V46.339H77Zm-6.63-20.03-.047 11.727.001.407.016.727.032.627c.074 1.065.24 1.695.539 2.3l.085.165a4.264 4.264 0 0 0 1.782 1.784c.658.352 1.302.543 2.463.624l.626.032.35.01.783.008 11.716-.049L70.37 26.309Z"
                      fill="#343434"
                      fillRule="nonzero"
                    />
                    <text
                      fontFamily="Roboto-Bold, Roboto"
                      fontSize="19.429"
                      fontWeight="bold"
                      fill="#6A6A6A"
                      transform="translate(37 23)"
                    >
                      <tspan x="9.191" y="47.143">
                        JPG
                      </tspan>
                    </text>
                  </g>
                  <g>
                    <path
                      d="M212 84.524A6.473 6.473 0 0 1 205.53 91h-42.06c-3.573 0-6.47-2.9-6.47-6.476V29.476A6.473 6.473 0 0 1 163.47 23h24.54c.86 0 1.684.342 2.292.95l20.75 20.768c.607.608.948 1.43.948 2.29v37.516Z"
                      fill="#212121"
                    />
                    <path
                      d="m189.827 23.475 21.7 21.718c.303.303.473.715.473 1.144v38.187A6.473 6.473 0 0 1 205.53 91h-42.06c-3.573 0-6.47-2.9-6.47-6.476V29.476A6.473 6.473 0 0 1 163.47 23h25.212c.43 0 .842.17 1.145.475ZM197 46.339l-.44-.003-.796-.02c-1.484-.06-2.351-.236-3.067-.521l-.347-.152a7.644 7.644 0 0 1-.335-.17 5.882 5.882 0 0 1-2.446-2.447 7.656 7.656 0 0 1-.17-.336l-.15-.347c-.286-.716-.462-1.584-.522-3.07l-.02-.796-.025-13.858H163.47a4.855 4.855 0 0 0-4.845 4.572l-.008.285v55.048a4.856 4.856 0 0 0 4.567 4.849l.286.008h42.058a4.855 4.855 0 0 0 4.845-4.572l.008-.285V46.339H197Zm-6.63-20.03-.047 11.727.001.407.016.727.032.627c.074 1.065.24 1.695.539 2.3l.085.165a4.264 4.264 0 0 0 1.782 1.784c.658.352 1.302.543 2.463.624l.626.032.35.01.783.008 11.716-.049-18.346-18.362Z"
                      fill="#343434"
                      fillRule="nonzero"
                    />
                    <text
                      fontFamily="Roboto-Bold, Roboto"
                      fontSize="19.429"
                      fontWeight="bold"
                      fill="#6A6A6A"
                      transform="translate(157 23)"
                    >
                      <tspan x="7.151" y="47.143">
                        MP4
                      </tspan>
                    </text>
                  </g>
                  <g filter="url(#a)" transform="translate(86 10)">
                    <path
                      d="M77 85.952C77 90.95 72.944 95 67.941 95H9.06C4.056 95 0 90.95 0 85.952V9.048C0 4.05 4.056 0 9.059 0h34.359c1.201 0 2.354.477 3.204 1.326L75.67 30.339A4.533 4.533 0 0 1 77 33.546v52.406Z"
                      fill="#212121"
                    />
                    <path
                      d="m45.958.663 30.377 30.34c.426.425.665 1.002.665 1.603v53.346C77 90.95 72.944 95 67.941 95H9.06C4.056 95 0 90.95 0 85.952V9.048C0 4.05 4.056 0 9.059 0h35.297c.6 0 1.177.238 1.602.663ZM56 32.605l-.617-.003-1.114-.028c-2.077-.083-3.291-.33-4.293-.729l-.486-.211a10.71 10.71 0 0 1-.469-.237 8.226 8.226 0 0 1-3.424-3.42c-.083-.155-.162-.31-.237-.468l-.212-.486c-.4-1-.647-2.213-.73-4.288l-.028-1.112-.036-19.361H9.06a6.79 6.79 0 0 0-6.783 6.387l-.011.399v76.904a6.79 6.79 0 0 0 6.395 6.775l.399.011H67.94a6.79 6.79 0 0 0 6.783-6.387l.011-.399V32.605H56ZM46.719 4.623l-.067 16.384.002.567.022 1.017.044.875c.105 1.488.338 2.37.755 3.214l.119.23a5.964 5.964 0 0 0 2.495 2.492c.921.492 1.823.759 3.448.873l.877.044.49.014 1.096.01 16.403-.067L46.719 4.623Z"
                      fill="#343434"
                      fillRule="nonzero"
                    />
                    <text
                      opacity=".4"
                      filter="url(#b)"
                      fontFamily="Roboto-Bold, Roboto"
                      fontSize="27.2"
                      fontWeight="bold"
                      fill="#26E1A4"
                    >
                      <tspan x="12.867" y="65.714">
                        JPG
                      </tspan>
                    </text>
                    <text
                      fontFamily="Roboto-Bold, Roboto"
                      fontSize="27.2"
                      fontWeight="bold"
                      fill="#26E1A4"
                    >
                      <tspan x="12.867" y="65.714">
                        JPG
                      </tspan>
                    </text>
                  </g>
                </g>
              </svg>
            </Center>
            <Heading mt="4" size="lg" color="gray.400">
              {t("room.files.empty.label")}
            </Heading>
            <Text mt="3" color="gray.600">
              {t("room.files.empty.description")}
            </Text>
            <Box display="inline-block" mt="6" maxWidth="320" w="full">
              <UploadArea inline />
            </Box>
          </Box>
        )
      ) : (
        <Box textAlign="center" py="8">
          <Spinner size="lg" />
        </Box>
      )}
    </>
  );
};

export default FilesList;
