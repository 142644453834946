import { FC } from "react";
import { IconButton, MenuButton, MenuButtonProps } from "@chakra-ui/react";

import { MeatballsIcon } from "../icons";

const MeatballsMenuButton: FC<MenuButtonProps> = (props) => (
  <MenuButton
    as={IconButton}
    icon={<MeatballsIcon />}
    variant="icon-ghost"
    h="auto"
    minW="auto"
    color="gray.500"
    _hover={{
      color: "brand.500",
    }}
    _active={{
      color: "brand.500",
    }}
    {...props}
  />
);

export default MeatballsMenuButton;
