import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const SwapSmartIcon: FC<IconProps> = (props) => (
  <Icon width="82px" height="82px" viewBox="0 0 82 82" {...props}>
    <defs>
      <radialGradient
        id="swap-smart-icon-a"
        cx="70.848397%"
        cy="-12.001495%"
        r="152.434001%"
        fx="70.848397%"
        fy="-12.001495%"
        gradientTransform="matrix(-.13676998 .63398581 -.99060283 -.08753279 .686496 -.57969)"
      >
        <stop offset="0%" stopColor="#474747" />
        <stop offset="100%" stopColor="#242424" />
      </radialGradient>
      <radialGradient
        id="swap-smart-icon-d"
        cx="53.319914%"
        cy="-3.538949%"
        r="203.74979%"
        fx="53.319914%"
        fy="-3.538949%"
        gradientTransform="matrix(-.05701442 .63895895 -.99837336 -.03648923 .528268 -.377373)"
      >
        <stop offset="0%" stopColor="#645D5D" />
        <stop offset="100%" stopColor="#272727" />
      </radialGradient>
      <radialGradient
        id="swap-smart-icon-e"
        cx="70.848397%"
        cy="-12.001495%"
        r="101.423156%"
        fx="70.848397%"
        fy="-12.001495%"
        gradientTransform="matrix(-.20555844 .95284941 -.6454783 -.30344357 .776652 -.831511)"
      >
        <stop offset="0%" stopColor="#474747" />
        <stop offset="100%" stopColor="#242424" />
      </radialGradient>
      <radialGradient
        id="swap-smart-icon-h"
        cx="53.319914%"
        cy="-3.538949%"
        r="131.312296%"
        fx="53.319914%"
        fy="-3.538949%"
        gradientTransform="scale(-.677419 -1) rotate(-82.496156 -.086596 .75281)"
      >
        <stop offset="0%" stopColor="#645D5D" />
        <stop offset="100%" stopColor="#272727" />
      </radialGradient>
      <radialGradient
        id="swap-smart-icon-i"
        cx="53.319914%"
        cy="-3.538949%"
        r="131.005477%"
        fx="53.319914%"
        fy="-3.538949%"
        gradientTransform="scale(-.794872 -1) rotate(-83.594944 -.029216 .673359)"
      >
        <stop offset="0%" stopColor="#645D5D" />
        <stop offset="100%" stopColor="#272727" />
      </radialGradient>
      <radialGradient
        id="swap-smart-icon-j"
        cx="70.848397%"
        cy="-12.001495%"
        r="103.202097%"
        fx="70.848397%"
        fy="-12.001495%"
        gradientTransform="scale(-.575758 -1) rotate(-69.459549 -.087889 1.398588)"
      >
        <stop offset="0%" stopColor="#474747" />
        <stop offset="100%" stopColor="#242424" />
      </radialGradient>
      <filter
        id="swap-smart-icon-c"
        width="125%"
        height="116%"
        x="-12.5%"
        y="-8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="4" in="SourceAlpha" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.121221595   0 0 0 0 0.1164416   0 0 0 0 0.116527982  0 0 0 0.36841674 0"
        />
      </filter>
      <filter
        id="swap-smart-icon-g"
        width="112.9%"
        height="119%"
        x="-6.5%"
        y="-9.5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="4" in="SourceAlpha" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.121221595   0 0 0 0 0.1164416   0 0 0 0 0.116527982  0 0 0 0.36841674 0"
        />
      </filter>
      <filter
        id="swap-smart-icon-l"
        width="106.1%"
        height="110.5%"
        x="-3%"
        y="-5.3%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.121221595   0 0 0 0 0.1164416   0 0 0 0 0.116527982  0 0 0 0.36841674 0"
        />
      </filter>
      <filter
        id="swap-smart-icon-m"
        width="533.3%"
        height="533.3%"
        x="-216.7%"
        y="-183.3%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="4"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0.148898333   0 0 0 0 0.883361518   0 0 0 0 0.641910613  0 0 0 0.307719624 0"
        />
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter2" />
        <feColorMatrix
          in="shadowOffsetOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 0.210825324   0 0 0 0 0.32433331   0 0 0 0 0.278071404  0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
      <filter
        id="swap-smart-icon-o"
        width="533.3%"
        height="533.3%"
        x="-216.7%"
        y="-183.3%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="4"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0.148898333   0 0 0 0 0.883361518   0 0 0 0 0.641910613  0 0 0 0.307719624 0"
        />
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter2" />
        <feColorMatrix
          in="shadowOffsetOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 0.210825324   0 0 0 0 0.32433331   0 0 0 0 0.278071404  0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
      <filter
        id="swap-smart-icon-q"
        width="533.3%"
        height="533.3%"
        x="-216.7%"
        y="-183.3%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="4"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0.148898333   0 0 0 0 0.883361518   0 0 0 0 0.641910613  0 0 0 0.307719624 0"
        />
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter2" />
        <feColorMatrix
          in="shadowOffsetOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 0.210825324   0 0 0 0 0.32433331   0 0 0 0 0.278071404  0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
      <circle id="swap-smart-icon-n" cx="8" cy="18" r="3" />
      <circle id="swap-smart-icon-p" cx="60" cy="12" r="3" />
      <circle id="swap-smart-icon-r" cx="31" cy="51" r="3" />
      <path
        id="swap-smart-icon-b"
        d="M11 6c2.761424 0 5 2.238576 5 5v15c0 2.761424-2.238576 5-5 5H5c-2.761424 0-5-2.238576-5-5V11c0-2.761424 2.238576-5 5-5h6Z"
      />
      <path
        id="swap-smart-icon-f"
        d="M26 0c2.761424 0 5 2.238576 5 5v11c0 2.761424-2.238576 5-5 5H5c-2.761424 0-5-2.238576-5-5V5c0-2.761424 2.238576-5 5-5h21Z"
      />
      <path
        id="swap-smart-icon-k"
        d="M34 3H5c-1.054362 0-1.918165.815878-1.994514 1.850738L3 5v15c0 1.054362.815878 1.918165 1.850738 1.994514L5 22h29c1.054362 0 1.918165-.815878 1.994514-1.850738L36 20V5c0-1.10457-.89543-2-2-2Z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#242424"
        fillRule="nonzero"
        d="M13 19c2.761424 0 5 2.238576 5 5v15c0 2.761424-2.238576 5-5 5H7c-2.761424 0-5-2.238576-5-5V24c0-2.761424 2.238576-5 5-5h6Zm0 2H7c-1.59768 0-2.90366 1.24892-2.994907 2.823727L4 24v15c0 1.59768 1.24892 2.90366 2.823727 2.994907L7 42h6c1.59768 0 2.90366-1.24892 2.994907-2.823727L16 39V24c0-1.59768-1.24892-2.90366-2.823727-2.994907L13 21Z"
      />
      <g transform="translate(2 11)">
        <use xlinkHref="#swap-smart-icon-b" fill="url(#swap-smart-icon-a)" />
        <use
          xlinkHref="#swap-smart-icon-b"
          fill="#000"
          filter="url(#swap-smart-icon-c)"
        />
      </g>
      <path
        fill="url(#swap-smart-icon-d)"
        fillRule="nonzero"
        d="M11 6c2.761424 0 5 2.238576 5 5v15c0 2.761424-2.238576 5-5 5H5c-2.761424 0-5-2.238576-5-5V11c0-2.761424 2.238576-5 5-5h6Zm0 2H5c-1.59768 0-2.90366 1.24892-2.994907 2.823727L2 11v15c0 1.59768 1.24892 2.90366 2.823727 2.994907L5 29h6c1.59768 0 2.90366-1.24892 2.994907-2.823727L14 26V11c0-1.59768-1.24892-2.90366-2.823727-2.994907L11 8Z"
        transform="translate(2 11)"
      />
      <path
        fill="#242424"
        fillRule="nonzero"
        d="M49 66.78818V69c0 2.761424-2.238576 5-5 5H23c-2.761424 0-5-2.238576-5-5v-2.21182h31Z"
      />
      <g transform="translate(18 51)">
        <use xlinkHref="#swap-smart-icon-f" fill="url(#swap-smart-icon-e)" />
        <use
          xlinkHref="#swap-smart-icon-f"
          fill="#000"
          filter="url(#swap-smart-icon-g)"
        />
      </g>
      <path
        fill="url(#swap-smart-icon-h)"
        fillRule="nonzero"
        d="M26 0c2.761424 0 5 2.238576 5 5v11c0 2.761424-2.238576 5-5 5H5c-2.761424 0-5-2.238576-5-5V5c0-2.761424 2.238576-5 5-5h21Zm0 3H5c-1.054362 0-1.918165.815878-1.994514 1.850738L3 5v11c0 1.054362.815878 1.918165 1.850738 1.994514L5 18h21c1.054362 0 1.918165-.815878 1.994514-1.850738L28 16V5c0-1.054362-.815878-1.918165-1.850738-1.994514L26 3Z"
        transform="translate(18 51)"
      />
      <g fillRule="nonzero">
        <path
          fill="#242424"
          d="M69 40v2c0 1.10457-.89543 2-2 2H56c-1.10457 0-2-.89543-2-2v-2h15Zm12-10.000327V33c0 2.761424-2.238576 5-5 5H47c-2.761424 0-5-2.238576-5-5v-3.000327h39Z"
        />
        <path
          fill="url(#swap-smart-icon-i)"
          d="M25 27c1.10457 0 2 .89543 2 2s-.89543 2-2 2H14c-1.10457 0-2-.89543-2-2s.89543-2 2-2h11Zm9-27c2.761424 0 5 2.238576 5 5v15c0 2.761424-2.238576 5-5 5H5c-2.761424 0-5-2.238576-5-5V5c0-2.761424 2.238576-5 5-5h29Zm0 3H5c-1.054362 0-1.918165.815878-1.994514 1.850738L3 5v15c0 1.054362.815878 1.918165 1.850738 1.994514L5 22h29c1.054362 0 1.918165-.815878 1.994514-1.850738L36 20V5c0-1.10457-.89543-2-2-2Z"
          transform="translate(42 11)"
        />
        <g transform="translate(42 11)">
          <use xlinkHref="#swap-smart-icon-k" fill="url(#swap-smart-icon-j)" />
          <use
            xlinkHref="#swap-smart-icon-k"
            fill="#000"
            filter="url(#swap-smart-icon-l)"
          />
        </g>
      </g>
      <path stroke="#26E1A4" strokeWidth="2" d="m62 23.5-52 6 23.5 32z" />
      <g transform="translate(2 11)">
        <use
          xlinkHref="#swap-smart-icon-n"
          fill="#000"
          filter="url(#swap-smart-icon-m)"
        />
        <use xlinkHref="#swap-smart-icon-n" fill="#26E1A4" />
      </g>
      <g transform="translate(2 11)">
        <use
          xlinkHref="#swap-smart-icon-p"
          fill="#000"
          filter="url(#swap-smart-icon-o)"
        />
        <use xlinkHref="#swap-smart-icon-p" fill="#26E1A4" />
      </g>
      <g transform="translate(2 11)">
        <use
          xlinkHref="#swap-smart-icon-r"
          fill="#000"
          filter="url(#swap-smart-icon-q)"
        />
        <use xlinkHref="#swap-smart-icon-r" fill="#26E1A4" />
      </g>
    </g>
  </Icon>
);

export default SwapSmartIcon;
