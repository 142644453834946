import ReactDOM from "react-dom/client";
import { FC, useEffect, useMemo, useRef } from "react";
import {
  AmbientLight,
  Color,
  DirectionalLight,
  Fog,
  MeshPhongMaterial,
  PerspectiveCamera,
  PointLight,
  Scene,
  Vector3,
  WebGLRenderer,
} from "three";
import { CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer";
import Globe from "three-globe";

import countries from "../../assets/countries.json";
import { Box, BoxProps, Fade, Icon } from "@chakra-ui/react";
import { UserIcon } from "../icons";
import FileIcon from "../common/FileIcon";
import { randEl } from "../../utils/array";
import { randBetween } from "../../utils/math";
import { RANDOM_FILE_EXTENSIONS } from "../../constants/extensions";
import { nanoid } from "nanoid";
import { AVATAR_COLORS } from "../../constants/colors";

const GlobeUserPosition: FC<TPosition> = ({ type }) => {
  const [delayUser, delayFile] = useMemo(
    () => [Math.random() / 2 + 0.25, Math.random() + 0.5],
    []
  );

  return (
    <Fade in delay={delayUser}>
      <Box position="relative" bottom="1.25rem">
        {type === "uploader" && (
          <Fade in delay={delayFile}>
            <Box
              position="absolute"
              left="0"
              bottom="100%"
              transform="translate(-20%, -0.25rem)"
              opacity="0.9"
              zIndex={2}
            >
              <Icon
                as={FileIcon}
                name={`.${randEl(RANDOM_FILE_EXTENSIONS)}`}
                animation="pulse 1s ease-in-out infinite alternate"
                transformOrigin="50% 110%"
                w="auto"
                h="auto"
              />
            </Box>
          </Fade>
        )}

        <Box
          p="0.375rem"
          borderRadius="100%"
          bg="var(--chakra-colors-gray-800)"
          lineHeight="0"
          opacity="0.75"
          display="block"
        >
          <Icon as={UserIcon} w="1rem" h="1rem" />
        </Box>
      </Box>
    </Fade>
  );
};

interface GlobeRendererProps extends BoxProps {
  size?: BoxProps["w"];
}

interface IPosition {
  id: string;
  index: number;
  lat: number;
  lng: number;
  dashStroke: number;
  dashGap: number;
  dashInitialGap: number;
  dashTime: number;
  color: string;
}

interface IUploaderPosition extends IPosition {
  type: "uploader";
}

interface IDownloaderPosition extends IPosition {
  type: "downloader";
  uploaderId: IPosition["id"];
}

type TPosition = IUploaderPosition | IDownloaderPosition;

const GlobeRenderer: FC<GlobeRendererProps> = ({ size = "480px", ...rest }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const { width, height } = containerRef.current!.getBoundingClientRect();

    const camera = new PerspectiveCamera(70, width / height, 0.01, 20000);
    camera.position.x = 0;
    camera.position.y = 0;
    camera.position.z = 225;

    const dLight = new DirectionalLight(0xffffff, 0.5);
    dLight.position.set(-800, 2000, 400);
    camera.add(dLight);

    const dLight1 = new DirectionalLight(0x0a946f, 0.375);
    dLight1.position.set(-200, 500, 200);
    camera.add(dLight1);

    const pLight = new PointLight(0x16ba89, 0.25);
    pLight.position.set(-200, 500, 200);
    camera.add(pLight);

    const scene = new Scene();
    scene.fog = new Fog(0xa6ffd8, 400, 2000);

    scene.add(camera);
    scene.add(new AmbientLight(0xf0fff7, 0.375));

    const globe = new Globe({
      waitForGlobeReady: true,
      animateIn: true,
    })
      .hexPolygonsData(countries.features)
      .hexPolygonResolution(2)
      .hexPolygonMargin(0.1)
      .hexPolygonAltitude(0.004)
      .hexPolygonColor(() => (Math.random() > 0.5 ? "#26E1A4" : "#4eedb3"))
      .showAtmosphere(true)
      .atmosphereColor("#0a946f")
      .atmosphereAltitude(0.25);

    const globeMaterial = globe.globeMaterial() as MeshPhongMaterial;
    globeMaterial.color = new Color(0x0a946f);
    globeMaterial.emissive = new Color(0x014739);
    globeMaterial.emissiveIntensity = 0.1;
    globeMaterial.shininess = 0.7;

    scene.add(globe);

    let globeMounted = false;
    globe.onGlobeReady(() => {
      if (globeMounted) {
        return;
      }
      globeMounted = true;

      setTimeout(() => {
        let uploaders: IUploaderPosition[] = [];
        let downloaders: IDownloaderPosition[] = [];
        let domRoots: {
          container: HTMLElement;
          root: ReactDOM.Root;
          userId: string;
        }[] = [];

        const renderUsers = () => {
          const users = [...uploaders, ...downloaders];
          globe
            .htmlElementsData(users)
            .htmlTransitionDuration(0)
            // @ts-ignore
            .htmlElement((position: TPosition) => {
              const div = document.createElement("div");
              const root = ReactDOM.createRoot(div);

              root.render(<GlobeUserPosition {...position} />);

              domRoots.push({ userId: position.id, container: div, root });

              return div;
            });

          globe
            .pointsData(users)
            .pointColor(() => "#fff")
            .pointsMerge(true)
            .pointAltitude(0.005)
            .pointRadius(0.25);

          setTimeout(() => {
            globe
              .ringsData(uploaders)
              .ringColor(
                () => (t: number) => `rgba(255,255,255,${1 - t / 1.5})`
              )
              .ringMaxRadius(12.5)
              .ringPropagationSpeed(3)
              .ringAltitude(0.0075)
              .ringRepeatPeriod(1000);
          }, 1000);

          globe
            .arcsData(
              downloaders.map((downloader) => {
                const uploader = uploaders.find(
                  ({ id }) => id === downloader.uploaderId
                )!;

                return {
                  startLat: uploader.lat,
                  startLng: uploader.lng,
                  endLat: downloader.lat,
                  endLng: downloader.lng,
                  ...downloader,
                };
              })
            )
            .arcColor(() => "rgba(255, 255, 255, 0.75)")
            .arcAltitudeAutoScale(1.02)
            .arcStroke("dashStroke")
            .arcDashGap("dashGap")
            .arcDashInitialGap("dashInitialGap")
            .arcDashAnimateTime("dashTime");
        };

        const spawnUsers = () => {
          // const newUploaders = uploaders.slice(randBetween(1, 4));
          const newUploaders = uploaders.slice(1);
          const newDownloaders = downloaders.filter((downloader) =>
            newUploaders.some(
              (uploader) => uploader.id === downloader.uploaderId
            )
          );

          const domRootsToRemove = domRoots.filter(
            ({ userId }) =>
              !(
                newUploaders.some(({ id }) => id === userId) ||
                newDownloaders.some(({ id }) => id === userId)
              )
          );

          domRoots = domRoots.filter(
            (root) =>
              !domRootsToRemove.some(({ userId }) => root.userId === userId)
          );
          uploaders = newUploaders;
          downloaders = newDownloaders;

          for (const { root, container } of domRootsToRemove) {
            root.unmount();
            container.remove();
          }

          const globeCenterCords = (() => {
            const center = new Vector3(0, 0, 1)
              .applyAxisAngle(new Vector3(-1, 0, 0), globe.rotation.x)
              .applyAxisAngle(new Vector3(0, -1, 0), globe.rotation.y);

            return globe.toGeoCoords(center);
          })();

          // const pushUploaders = new Array(randBetween(1, 3))
          const pushUploaders = new Array(1).fill(null).map((_, index, arr) => {
            const prevPos = index % 2 === 1 ? arr[index - 1] : null;
            // const [x, y] = countries.features[0].geometry.coordinates[0][0];
            // console.log(x, y);

            return {
              id: nanoid(),
              index,
              type: "uploader",
              color: randEl(AVATAR_COLORS),
              lat: prevPos
                ? prevPos.lat + (Math.random() - 0.5) * 10 + 5
                : globeCenterCords.lat + (Math.random() - 0.5) * 30,
              lng: prevPos
                ? prevPos.lng + (Math.random() - 0.5) * 20 + 10
                : globeCenterCords.lng + (Math.random() - 0.5) * 60 - 5,
            };
          }) as IUploaderPosition[];
          uploaders.push(...pushUploaders);
          downloaders.push(
            ...pushUploaders
              .map((uploader) =>
                new Array(randBetween(1, 3)).fill(null).map((_, index) => ({
                  id: nanoid(),
                  index,
                  uploaderId: uploader.id,
                  type: "downloader" as IDownloaderPosition["type"],
                  color: randEl(AVATAR_COLORS),
                  // lat: uploader.lat + (Math.random() - 0.5) * 10 + 5,
                  // lng: uploader.lng + (Math.random() - 0.5) * 20 + 10,
                  lat: uploader.lat + (Math.random() - 0.5) * 15 + 10,
                  lng: uploader.lng + (Math.random() - 0.5) * 30 + 20,
                  dashStroke: Math.random() * 0.25 + 0.375,
                  dashGap: Math.random() * 0.5 + 0.75,
                  dashInitialGap: Math.random() + 3,
                  dashTime: Math.random() * 250 + 750,
                }))
              )
              .flat()
          );

          renderUsers();
          setTimeout(spawnUsers, randBetween(5000, 7500));
        };

        spawnUsers();
      }, 1000);
    });

    const renderer = new WebGLRenderer({
      // antialias: true,
      alpha: true,
    });
    renderer.setSize(width, height);
    renderer.setPixelRatio(window.devicePixelRatio);

    const cssRenderer = new CSS2DRenderer();
    cssRenderer.setSize(width, height);
    cssRenderer.domElement.style.position = "absolute";
    cssRenderer.domElement.style.top = "0px";
    cssRenderer.domElement.style.pointerEvents = "none";

    const animate = () => {
      globe.rotation.x += 0.000125;
      globe.rotation.y -= 0.0005;
      renderer.render(scene, camera);
      cssRenderer.render(scene, camera);
    };
    renderer.setAnimationLoop(animate);

    containerRef.current?.appendChild(renderer.domElement);
    containerRef.current?.appendChild(cssRenderer.domElement);

    return () => {
      renderer.setAnimationLoop(null);
      containerRef.current?.removeChild(renderer.domElement);
      containerRef.current?.removeChild(cssRenderer.domElement);
    };
  }, []);

  return (
    <Box
      ref={containerRef}
      w={size}
      h={size}
      position="relative"
      pointerEvents="none"
      {...rest}
    />
  );
};

export default GlobeRenderer;
