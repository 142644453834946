import { useRoomStore } from "../stores/roomStore";
import { useProfileStore } from "../stores/profileStore";

const useRoomCanUpload = () => {
  const userId = useProfileStore((state) => state.profile?.uid);
  const ownerId = useRoomStore((state) => state.room?.ownerId);
  const allowUploadOthers = useRoomStore(
    (state) => state.room?.allowUploadOthers
  );

  return allowUploadOthers || (userId && ownerId === userId);
};

export default useRoomCanUpload;
