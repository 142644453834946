import { useCallback, useState } from "react";
import log from "loglevel";

import { IPublicRoom } from "../types/room";
import { getPublicList } from "../controllers/rooms";

const useFetchPublicRooms = () => {
  const [rooms, setRooms] = useState<IPublicRoom[] | null>(null);
  const fetchPublicRooms = useCallback(async () => {
    try {
      const { data } = await getPublicList();
      setRooms(data);
    } catch (err) {
      log.error(err);
    }
  }, []);

  return { rooms, fetchPublicRooms };
};

export default useFetchPublicRooms;
