import { FC } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import CreateRoomForm from "./CreateRoomForm";
import { useNavigate } from "react-router-dom";

type ICreateRoomModalProps = Omit<ModalProps, "children">;

const CreateRoomModal: FC<ICreateRoomModalProps> = ({ ...rest }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal scrollBehavior="outside" {...rest}>
      <ModalOverlay />
      <ModalContent pb="3">
        <ModalHeader>{t("home.create.title")}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <CreateRoomForm onCreate={(roomname) => navigate(`/${roomname}`)} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateRoomModal;
