import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Layout from "./base/Layout";
import Home from "./pages/Home";
import Room from "./pages/Room";

const App: FC = () => (
  <Routes>
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path=":name" element={<Room />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Route>
  </Routes>
);

export default App;
