import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const SecurityLockIcon: FC<IconProps> = (props) => (
  <Icon size="42" {...props}>
    <g fill="none" fillRule="evenodd">
      <rect
        width="28"
        height="18"
        x="7"
        y="19"
        stroke="currentColor"
        strokeWidth="2"
        rx="2"
      />
      <path
        stroke="currentColor"
        strokeWidth="2"
        d="M21 5c2.209139 0 4.209139.89543 5.656854 2.343146C28.10457 8.79086 29 10.79086 29 13v6H13v-6c0-2.209139.89543-4.209139 2.343146-5.656854C16.79086 5.89543 18.79086 5 21 5Z"
      />
      <rect width="2" height="8" x="20" y="24" fill="currentColor" rx="1" />
    </g>
  </Icon>
);

export default SecurityLockIcon;
