import { FC } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import status from "http-status";
import type { AxiosError } from "axios";

import * as roomsApi from "../../controllers/rooms";
import {
  PasswordSmartIcon,
  UserSmartIcon,
  RequestSmartIcon,
  PeerSmartIcon,
  IpfsSmartIcon,
  CloudSmartIcon,
  InfoIcon,
} from "../icons";
import { RoomAccess, RoomTransferProtocol } from "../../types/room";
import SegmentedControl from "../ui/SegmentedControl";
import useMediaQueryDesktop from "../../hooks/useMediaQueryDesktop";

interface ICreateRoomFormProps {
  onCreate: (name: string) => void;
  onCreateClick?: () => void;
}

interface ICreateRoomFormValues {
  name: string;
  transferProtocol: RoomTransferProtocol;
  access: RoomAccess;
  password?: string;
  sharePublic?: boolean;
  allowUploadOthers: boolean;
}

const CreateRoomForm: FC<ICreateRoomFormProps> = ({
  onCreate,
  onCreateClick,
}) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQueryDesktop();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    watch,
    setValue,
    setError,
  } = useForm<ICreateRoomFormValues>({
    defaultValues: {
      name: "",
      transferProtocol: RoomTransferProtocol.P2P,
      access: RoomAccess.All,
      sharePublic: false,
      allowUploadOthers: true,
    },
  });
  const transferProtocol = watch("transferProtocol");
  const access = watch("access");

  const onSubmit = async ({
    name,
    transferProtocol,
    access,
    password,
    sharePublic,
    allowUploadOthers,
  }: ICreateRoomFormValues) => {
    try {
      await roomsApi.create({
        name,
        transferProtocol,
        access,
        ...(password ? { password } : {}),
        ...(access === RoomAccess.All && sharePublic ? { sharePublic } : {}),
        allowUploadOthers,
      });
      onCreate(name);
    } catch (err) {
      if ((err as AxiosError).response?.status === status.CONFLICT) {
        setError("name", { type: "nameUsed" });
      } else {
        console.error(err);
      }
    }
  };

  return (
    <VStack
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      spacing="4"
      align="stretch"
    >
      <FormControl isInvalid={!!errors.name}>
        <FormLabel>{t("home.create.name.label")}</FormLabel>
        <Input
          placeholder={t("home.create.name.placeholder")}
          autoCorrect="off"
          autoCapitalize="none"
          {...register("name", {
            required: true,
            minLength: 1,
            maxLength: 20,
            pattern: /^[a-z0-9-_]*$/i,
          })}
        />
        {errors.name && (
          <FormErrorMessage>
            {t(`validation.${errors.name.type}`, {
              field: t("home.create.name.label"),
            })}
          </FormErrorMessage>
        )}
      </FormControl>

      <FormControl>
        <FormLabel>{t("home.create.protocol.label")}</FormLabel>
        <SegmentedControl
          options={[
            {
              icon: PeerSmartIcon,
              label: t("home.create.protocol.p2p"),
              value: RoomTransferProtocol.P2P,
            },
            {
              icon: CloudSmartIcon,
              label: t("home.create.protocol.webtorrent"),
              value: RoomTransferProtocol.Webtorrent,
            },
            {
              icon: IpfsSmartIcon,
              label: t("home.create.protocol.ipfs"),
              value: RoomTransferProtocol.IPFS,
            },
          ]}
          value={transferProtocol}
          onChange={(protocol) =>
            setValue("transferProtocol", protocol as RoomTransferProtocol)
          }
        />
      </FormControl>

      {transferProtocol !== RoomTransferProtocol.P2P && (
        <HStack
          spacing="2"
          px="3"
          py="2"
          borderRadius="md"
          color="#F2B324"
          bgColor="#423822"
          border="1px"
          borderColor="#61470D"
        >
          <Icon as={InfoIcon} w="auto" h="auto" />
          <Text
            fontSize="sm"
            dangerouslySetInnerHTML={{
              __html: t("home.create.protocol.alert", {
                protocol:
                  transferProtocol === RoomTransferProtocol.IPFS
                    ? transferProtocol.toUpperCase()
                    : transferProtocol[0].toUpperCase() +
                      transferProtocol.slice(1),
              }),
            }}
          />
        </HStack>
      )}

      <HStack
        spacing="2"
        px="3"
        py="2"
        borderRadius="md"
        color="brand.200"
        bgColor="brand.900"
        border="1px"
        borderColor="brand.800"
      >
        <Icon as={InfoIcon} w="auto" h="auto" />
        <Box>
          <Text fontSize="sm">
            <b>P2P</b> - best to share with small group of people
          </Text>
          <Text fontSize="sm">
            <b>WebTorrent</b> - for sharing files on WebTorrent network
          </Text>
          <Text fontSize="sm">
            <b>IPFS</b> - better for sharing with large group
          </Text>
        </Box>
      </HStack>

      <FormControl>
        <FormLabel>{t("home.create.access.label")}</FormLabel>
        <SegmentedControl
          options={[
            {
              icon: UserSmartIcon,
              label: t("home.create.access.all"),
              value: RoomAccess.All,
            },
            {
              icon: PasswordSmartIcon,
              label: t("home.create.access.password"),
              value: RoomAccess.Password,
            },
            {
              icon: RequestSmartIcon,
              label: t("home.create.access.request"),
              value: RoomAccess.Request,
            },
          ]}
          value={access}
          onChange={(access) => setValue("access", access as RoomAccess)}
        />
      </FormControl>

      {access === RoomAccess.Password && (
        <FormControl isInvalid={!!errors.password}>
          <FormLabel>{t("common.password")}</FormLabel>
          <Input
            placeholder={t("home.create.password.placeholder")}
            type="password"
            autoComplete="off"
            {...register("password", {
              minLength: 1,
              maxLength: 50,
            })}
            autoFocus={isDesktop}
          />
          {errors.password && (
            <FormErrorMessage>
              {t(`validation.${errors.password.type}`, {
                field: t("common.password"),
              })}
            </FormErrorMessage>
          )}
        </FormControl>
      )}

      {access === RoomAccess.All && (
        <Checkbox flexShrink="0" {...register("sharePublic")}>
          {t("home.create.public.label")}
        </Checkbox>
      )}
      <Checkbox flexShrink="0" {...register("allowUploadOthers")}>
        {t("home.create.allowUploadOthers")}
      </Checkbox>

      <Button
        {...(onCreateClick
          ? {
              type: "button",
              onClick: onCreateClick,
            }
          : {
              type: "submit",
            })}
        isLoading={isSubmitting}
        w="full"
      >
        {t("home.create.submit")}
      </Button>
    </VStack>
  );
};

export default CreateRoomForm;
