import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const ErrorIcon: FC<IconProps> = (props) => (
  <Icon size="24" {...props}>
    <defs>
      <radialGradient
        id="error-icon-d"
        cx="87.816%"
        cy="0%"
        r="122.011%"
        fx="87.816%"
        fy="0%"
      >
        <stop offset="0%" stopColor="#E8643C" />
        <stop offset="100%" stopColor="#E92828" />
      </radialGradient>
      <radialGradient id="c" cx="50%" cy="0%" r="146.813%" fx="50%" fy="0%">
        <stop offset="0%" stopColor="#C22685" />
        <stop offset="0%" stopColor="#C22685" />
        <stop offset="100%" stopColor="#8F114D" />
      </radialGradient>
      <filter
        id="error-icon-a"
        width="200%"
        height="200%"
        x="-50%"
        y="-40%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.91372549 0 0 0 0 0.156862745 0 0 0 0 0.156862745 0 0 0 0.358719406 0"
        />
      </filter>
      <path
        id="error-icon-b"
        d="M14 0a6 6 0 0 1 6 6v8a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6a6 6 0 0 1 6-6h8Zm-.957 5.5L10 8.542 6.957 5.5 5.5 6.957 8.542 10 5.5 13.043 6.957 14.5 10 11.457l3.043 3.043 1.457-1.457L11.457 10 14.5 6.957 13.043 5.5Z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(2 2)">
      <use xlinkHref="#error-icon-b" fill="#000" filter="url(#error-icon-a)" />
      <path
        fill="url(#error-icon-d)"
        stroke="url(#error-icon-c)"
        // @ts-ignore
        strokeLinejoin="square"
        d="M14 .5c1.519 0 2.894.616 3.89 1.61a5.482 5.482 0 0 1 1.603 3.607L19.5 6v8a5.483 5.483 0 0 1-1.61 3.89 5.482 5.482 0 0 1-3.607 1.603L14 19.5H6a5.483 5.483 0 0 1-3.89-1.61 5.482 5.482 0 0 1-1.603-3.607L.5 14V6c0-1.519.616-2.894 1.61-3.89A5.482 5.482 0 0 1 5.718.508L6 .5h8Zm-.957 4.293L10 7.835 6.957 4.793 4.793 6.957 7.835 10l-3.042 3.043 2.164 2.164L10 12.164l3.043 3.043 2.164-2.164L12.164 10l3.043-3.043-2.164-2.164Z"
      />
    </g>
  </Icon>
);

export default ErrorIcon;
