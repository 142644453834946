import { FC } from "react";
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useClipboard,
} from "@chakra-ui/react";

import Panel from "../ui/Panel";
import { CopyIcon } from "../icons";
import { useAddStatusAlert } from "../../stores/snackbarStore";
import { useTranslation } from "react-i18next";
import { useRoomStore } from "../../stores/roomStore";

const SharePanel: FC = () => {
  const roomname = useRoomStore((state) => state.room?.name);
  const roomUrl =
    roomname && `${process.env.REACT_APP_WEBSITE_ORIGIN}/${roomname}`;
  const addStatusAlert = useAddStatusAlert();
  const { t } = useTranslation();

  const { onCopy } = useClipboard(roomUrl || "");

  const handleCopy = () => {
    onCopy();
    addStatusAlert({ status: "success", content: t("alerts.success.copied") });
  };

  return (
    <Panel title="Share" flexShrink="0">
      <InputGroup onClick={handleCopy}>
        <Input
          isDisabled
          fontWeight="medium"
          _disabled={{
            color: "gray.200",
          }}
          value={roomUrl || ""}
          variant="outline"
        />
        <InputRightElement>
          <IconButton
            aria-label="copy"
            icon={<CopyIcon />}
            disabled={!roomUrl}
            variant="icon-ghost"
            minW="8"
            h="8"
          />
        </InputRightElement>
      </InputGroup>
    </Panel>
  );
};

export default SharePanel;
