import { FC, useEffect } from "react";
import { useBoolean, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { useRoomStore } from "../../stores/roomStore";
import SnackbarAlert from "../common/SnackbarAlert";
import { createPortal } from "react-dom";
import { SNACKBAR_FILE_TRANSFERS_CONTAINER_ID } from "../../constants/dom";

const SnackbarFileTransfers: FC = () => {
  const { t } = useTranslation();
  const fileTransferSessions = useRoomStore(
    (state) => state.fileTransferSessions
  );
  const removeFileTransferSession = useRoomStore(
    (state) => state.removeFileTransferSession
  );
  const members = useRoomStore((state) => state.members);
  const [mounted, setMounted] = useBoolean();

  useEffect(() => {
    setMounted.on();
  }, []);

  return mounted
    ? createPortal(
        <Box>
          {fileTransferSessions.map((sess) => {
            const {
              id,
              role,
              access: { token, payload },
              progressValue,
            } = sess;
            const isReceiver = role === "receiver";
            const peer = members.find(
              (m) =>
                m.id === (isReceiver ? payload.senderId : payload.receiverId)
            );

            // if (sess.role === "sender" && sess.status === "queue") {
            //   return null;
            // }

            return peer ? (
              <SnackbarAlert
                key={token}
                alert={{
                  id: token,
                  type: "progress",
                  content: t(
                    typeof progressValue === "number"
                      ? `alerts.fileTransfer.active.${
                          isReceiver ? "download" : "upload"
                        }`
                      : `alerts.fileTransfer.queue.${
                          isReceiver ? "download" : "upload"
                        }`,
                    { fileId: payload.fileId, nickname: peer.nickname }
                  ),
                  value: progressValue,
                  isClosable: true,
                }}
                onClose={() => {
                  removeFileTransferSession(id);
                }}
              />
            ) : null;
          })}
        </Box>,
        document.getElementById(SNACKBAR_FILE_TRANSFERS_CONTAINER_ID)!
      )
    : null;
};

export default SnackbarFileTransfers;
