import { FC, useContext } from "react";
import {
  Box,
  HStack,
  Icon,
  Menu,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useProfileStore } from "../../stores/profileStore";
import { useRoomStore } from "../../stores/roomStore";
import useMediaQueryDesktop from "../../hooks/useMediaQueryDesktop";
import Frame from "../ui/Frame";
import VCard from "../ui/VCard";
import Avatar from "../ui/Avatar";
import { TrashIcon, LogoIcon } from "../icons";
import MeatballsMenuButton from "../ui/MeatballsMenuButton";
import useRoomOwner from "../../hooks/useRoomOwner";
import RoomSocketConnectionContext from "../../contexts/RoomSocketConnectionContext";
import Logo from "../ui/Logo";
import { roomTransferProtocolIcons } from "../icons/roomTransferProtocolIcons";

const RoomHeader: FC = () => {
  const { t } = useTranslation();
  const profile = useProfileStore((state) => state.profile);
  const room = useRoomStore((state) => state.room);
  const isDesktop = useMediaQueryDesktop();
  const isOwner = useRoomOwner();
  const { remove } = useContext(RoomSocketConnectionContext);

  return (
    <Frame
      as="header"
      position={{ lg: "sticky" }}
      borderWidth={{ base: "0px", lg: "1px" }}
      borderRadius={{ base: "0", lg: "lg" }}
      top={{ lg: "0" }}
      zIndex="1001"
      boxShadow={{ lg: "0 3px 7px 0 rgba(0, 0, 0, 0.32)" }}
      flexShrink="0"
      height={{ base: "64px", lg: "70px" }}
      px={{ base: "3", lg: "1.5rem" }}
      py={{ lg: "2" }}
      display="flex"
      alignItems="center"
      borderTopRadius={{ lg: "0" }}
    >
      <Box flex={isDesktop ? "1" : undefined} flexShrink="0">
        <Link to="/">{isDesktop ? <Logo /> : <LogoIcon />}</Link>
      </Box>

      <Box
        flex="1"
        display="flex"
        justifyContent="center"
        height="full"
        py={!isDesktop ? "1.5" : undefined}
      >
        {room && (
          <HStack
            w="full"
            px="3"
            spacing="2"
            bg="gray.900"
            border="1px"
            borderColor="gray.700"
            borderRadius="md"
            mx={!isDesktop ? "3" : undefined}
          >
            <Icon
              as={roomTransferProtocolIcons[room.transferProtocol]}
              isActive
              w="auto"
              h="auto"
              ml="-1"
            />

            <Box flex="1">
              <Text fontSize="xl" lineHeight="5" fontWeight="500">
                {room.name}
              </Text>
              <Text fontWeight="500" lineHeight="3" mt="1" color="gray">
                #{room.id}
              </Text>
            </Box>

            {isOwner && (
              <Menu placement="bottom-end">
                <MeatballsMenuButton />
                <MenuList>
                  <MenuItem icon={<TrashIcon />} onClick={() => remove()}>
                    {t("room.header.close")}
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </HStack>
        )}
      </Box>

      <Box
        flex={isDesktop ? "1" : undefined}
        flexShrink="0"
        display="flex"
        justifyContent="end"
      >
        {profile &&
          (isDesktop ? (
            <VCard
              uid={profile.uid}
              nickname={profile.nickname}
              align="right"
            />
          ) : (
            <Avatar colorHash={profile.uid} nickname={profile.nickname} />
          ))}
      </Box>
    </Frame>
  );
};

export default RoomHeader;
