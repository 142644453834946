import { FC } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  VStack,
} from "@chakra-ui/react";
import log from "loglevel";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useProfileStore } from "../../stores/profileStore";
import { IProfile } from "../../types/profile";
import { useAddStatusAlert } from "../../stores/snackbarStore";
import { UserSmartIcon } from "../icons";
import useMediaQueryDesktop from "../../hooks/useMediaQueryDesktop";
import Alert from "../ui/Alert";

interface ISetupProfileFormProps {}

interface ISetupProfileFormValues extends Omit<IProfile, "uid"> {}

const SetupProfileForm: FC<ISetupProfileFormProps> = () => {
  const { t } = useTranslation();
  const isDesktop = useMediaQueryDesktop();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<ISetupProfileFormValues>();
  const setNewProfile = useProfileStore((state) => state.setNewProfile);
  const addAlert = useAddStatusAlert();

  const onSubmit = async ({ nickname }: ISetupProfileFormValues) => {
    try {
      await setNewProfile(nickname);
      addAlert({
        status: "success",
        content: t("alerts.success.profileSetup"),
      });
    } catch (err) {
      addAlert({ status: "error", content: t("alerts.error.default") });
      log.error(err);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="4" pb="2" align="stretch">
        <Alert icon={UserSmartIcon}>{t("profile.form.info")}</Alert>

        <FormControl isInvalid={!!errors.nickname}>
          <Input
            placeholder={t("profile.form.nickname.placeholder")}
            {...register("nickname", {
              required: true,
              maxLength: 30,
              pattern: /^[a-z0-9-_]*$/i,
            })}
            autoFocus={isDesktop}
          />
          {errors.nickname && (
            <FormErrorMessage>
              {t(`validation.${errors.nickname.type}`, {
                field: t("common.nickname"),
              })}
            </FormErrorMessage>
          )}
        </FormControl>

        <Button type="submit" isLoading={isSubmitting}>
          {t("common.submit")}
        </Button>
      </VStack>
    </form>
  );
};

export default SetupProfileForm;
