import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const UserIcon: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <circle stroke="currentColor" strokeWidth="2" cx="10" cy="5" r="4" />
      <path
        d="M10 11a9 9 0 0 1 9 9h-2a7 7 0 0 0-14 0H1a9 9 0 0 1 9-9Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </Icon>
);

export default UserIcon;
