import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const DownloadIcon: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="m9.56 15.963-.094-.083-5.173-5.173a1 1 0 0 1 1.32-1.497l.094.083 3.466 3.466V1a1 1 0 0 1 1.993-.117l.007.117v11.759l3.465-3.466a1 1 0 0 1 1.32-.083l.094.083a1 1 0 0 1 .084 1.32l-.084.094-5.172 5.173a1 1 0 0 1-1.32.083Z" />
      <path d="m18 14 .007-.117A1 1 0 0 1 20 14v1a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5v-1l.007-.117A1 1 0 0 1 2 14v1a3 3 0 0 0 2.824 2.995L5 18h10a3 3 0 0 0 2.995-2.824L18 15v-1Z" />
    </g>
  </Icon>
);

export default DownloadIcon;
