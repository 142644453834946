import create from "zustand";
import { devtools } from "zustand/middleware";

type TStatus =
  | "idle"
  | "connecting"
  | "connected"
  | "authenticated"
  | "joined"
  | "disconnected"
  | "error";

interface IChatConnectionState {
  status: TStatus;
  error?: Error;
  setStatus: (status: TStatus, error?: Error) => void;
}

export const useChatConnectionStore = create<IChatConnectionState>()(
  devtools(
    (set) => ({
      status: "idle",
      setStatus: (status, error) => set({ status, error }),
    }),
    { enabled: process.env.NODE_ENV === "development" }
  )
);
