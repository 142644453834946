import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const ChatIcon: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M15 2c1.105 0 2.105.448 2.828 1.172A3.987 3.987 0 0 1 19 6v4a3.987 3.987 0 0 1-1.172 2.828A3.987 3.987 0 0 1 15 14h-3.87l-6.383 4.358 1.318-4.496c-1.562.404-3.03-.113-3.98-1.123A3.986 3.986 0 0 1 1 10V6c0-1.105.448-2.105 1.172-2.828A3.987 3.987 0 0 1 5 2h10Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M10.5 6.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM5.5 6.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM15.5 6.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
        fill="currentColor"
      />
    </g>
  </Icon>
);

export default ChatIcon;
