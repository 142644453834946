import { FC, ReactNode } from "react";
import { Box, BoxProps } from "@chakra-ui/react";

import Frame from "./Frame";

export interface IPanelProps extends Omit<BoxProps, "title"> {
  title?: ReactNode;
  actions?: ReactNode;
  noPadding?: boolean;
}

const Panel: FC<IPanelProps> = ({
  title,
  actions,
  noPadding,
  children,
  ...rest
}) => {
  // const [bgColor] = useToken("colors", ["gray.800"]);

  return (
    <Frame
      overflow="hidden"
      boxShadow="0 2px 8px 0 rgba(0, 0, 0, 0.2)"
      display="flex"
      flexDirection="column"
      {...rest}
    >
      {title && (
        <>
          <Box
            paddingX="3"
            paddingY="2"
            color="gray.100"
            // borderBottom="1px"
            // borderBottomColor="gray.600"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexShrink="0"
            // position="sticky"
            // top="0"
            // bg={`${bgColor}${(0.2 * 255).toString(16)}`}
          >
            {title}
            {actions && <Box>{actions}</Box>}
          </Box>
          <Box h="1px" w="full" px="3" mt="-1px" flexShrink="0">
            <Box h="full" w="full" bg="gray.600" borderRadius="full" />
          </Box>
        </>
      )}
      <Box
        {...(noPadding
          ? { height: "100%", overflow: "hidden", flex: "1" }
          : {
              paddingX: "3",
              paddingY: "3",
              overflowY: "auto",
              maxHeight: "100%",
            })}
      >
        {children}
      </Box>
    </Frame>
  );
};

export default Panel;
