import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const IpfsIcon: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M1.5,5.90442174 L6.75227148,8.77191105 C6.73806833,8.89353891 6.73076923,9.01723147 6.73076923,9.1426003 C6.73076923,10.5333945 7.62905829,11.7178935 8.88627834,12.1657662 L8.88715385,18 L1.5,13.9988587 L1.5,5.90442174 Z M18.4986923,5.90570755 L18.5,13.9509174 L11.1115385,17.9897136 L11.1137217,12.1657662 C12.3709417,11.7178935 13.2692308,10.5333945 13.2692308,9.1426003 C13.2692308,9.01723147 13.2619317,8.89353891 13.2477285,8.77191105 L18.4986923,5.90570755 Z M10.0050694,0 L17.3897692,4.07600543 L12.2992873,6.85745919 C11.7086794,6.282882 10.8964786,5.92808497 10,5.92808497 C9.104184,5.92808497 8.29252127,6.28235773 7.70202259,6.85618554 L2.54746154,4.04257447 L10.0050694,0 Z"
        fill="currentColor"
      ></path>
    </g>
  </Icon>
);

export default IpfsIcon;
