import { FC, useEffect, lazy, Suspense } from "react";
import { useBoolean } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";

const LegalModal = lazy(() => import("../components/base/LegalModal"));

const LegalModalProvider: FC = () => {
  const navigate = useNavigate();
  const { pathname, hash } = useLocation();
  const isOpen = "#legal" === hash;
  const [isInitialized, setInitialized] = useBoolean();

  useEffect(() => {
    if (isOpen && !isInitialized) {
      setInitialized.on();
    }
  }, [isOpen]);

  return isInitialized ? (
    <Suspense fallback={<></>}>
      <LegalModal isOpen={isOpen} onClose={() => navigate(pathname)} />
    </Suspense>
  ) : null;
};

export default LegalModalProvider;
