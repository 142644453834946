import { FC } from "react";
import { VStack } from "@chakra-ui/react";

import { useSnackbarStore } from "../../stores/snackbarStore";
import SnackbarAlert from "../common/SnackbarAlert";
import {
  SNACKBAR_FILE_REQUESTS_CONTAINER_ID,
  SNACKBAR_FILE_TRANSFERS_CONTAINER_ID,
} from "../../constants/dom";

const Snackbar: FC = () => {
  const { alerts, removeAlert } = useSnackbarStore();

  return (
    <VStack
      position="fixed"
      zIndex="var(--chakra-zIndices-toast)"
      bottom="4"
      left="50%"
      transform="translateX(-50%)"
      maxW="384px"
      w="100%"
      spacing="0"
      align="stretch"
    >
      {alerts.map((alert) => (
        <SnackbarAlert key={alert.id} alert={alert} onClose={removeAlert} />
      ))}

      <div id={SNACKBAR_FILE_REQUESTS_CONTAINER_ID} />
      <div id={SNACKBAR_FILE_TRANSFERS_CONTAINER_ID} />
    </VStack>
  );
};

export default Snackbar;
