import { FC, useState } from "react";
import {
  VStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  HStack,
  Box,
  Text,
  Button,
  Checkbox,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import FileIcon from "../common/FileIcon";
const prettysize = require("prettysize");

type IUploadFilesModalProps = Omit<ModalProps, "children"> & {
  files: File[];
  onSubmit: (isProtected: boolean) => void;
};

const UploadFilesModal: FC<IUploadFilesModalProps> = ({
  files,
  onSubmit,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation();
  const [isProtected, setProtected] = useState(false);

  return (
    <Modal scrollBehavior="inside" onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("room.upload.modal.title")}</ModalHeader>
        <ModalCloseButton />

        <ModalBody overflow="hidden" display="flex" flexDirection="column">
          <VStack overflow="hidden" spacing="4" align="stretch" mb="2">
            <VStack flex="1" overflow="auto" spacing="3" align="stretch">
              {files.map((file, i) => (
                <HStack
                  key={i}
                  spacing="3"
                  p="3"
                  bg="gray.900"
                  borderRadius="md"
                >
                  <FileIcon name={file.name} />

                  <Box overflow="hidden">
                    <Text fontWeight="medium" noOfLines={1}>
                      {file.name}
                    </Text>
                    <Text fontSize="sm" color="gray.300">
                      {prettysize(file.size, { places: 2 })}
                    </Text>
                  </Box>
                </HStack>
              ))}
            </VStack>

            <Checkbox
              flexShrink="0"
              checked={isProtected}
              onChange={(e) => setProtected(e.target.checked)}
            >
              {t("room.upload.modal.protectLabel")}
            </Checkbox>

            <Button flexShrink="0" onClick={() => onSubmit(isProtected)}>
              {t("room.upload.modal.submit")}
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UploadFilesModal;
