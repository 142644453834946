import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Icon, Text } from "@chakra-ui/react";

import { UploadIcon } from "../icons";
import UploadInputProvider from "./UploadInputProvider";

export interface IUploadAreaProps {
  inline?: boolean;
}

const UploadArea: FC<IUploadAreaProps> = ({ inline }) => {
  const { t } = useTranslation();

  return (
    <UploadInputProvider>
      {({ onTrigger, onFiles, isLoading, isDisabled }) => (
        <Box
          onClick={() => !isLoading && onTrigger()}
          role="button"
          p="3"
          borderWidth="1px"
          borderStyle="dashed"
          borderColor="gray.500"
          transition="border-color 150ms ease-out"
          _hover={
            !isLoading && !isDisabled
              ? {
                  borderColor: "brand.600",
                }
              : {}
          }
          borderRadius="md"
          bg="gray.900"
          textAlign="center"
          {...(isDisabled
            ? { opacity: "0.4", cursor: "not-allowed" }
            : {
                opacity: isLoading ? "0.7" : "1",
                cursor: isLoading ? "wait" : undefined,
                onDrop: (e) => {
                  e.preventDefault();

                  const files = Array.from(e.dataTransfer.files);
                  if (files.length > 0) {
                    onFiles(files);
                  }
                },
                onDragOver: (e) => e.preventDefault(),
              })}
        >
          {!inline && <Icon as={UploadIcon} w="6" h="auto" color="gray.600" />}

          <Text
            color="gray.500"
            lineHeight="5"
            whiteSpace={inline ? undefined : "pre-wrap"}
          >
            {t("room.upload.d&d")}
            <Text as="span" fontWeight="medium" color="brand.500">
              {t("room.upload.action")}
            </Text>
          </Text>
        </Box>
      )}
    </UploadInputProvider>
  );
};

export default UploadArea;
