import { FC } from "react";
import { Heading, HeadingProps } from "@chakra-ui/react";

const HomeSectionHeading: FC<HeadingProps> = (props) => (
  <Heading
    size="xl"
    fontWeight="medium"
    whiteSpace="pre-wrap"
    lineHeight="1"
    color="brand.500"
    css={`
      span {
        color: white;
        font-size: var(--chakra-fontSizes-3xl);
      }
    `}
    {...props}
  />
);

export default HomeSectionHeading;
