import { FC } from "react";
import { HStack, StackProps, Icon, Text } from "@chakra-ui/react";

export interface IAlertProps extends Omit<StackProps, "title"> {
  icon?: FC;
}

const Alert: FC<IAlertProps> = ({ icon, children, ...rest }) => (
  <HStack
    spacing="3"
    p="3"
    borderRadius="md"
    border="1px"
    borderColor="gray.600"
    bg="gray.800"
    {...rest}
  >
    <Icon as={icon} w="6" h="6" flexShrink="0" />
    <Text fontWeight="medium">{children}</Text>
  </HStack>
);

export default Alert;
