import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useMediaQueryDesktop from "./useMediaQueryDesktop";

const useScrollIntoHashEl = () => {
  const { hash } = useLocation();
  const isDesktop = useMediaQueryDesktop();

  useEffect(() => {
    if (isDesktop) {
      return;
    }

    const el = document.getElementById(hash.slice(1));
    if (el) {
      setTimeout(() => {
        el.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      });
    }
  }, [hash]);
};

export default useScrollIntoHashEl;
