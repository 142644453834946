import { FC } from "react";
import { Box, Button, Flex, HStack, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import useMediaQueryDesktop from "../../hooks/useMediaQueryDesktop";
import { ChevronRightIcon, LogoIcon } from "../icons";
import HomeNavLinks from "./HomeNavLinks";
import Logo from "../ui/Logo";

interface IHomeHeaderProps {
  onCreateRoomClick: () => void;
}

const HomeHeader: FC<IHomeHeaderProps> = ({ onCreateRoomClick }) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQueryDesktop();

  return (
    <HStack
      spacing="4"
      as="header"
      px={{ base: "0", lg: "10" }}
      py={{ base: "4", lg: "8" }}
    >
      <Box flex="1">
        {isDesktop ? (
          <Logo
            style={{
              filter: "brightness(99)",
              height: "30px",
              width: "auto",
            }}
          />
        ) : (
          <LogoIcon filter="brightness(99)" />
        )}
      </Box>

      {isDesktop && <HomeNavLinks flex="1" justify="center" spacing="8" />}

      <Flex flex="1" justify="flex-end">
        <Button
          onClick={onCreateRoomClick}
          variant="ghost"
          rightIcon={<Icon as={ChevronRightIcon} />}
        >
          {t("home.header.createRoom")}
        </Button>
      </Flex>
    </HStack>
  );
};

export default HomeHeader;
