import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const EncryptionSmartIcon: FC<IconProps> = (props) => (
  <Icon width="82px" height="82px" viewBox="0 0 82 82" {...props}>
    <defs>
      <filter
        id="encryption-smart-icon-e"
        width="233.3%"
        height="336.5%"
        x="-66.7%"
        y="-118.3%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="4.392157" />
      </filter>
      <filter
        id="encryption-smart-icon-g"
        width="140.6%"
        height="303.6%"
        x="-20.3%"
        y="-86.1%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="4"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.148898333   0 0 0 0 0.883361518   0 0 0 0 0.641910613  0 0 0 0.307719624 0"
        />
      </filter>
      <radialGradient
        id="encryption-smart-icon-c"
        cx="70.848397%"
        cy="-12.001495%"
        r="108.37252%"
        fx="70.848397%"
        fy="-12.001495%"
        gradientTransform="scale(-1 -.908722) rotate(-78.908455 .153151 .866888)"
      >
        <stop offset="0%" stopColor="#474747" />
        <stop offset="100%" stopColor="#242424" />
      </radialGradient>
      <radialGradient
        id="encryption-smart-icon-d"
        cx="53.319914%"
        cy="-3.538949%"
        r="143.734846%"
        fx="53.319914%"
        fy="-3.538949%"
        gradientTransform="matrix(-.0808202 .9057493 -.9967287 -.07344309 .541019 -.520933)"
      >
        <stop offset="0%" stopColor="#645D5D" />
        <stop offset="100%" stopColor="#272727" />
      </radialGradient>
      <path
        id="encryption-smart-icon-b"
        d="M25.325954 61.681655C16.16785 58.708149 9.298748 52.807894 4.718653 43.98089c-4.749729-9.15393-5.9137-19.289239-3.491915-30.405924 4.90711-.544408 8.890353-1.399539 11.949733-2.565391 2.103323-.801524 4.836592-2.156373 8.199806-4.064548l1.896955-1.095698 2.021902-1.205448L26.352941 4l2.084377 1.260324 1.959428 1.150573c3.79391 2.191396 6.838132 3.724288 9.132666 4.598678 3.059379 1.165852 7.042623 2.020983 11.949733 2.565391C53.90093 24.691651 52.736958 34.82696 47.98723 43.98089 43.2375 53.13482 36.02607 59.14119 26.35294 62l-1.026987-.318345Z"
      />
      <path
        id="encryption-smart-icon-h"
        d="M18.666667 25.646586v2l-5.295074 2.447732 3.107478 3.944638v2l-2.909988 2.380399-4.236902-5.438223-4.168087 5.438223-2.943902-2.380399v-2l2.933122-3.857387L0 28.118555v-2l18.666667-.47197Zm22.666666 0v2l-5.295074 2.447732 3.10748 3.944638v2l-2.90999 2.380399-4.236901-5.438223-4.168087 5.438223-2.943903-2.380399v-2l2.933123-3.857387-5.153314-2.063014v-2l18.666666-.47197Zm22.666667 0v2l-5.295074 2.447732 3.107479 3.944638v2l-2.90999 2.380399-4.2369-5.438223-4.168087 5.438223-2.943903-2.380399v-2l2.933123-3.857387-5.153315-2.063014v-2L64 25.646585Z"
      />
      <linearGradient
        id="encryption-smart-icon-f"
        x1="37.189693%"
        x2="81.660773%"
        y1="53.416385%"
        y2="32.424099%"
      >
        <stop offset="0%" stopColor="#FFF" stopOpacity=".04" />
        <stop offset="100%" stopColor="#FFF" stopOpacity=".068018" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="url(#encryption-smart-icon-c)"
        d="M25.325954 57.681655C16.16785 54.708149 9.298748 48.807894 4.718653 39.98089c-4.749729-9.15393-5.9137-19.289239-3.491915-30.405924 4.90711-.544408 8.890353-1.399539 11.949733-2.565391 2.103323-.801524 4.836592-2.156373 8.199806-4.064548l1.896955-1.095698L25.295134.643881 26.352941 0l2.084377 1.260324 1.959428 1.150573c3.79391 2.191396 6.838132 3.724288 9.132666 4.598678 3.059379 1.165852 7.042623 2.020983 11.949733 2.565391C53.90093 20.691651 52.736958 30.82696 47.98723 39.98089 43.2375 49.13482 36.02607 55.14119 26.35294 58l-1.026987-.318345Z"
        transform="translate(14.647059 10)"
      />
      <path
        fill="url(#encryption-smart-icon-d)"
        fillRule="nonzero"
        d="m26.352941 0 2.084377 1.260324 1.959428 1.150573.97271.55673.938697.526856.904685.49698.870672.467105.83666.437229c.273218.140764.540767.276548.802648.407354l.768636.377478c1.127444.543812 2.140097.986793 3.037958 1.328946 3.059379 1.165852 7.042623 2.020983 11.949733 2.565391C53.90093 20.691651 52.736958 30.82696 47.98723 39.98089 43.2375 49.13482 36.02607 55.14119 26.35294 58l-1.026987-.318345C16.16785 54.708149 9.298748 48.807894 4.718653 39.98089c-4.749729-9.15393-5.9137-19.289239-3.491915-30.405924 4.90711-.544408 8.890353-1.399539 11.949733-2.565391 1.051661-.400762 2.26081-.939855 3.627444-1.61728l.838879-.423054a78.4062 78.4062 0 0 0 .433613-.223978l.895574-.472853.93337-.506054.971169-.539253a127.472003 127.472003 0 0 0 .499757-.282076l1.896955-1.095698L25.295134.643881 26.352941 0ZM26.35 4.397l-1.193783.712295-1.922159 1.110126-.52259.294944-1.017256.56467-.980229.531082c-.8015.428588-1.564693.82224-2.290236 1.18108l-.85262.413908c-1.112842.52962-2.129968.970249-3.054062 1.322398-2.65525 1.011849-5.85558 1.794948-9.613867 2.364113L4.408 12.964l-.075703.465246c-1.39014 8.86451-.226519 16.934453 3.471913 24.31556l.256093.50219c4.044347 7.794481 9.97175 12.974034 17.883256 15.673277L26.352 54.056l.414279-.138163c7.773477-2.66365 13.60311-7.685765 17.629968-15.198077l.249332-.472763c3.900314-7.516893 5.14974-15.751774 3.728006-24.817751L48.297 12.964l-.494315-.072384c-3.549495-.537544-6.601304-1.265913-9.166277-2.197587l-.44759-.166526c-.874524-.33326-1.833003-.746045-2.87854-1.23936l-.800525-.385102a67.492238 67.492238 0 0 1-.412596-.203905l-.850042-.430576-.883416-.46101-.917081-.491536-.95104-.52216a123.720193 123.720193 0 0 1-.488343-.272591l-1.00252-.568268-.514249-.2957-2.001083-1.175394L26.35 4.397Z"
        transform="translate(14.647059 10)"
      />
      <path
        fill="#FFF"
        d="m22.27451 13.517245 19.764706 6.295903-3.249167 4.846392-5.535147-.840681z"
        filter="url(#encryption-smart-icon-e)"
        opacity=".720401"
        transform="translate(14.647059 10)"
      />
      <path
        fill="#2B2B2B"
        d="M40.51165 18.184685c-4.522534 2.985238-8.05402 4.988243-10.594458 6.009014-2.631167 1.057228-6.422003 2.05662-11.372508 2.998174l.484835-4.223992c4.918124-.796299 8.812333-1.819337 11.682627-3.069115C33.582441 18.648988 37.011726 16.825384 41 14.427952c3.988275 2.397432 7.41756 4.221036 10.287854 5.470814 2.870294 1.249778 6.764503 2.272816 11.682628 3.069116l.484835 4.223991c-4.950505-.941555-8.741341-1.940946-11.372509-2.998174C49.451641 23.136472 45.757372 21.0256 41 17.86108Z"
      />
      <path
        fill="url(#encryption-smart-icon-f)"
        d="M48.808258 17.191873c-.089277-.892784-.161517-1.579647-.21672-2.060588-.055203-.480941-.144574-1.202076-.268115-2.163403l3.155722-3.392916c-5.111336-.622234-9.09458-1.477364-11.949733-2.565391C36.674259 5.921548 32.282102 3.585023 26.352942 0v7.86108c4.584645 3.026375 8.161788 5.089633 10.73143 6.189774 2.569643 1.100141 6.477604 2.147148 11.723886 3.141019Z"
        transform="translate(14.647059 10)"
      />
      <g transform="translate(9 10)">
        <use
          xlinkHref="#encryption-smart-icon-h"
          fill="#000"
          filter="url(#encryption-smart-icon-g)"
        />
        <use xlinkHref="#encryption-smart-icon-h" fill="#365347" />
      </g>
      <path
        fill="#26E1A4"
        d="M38.791497 27.709677h4.291888v6.786798l5.633617-2.291462 1.616331 3.441572-6.44952 2.981394 4.261925 5.410977-2.909989 2.380399-4.236901-5.438223-4.168087 5.438223-2.943903-2.380399 4.048237-5.410977-6.268428-2.509424 1.52348-3.913542 5.60135 2.291462v-6.786798Zm22.666667 0h4.291887v6.786798l5.633618-2.291462L73 35.646585l-6.44952 2.981394 4.261925 5.410977-2.90999 2.380399-4.2369-5.438223-4.168087 5.438223-2.943903-2.380399 4.048237-5.410977-6.268429-2.509424 1.52348-3.913542 5.60135 2.291462v-6.786798Zm-45.333334 0h4.291888v6.786798l5.633617-2.291462 1.616332 3.441572-6.44952 2.981394 4.261924 5.410977-2.909988 2.380399-4.236902-5.438223-4.168087 5.438223-2.943902-2.380399 4.048236-5.410977L9 36.118555l1.52348-3.913542 5.60135 2.291462v-6.786798Z"
      />
    </g>
  </Icon>
);

export default EncryptionSmartIcon;
