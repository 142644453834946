import { FC } from "react";
import { Icon, Box, Text } from "@chakra-ui/react";

interface ICardProps {
  title: string;
  text: string;
  icon: FC;
  variant?: "simple" | "horizontal";
}

const Card: FC<ICardProps> = ({ title, text, icon, variant = "simple" }) => (
  <Box
    p={variant === "simple" ? { base: "6", lg: "8" } : "4"}
    bg="gray.900"
    border="1px"
    borderColor="gray.800"
    borderRadius="xl"
    {...(variant === "horizontal"
      ? {
          display: "flex",
          alignItems: "flex-start",
        }
      : {})}
  >
    {icon && (
      <Icon
        as={icon}
        {...(variant === "horizontal"
          ? {
              ml: "-12",
              mr: "3",
              w: "auto",
              h: "auto",
            }
          : {
              w: "auto",
              h: "20",
              mb: "1",
            })}
      />
    )}

    <Box>
      <Text
        fontSize={variant === "horizontal" ? "lg" : "xl"}
        fontWeight="medium"
      >
        {title}
      </Text>
      <Text
        lineHeight={variant === "horizontal" ? "5" : undefined}
        color={variant === "horizontal" ? "gray.200" : undefined}
        mt={variant === "simple" ? "2" : undefined}
        whiteSpace="pre-wrap"
      >
        {text}
      </Text>
    </Box>
  </Box>
);

export default Card;
