import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const UploadIcon: FC<IconProps> = (props) => (
  <Icon width="28" height="30" viewBox="0 0 28 30" {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <path
        d="m14.786 14.21.094.083 5.172 5.173a1 1 0 0 1-1.32 1.497l-.094-.083-3.466-3.466v11.759a1 1 0 0 1-1.993.116l-.006-.116-.001-11.759-3.465 3.466a1 1 0 0 1-1.32.083l-.094-.083a1 1 0 0 1-.083-1.32l.083-.094 5.173-5.173a1 1 0 0 1 1.32-.083Z"
        fillRule="nonzero"
      />
      <path d="M15 0h2.5L25 7.683V10h-7.4A2.6 2.6 0 0 1 15 7.4V0Z" />
      <path
        d="M11 26H7a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h8v2H7a2 2 0 0 0-1.995 1.85L5 4v18a2 2 0 0 0 1.85 1.995L7 24h4v2Zm10-2a2 2 0 0 0 1.995-1.85L23 22V10h2v12a4 4 0 0 1-4 4h-4v-2h4Z"
        fillRule="nonzero"
      />
    </g>
  </Icon>
);

export default UploadIcon;
