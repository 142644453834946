import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "@chakra-ui/react";

import { UploadIcon } from "../icons";
import UploadInputProvider from "./UploadInputProvider";

export interface IUploadButtonProps {
  inline?: boolean;
}

const UploadButton: FC<IUploadButtonProps> = ({ inline }) => {
  const { t } = useTranslation();

  return (
    <UploadInputProvider>
      {({ onTrigger, isLoading, isDisabled }) => (
        <Button
          onClick={() => onTrigger()}
          isDisabled={isLoading || isDisabled}
          variant="solid"
          leftIcon={<Icon as={UploadIcon} />}
          boxShadow="0 3px 7px 0 rgba(0, 0, 0, 0.32)"
          size="lg"
          fontWeight="normal"
        >
          {t("room.upload.action")}
        </Button>
      )}
    </UploadInputProvider>
  );
};

export default UploadButton;
