import { FC, ReactNode } from "react";
import { HStack, Icon, Text } from "@chakra-ui/react";

import type { SmartIconProps } from "../icons/Icon";

export interface ISegmentedControlProps<T extends string | number | symbol> {
  options: {
    icon: FC<SmartIconProps>;
    label: ReactNode;
    value: T;
  }[];
  value: T;
  onChange: (value: T) => void;
}

const SegmentedControl = <T extends string | number>({
  options,
  value: activeValue,
  onChange,
}: ISegmentedControlProps<T>) => (
  <HStack spacing="3" alignItems="stretch">
    {options.map(({ icon, label, value }) => {
      const isActive = activeValue === value;

      return (
        <HStack
          key={value}
          role="button"
          onClick={() => onChange(value)}
          spacing="2"
          p="2"
          borderRadius="md"
          bg={isActive ? "brand.900" : "gray.900"}
          boxShadow={
            isActive ? "0 0 0 1px var(--chakra-colors-brand-500)" : undefined
          }
          flexGrow="1"
        >
          <Icon as={icon} isActive={isActive} w="1.25rem" h="1.25rem" />
          <Text lineHeight="4">{label}</Text>
        </HStack>
      );
    })}
  </HStack>
);

export default SegmentedControl;
