import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const DocIcon: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M6 2a2 2 0 0 0-1.995 1.85L4 4v12a2 2 0 0 0 1.85 1.995L6 18h8a2 2 0 0 0 1.995-1.85L16 16V8h2v8a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h4v2H6Z"
        fill="currentColor"
        fillRule="nonzero"
      />
      <path d="M10 0h2l6 6.147V8h-6a2 2 0 0 1-2-2V0Z" fill="currentColor" />
    </g>
  </Icon>
);

export default DocIcon;
