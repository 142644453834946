export const RANDOM_FILE_EXTENSIONS = [
  "svg",
  "png",
  "jpg",
  "gif",
  "pdf",
  "mp4",
  "m4a",
  "wav",
  "txt",
  "exe",
  "iso",
];
