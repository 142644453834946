import { useRef, useEffect } from 'react';

const useRotate = (
  slidesCount: number,
  slideTimeout: number,
  activeSlideIndex: number,
  setActiveSlideIndex: (index: number) => void,
  isPanning?: boolean,
) => {
  const directionRef = useRef<'right' | 'left'>('right');

  useEffect(() => {
    let timeout: number;

    if (!isPanning && slidesCount > 1) {
      timeout = window.setTimeout(() => {
        if (directionRef.current === 'right') {
          if (activeSlideIndex + 1 >= slidesCount) {
            directionRef.current = 'left';
            setActiveSlideIndex(activeSlideIndex - 1);
          } else {
            setActiveSlideIndex(activeSlideIndex + 1);
          }
        } else {
          if (activeSlideIndex <= 0) {
            directionRef.current = 'right';
            setActiveSlideIndex(activeSlideIndex + 1);
          } else {
            setActiveSlideIndex(activeSlideIndex - 1);
          }
        }
      }, slideTimeout);
    }

    return () => {
      window.clearTimeout(timeout);
    };
  }, [activeSlideIndex, slidesCount, slideTimeout, isPanning]);
};

export default useRotate;
