import { FC, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  useBoolean,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { useProfileStore } from "../../stores/profileStore";
import { stepsIcons } from "../icons/stepsIcons";
import JoinRoomForm, { IJoinRoomFormProps } from "../base/JoinRoomForm";
import Frame from "../ui/Frame";
import CreateRoomModal from "../base/CreateRoomModal";
import SetupProfileModal from "../base/SetupProfileModal";
import HomeHeader from "../base/HomeHeader";
import Card from "../ui/Card";
import CreateRoomForm from "../base/CreateRoomForm";
import HomeNavLinks from "../base/HomeNavLinks";
import EncryptionSmartIcon from "../icons/EncryptionSmartIcon";
import SwapSmartIcon from "../icons/SwapSmartIcon";
import UsersSmartIcon from "../icons/UsersSmartIcon";
import HomeSectionHeading from "../home/HomeSectionHeading";
import Logo from "../ui/Logo";
import GlobeRenderer from "../base/GlobeRenderer";
import useMediaQueryDesktop from "../../hooks/useMediaQueryDesktop";
import useScrollIntoHashEl from "../../hooks/useScrollIntoHashEl";
import {
  CloudIcon,
  InfoIcon,
  IpfsIcon,
  PeerIcon,
  PointIcon,
  SecurityKeyIcon,
  SecurityLockIcon,
} from "../icons";
import LegalModalProvider from "../../providers/LegalModalProvider";
import PublicRoomsSection from "../home/PublicRoomsSection";

const featuresIcons = [EncryptionSmartIcon, UsersSmartIcon, SwapSmartIcon];

const Home: FC = () => {
  const navigate = useNavigate();
  const { profile } = useProfileStore();
  const { t } = useTranslation();
  const isDesktop = useMediaQueryDesktop();
  const [isCreateRoomModalOpen, setCreateRoomModalOpen] = useBoolean();
  const [isSetupProfileModalOpen, setSetupProfileModalOpen] = useBoolean();
  const profileSetupCbRef = useRef<() => void>();
  const stepsContent = t("home.steps", { returnObjects: true }) as {
    title: string;
    text: string;
  }[];
  const steps = stepsContent.map((steps, i) => ({
    ...steps,
    icon: stepsIcons[i],
  }));
  const featuresContent = t("home.features.items", { returnObjects: true }) as {
    title: string;
    text: string;
  }[];
  const features = featuresContent.map((section, i) => ({
    ...section,
    icon: featuresIcons[i],
  }));

  const handleCreateRoomClick = profile
    ? setCreateRoomModalOpen.on
    : () => {
        profileSetupCbRef.current = setCreateRoomModalOpen.on;
        setSetupProfileModalOpen.on();
      };

  const handleOnJoinRoom: IJoinRoomFormProps["onJoin"] = (room) => {
    const navigateToRoom = () => {
      navigate(`/${room.name}`, { state: { room } });
    };

    if (profile) {
      navigateToRoom();
    } else {
      profileSetupCbRef.current = navigateToRoom;
      setSetupProfileModalOpen.on();
    }
  };

  useScrollIntoHashEl();

  useEffect(() => {
    if (profile && isSetupProfileModalOpen) {
      profileSetupCbRef.current?.();
      setSetupProfileModalOpen.off();
    }
  }, [!profile]);

  return (
    <>
      <Helmet>
        <title>{t("home.title")}</title>
      </Helmet>

      <Box w="100vw" h="100vh" position="absolute" zIndex="-1">
        <Box
          w="full"
          h="full"
          bgImage={`url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1440' height='420'%3E%3Cdefs%3E%3ClinearGradient id='bg-a' x1='50%25' x2='50%25' y1='100%25' y2='-12.35%25'%3E%3Cstop offset='0%25' stop-color='%2326E1A4'/%3E%3Cstop offset='100%25' stop-color='%2326E1A4' stop-opacity='0'/%3E%3C/linearGradient%3E%3Cfilter id='bg-b' width='100%25' height='100%25' x='0%25' y='0%25' filterUnits='objectBoundingBox'%3E%3CfeGaussianBlur in='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cpath fill='url(%23bg-a)' fill-rule='evenodd' d='M276 109c139.96 160 379.96 240 720 240s580.04-80 720-240v420H276V109Z' filter='url(%23bg-b)' opacity='.828' transform='translate(-276 -109)'/%3E%3C/svg%3E")`}
          bgSize={{ base: "300%", md: "120%" }}
          bgPosition="0 100%"
          bgRepeat="no-repeat"
          filter="blur(21px)"
        />
      </Box>

      <Box
        bgGradient="linear(to-b, rgba(33, 33, 33, 0.7), rgba(19, 19, 19, 0.7))"
        overflowX="hidden"
      >
        <Box
          w="full"
          h="full"
          maxW={{ base: "100vw", lg: "1266px" }}
          mx="auto"
          px="4"
        >
          <HomeHeader onCreateRoomClick={handleCreateRoomClick} />

          <Flex
            justifyContent={{ base: "center", lg: "space-between" }}
            alignItems="center"
            w="full"
            minH="min(calc(100vh - 240px), 928px)"
            position="relative"
            pb="2"
          >
            <Flex
              direction="column"
              zIndex="2"
              align={{ base: "center", lg: "start" }}
            >
              <HStack
                spacing="1.5"
                pl="1.5"
                pr="2"
                py="1"
                borderRadius="md"
                color="#F2B324"
                bgColor="#423822"
                border="1px"
                borderColor="#61470D"
                mb="4"
              >
                <Icon as={InfoIcon} w="auto" h="auto" />
                <Text fontWeight="medium">{t("home.beta")}</Text>
              </HStack>

              <Logo style={{ width: "256px", height: "auto" }} />

              <Heading
                size="2xl"
                mt="6"
                textAlign={{ base: "center", lg: "left" }}
              >
                {t("home.intro.title")}
              </Heading>

              <Heading
                size="md"
                mt="4"
                mb="6"
                textAlign={{ base: "center", lg: "left" }}
              >
                {t("home.intro.subtitle")}
              </Heading>

              <VStack spacing="2" align="center" w="full" maxW="320px">
                <Frame
                  w="full"
                  p="3"
                  boxShadow="0 2px 8px 0 rgba(0, 0, 0, 0.2)"
                >
                  <JoinRoomForm onJoin={handleOnJoinRoom} />
                </Frame>

                <Text
                  color="gray.300"
                  textAlign="center"
                  fontWeight="medium"
                  textTransform="uppercase"
                >
                  {t("common.or")}
                </Text>

                <Button
                  onClick={handleCreateRoomClick}
                  variant="outline"
                  w="full"
                >
                  {t("home.create.title")}
                </Button>

                <Text
                  maxW="266px"
                  textAlign="center"
                  mx="auto"
                  color="gray.300"
                >
                  {t("home.intro.tip")}
                </Text>
              </VStack>
            </Flex>

            {isDesktop && (
              <GlobeRenderer
                position="absolute"
                zIndex="0"
                right="0"
                bottom="0"
                size="1040px"
                overflow="hidden"
                transform="translate(35%, 37.5%)"
              />
            )}
          </Flex>

          <VStack spacing={{ base: "12", lg: "24" }} align="stretch">
            <SimpleGrid
              columns={{ base: 1, lg: 3 }}
              spacing={{ base: "4", lg: "10" }}
              pt="6"
              pl={{ base: "6", lg: "none" }}
              maxW={{ base: "568px", lg: "1000px" }}
              mx="auto"
              zIndex="2"
              position="relative"
            >
              {steps.map((step, i) => (
                <Card key={i} {...step} variant="horizontal" />
              ))}
            </SimpleGrid>

            <Box id="whats-fileswap" as="section">
              <VStack spacing="4" maxW="600px" mx="auto" textAlign="center">
                <HomeSectionHeading
                  mb="4"
                  dangerouslySetInnerHTML={{
                    __html: "<span>What's</span>\nFileSwap",
                  }}
                />

                <Text color="gray.300">
                  FileSwap is a sharing web app that allows users to{" "}
                  <b>directly (P2P)</b> transfer files between multiple devices,
                  friends or family members.
                </Text>

                <Text color="gray.300">
                  What does “directly” mean? FileSwap uses peer-to-peer
                  technology which means there is direct transfer between the
                  sender and receiver without any intermediate server.
                </Text>
              </VStack>

              <Text
                mt="8"
                mb="3"
                textTransform="uppercase"
                fontWeight="medium"
                color="brand.500"
              >
                Example usage:
              </Text>

              <Wrap spacing="3" justify="center">
                {[
                  "You can safely transfer your iPhone files to your PC.",
                  "You can add music to your Android device.",
                  "You can share files with your friends or family members.",
                  "You can add files to the IPFS network.",
                  "You can share files to the Webtorrent network.",
                ].map((example, i) => (
                  <WrapItem key={i} w={{ base: "full", md: "auto" }}>
                    <HStack
                      w="full"
                      spacing="2"
                      p="3"
                      bg="gray.900"
                      border="1px"
                      borderColor="gray.800"
                      borderRadius="xl"
                    >
                      <Icon as={PointIcon} w="auto" h="auto" />
                      <Text color="gray.50">{example}</Text>
                    </HStack>
                  </WrapItem>
                ))}
              </Wrap>
            </Box>

            <Box id="features" as="section">
              <HomeSectionHeading
                dangerouslySetInnerHTML={{
                  __html: t("home.features.title"),
                }}
              />

              <SimpleGrid
                columns={{ base: 1, lg: 3 }}
                spacing={{ base: "5", lg: "10" }}
                mt="6"
                maxW={{ base: "568px", lg: "100%" }}
                mx="auto"
              >
                {features.map((feature, i) => (
                  <Card key={i} {...feature} />
                ))}
              </SimpleGrid>
            </Box>

            <Box id="security" as="section">
              <Stack
                direction={{ base: "column", lg: "row" }}
                spacing={{ base: "8", lg: "12" }}
                align="center"
              >
                <Box flex="4">
                  <HomeSectionHeading
                    mb="4"
                    dangerouslySetInnerHTML={{
                      __html: "<span>About</span>\nSecurity",
                    }}
                  />

                  <Text color="gray.300">
                    Security is not just a feature. It's our mission. Every
                    design decision in FileSwap begins with the safety and
                    privacy of your data in mind.
                    <br />
                    <br />
                    We can't read your files, and no one else can either.
                    Privacy isn't an optional mode — it's just the way that
                    FileSwap works.
                  </Text>
                </Box>

                <VStack spacing="4" flex="6">
                  {[
                    {
                      icon: SecurityKeyIcon,
                      title: "Your files and chats are end-to-end encrypted.",
                      description:
                        "Your files, messages are end-to-end encrypted, and only you hold the keys to decrypt them. We can't see your files, so we can't use them, share them, or sell them.",
                    },
                    {
                      icon: SecurityLockIcon,
                      title: "We use state-of-the-art security.",
                      description:
                        "Your files are end-to-end encrypted to keep them safe at rest and in transit. Our security starts with AES 128-bit encryption, and we use multiple techniques to make sure only you have access to your information. We're continuously working to make sure our code is rock solid.",
                    },
                  ].map(({ icon, title, description }, i) => {
                    const isDark = i % 2 !== 0;

                    return (
                      <HStack
                        key={i}
                        w="full"
                        spacing="4"
                        p="4"
                        bg={isDark ? "gray.900" : "brand.500"}
                        border="1px"
                        borderColor={isDark ? "gray.800" : "brand.400"}
                        borderRadius="xl"
                        color={isDark ? "white" : "black"}
                      >
                        <Icon as={icon} w="auto" h="auto" />
                        <Box>
                          <Text fontWeight="medium">{title}</Text>
                          <Text mt="2">{description}</Text>
                        </Box>
                      </HStack>
                    );
                  })}
                </VStack>
              </Stack>
            </Box>

            <Box id="how-it-works" as="section">
              <HomeSectionHeading
                mb="5"
                dangerouslySetInnerHTML={{
                  __html: "<span>How it</span>\nWorks",
                }}
              />

              <Stack
                direction={{ base: "column", lg: "row" }}
                spacing={{ base: "8", lg: "12" }}
                align="center"
              >
                <VStack spacing="4" flex="1">
                  {[
                    {
                      title: "Create a space.",
                      description:
                        "Start by creating new or joining existing space.\nSpace is a collection of files and users among which you want to send and receive content.\nSpaces in FileSwap can be thought of as group chats in messaging apps.\nEach user in a space must have a unique name for them to correctly join the space.\nSpaces are not persisted on any database and are immediately destroyed once all the users of that room leave.",
                    },
                    {
                      title: "Share a file.",
                      description:
                        "Sharing a file is easy in FileSwap. Simply just drag and drop the file into the share box or click on the 'Share' button at the bottom right and choose the files that you want to send.\n\nOnce the files are selected, they would be shared with all the other users in that room.",
                    },
                  ].map(({ title, description }, i) => (
                    <Box
                      key={i}
                      p="8"
                      bg="gray.900"
                      border="1px"
                      borderColor="gray.800"
                      borderRadius="xl"
                    >
                      <Text
                        textTransform="uppercase"
                        fontWeight="medium"
                        color="brand.500"
                      >
                        Step {i + 1}
                      </Text>
                      <Text mb="1.5" fontWeight="medium" fontSize="xl">
                        {title}
                      </Text>
                      <Text whiteSpace="pre-wrap" color="gray.300">
                        {description}
                      </Text>
                    </Box>
                  ))}
                </VStack>
                <VStack
                  spacing="5"
                  p={{ base: "0", lg: "8" }}
                  flex="1"
                  align="flex-start"
                >
                  <Text
                    textTransform="uppercase"
                    fontWeight="medium"
                    color="brand.500"
                    mb="-1.5"
                  >
                    Available protocols:
                  </Text>

                  {[
                    {
                      icon: PeerIcon,
                      description:
                        "<b>P2P (WebRTC)</b> is the protocol that makes it possible for the peer-to-peer connection for file transfer.",
                    },
                    {
                      icon: CloudIcon,
                      description:
                        "<b>WebTorrent</b> is a peer-to-peer (P2P) streaming torrent client which enables users to distribute data and electronic files over the Internet in a decentralised manner. (Please note that you're not anonymous in torrent network and your IP can be tracked)",
                    },
                    {
                      icon: IpfsIcon,
                      description:
                        "<b>IPFS</b> - The InterPlanetary File System (IPFS) is a protocol, hypermedia and file sharing peer-to-peer network for storing and sharing data in a distributed file system. IPFS uses content-addressing to uniquely identify each file in a global namespace connecting IPFS hosts.",
                    },
                  ].map(({ icon, description }, i) => (
                    <HStack key={i} spacing="2" align="start">
                      <Icon
                        as={icon}
                        w="auto"
                        h="auto"
                        mt="1"
                        color="brand.500"
                      />
                      <Text
                        color="gray.100"
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    </HStack>
                  ))}

                  <Text fontSize="xs" color="gray.300">
                    Do note that if the sender of a file leaves the space and
                    the file isn't transferred to any user yet, the file
                    transfer would get cancelled resulting in no-one receiving
                    the file. This is because your files are not stored on any
                    server and are transferred in real-time when you are online.
                  </Text>
                </VStack>
              </Stack>
            </Box>

            <PublicRoomsSection />

            <Box as="section">
              <HomeSectionHeading
                dangerouslySetInnerHTML={{
                  __html: t("home.createFirst"),
                }}
                mb="6"
              />

              <Flex
                justify="space-between"
                align={{ base: "center", lg: "flex-end" }}
                direction={{ base: "column", lg: "row" }}
              >
                <Box
                  maxW="480px"
                  w="full"
                  bg="gray.800"
                  border="1px"
                  borderBottom={{ lg: "none" }}
                  borderColor="gray.700"
                  p="4"
                  borderRadius="lg"
                  borderBottomRadius={{ lg: "none" }}
                >
                  <CreateRoomForm
                    onCreate={(roomname) => navigate(`/${roomname}`)}
                    {...(profile
                      ? {}
                      : {
                          onCreateClick: () => {
                            setSetupProfileModalOpen.on();
                          },
                        })}
                  />
                </Box>

                <Flex
                  direction="column"
                  align={{ base: "center", lg: "flex-end" }}
                  mt="8"
                  mb="6"
                >
                  <Logo
                    style={{
                      filter: "brightness(99)",
                      opacity: "0.4",
                      width: "256px",
                      height: "auto",
                    }}
                  />

                  <HomeNavLinks my="4" wrap="wrap" justify="center" />

                  <Text fontSize="sm" color="gray.600">
                    {t("home.footer.copyright", {
                      year: new Date().getFullYear(),
                    })}
                  </Text>
                </Flex>
              </Flex>
            </Box>
          </VStack>
        </Box>
      </Box>

      <CreateRoomModal
        isOpen={isCreateRoomModalOpen}
        onClose={setCreateRoomModalOpen.off}
      />

      <SetupProfileModal
        isOpen={isSetupProfileModalOpen}
        onClose={() => {
          profileSetupCbRef.current = undefined;
          setSetupProfileModalOpen.off();
        }}
      />

      <LegalModalProvider />
    </>
  );
};

export default Home;
