import { createContext } from "react";

import type {
  IFile,
  IJoinRequest,
  IFileAccessRequest,
  IMessage,
} from "../types/room";

export interface IRoomSocketConnectionContextValue {
  join: (access?: { password?: string; joinToken?: string }) => Promise<void>;
  remove: () => Promise<void>;
  responseToJoinRequest: (
    req: IJoinRequest,
    action: "accept" | "decline"
  ) => Promise<void>;
  addFile: (file: File, needsApproval?: boolean) => Promise<IFile>;
  responseFileAccess: (
    req: IFileAccessRequest,
    status: "granted" | "denied"
  ) => Promise<void>;
  deleteFile: (fileId: IFile["id"]) => Promise<void>;
  downloadFile: (fileId: IFile["id"]) => Promise<boolean>;
  sendChatMessage: (content: string) => Promise<IMessage>;
}

const RoomSocketConnectionContext =
  createContext<IRoomSocketConnectionContextValue>(
    // @ts-ignore
    null
  );

export default RoomSocketConnectionContext;
