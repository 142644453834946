import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const SecurityKeyIcon: FC<IconProps> = (props) => (
  <Icon size="42" {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M14.575668 20.424332c-2.209139 0-4-1.790861-4-4v-10c0-2.20914 1.790861-4 4-4h12c2.20914 0 4 1.79086 4 4v10c0 2.209139-1.79086 4-4 4h-3v16l-2.28328 2.39846c-.3808.400012-1.013774.415584-1.413785.034783a1.000179 1.000179 0 0 1-.027511-.027224l-3.8751-3.986023c-.381141-.39205-.376728-1.017533.009905-1.404167l1.589771-1.58977-1.579864-1.579864c-.390524-.390525-.390524-1.02369 0-1.414214l1.579864-1.579864v-6.852117h-3Zm14-4v-10c0-1.054362-.815877-1.918165-1.850737-1.994515l-.149263-.005485h-12c-1.054362 0-1.918165.815877-1.994514 1.850737l-.005486.149263v10c0 1.054361.815878 1.918165 1.850738 1.994514l.149262.005486h12c1.054362 0 1.918165-.815878 1.994515-1.850738l.005485-.149262Zm-3-9c0 .552284-.447715 1-1 1h-8c-.552284 0-1-.447716-1-1 0-.552285.447716-1 1-1h8c.552285 0 1 .447715 1 1Zm-4 29.128892V21.424332l-.292893-.292893c-.390524-.390525-1.02369-.390525-1.414214 0l-.292893.292893v15.128892l.28509.291489c.386167.394834 1.019293.40186 1.414127.015693a1.000008 1.000008 0 0 0 .015693-.015693l.28509-.291489Z"
    />
  </Icon>
);

export default SecurityKeyIcon;
