import { FC } from "react";
import { Center, CenterProps, Text } from "@chakra-ui/react";
import { AVATAR_COLORS } from "../../constants/colors";

export interface IAvatarProps extends CenterProps {
  nickname: string;
  colorHash?: string;
  size?: number | string;
}

const Avatar: FC<IAvatarProps> = ({
  nickname,
  colorHash,
  size = "9",
  ...rest
}) => (
  <Center
    w={size}
    h={size}
    borderRadius="sm"
    flexShrink="0"
    bgColor={
      colorHash
        ? AVATAR_COLORS[
            Array.from(colorHash).reduce(
              (total, char) => total + char.codePointAt(0)!,
              0
            ) % AVATAR_COLORS.length
          ]
        : "gray.700"
    }
    {...rest}
  >
    <Text
      fontStyle="italic"
      fontWeight="600"
      pointerEvents="none"
      userSelect="none"
      fontSize={`${Number(size) / 8}rem`}
    >
      {nickname.slice(0, 1)}
    </Text>
  </Center>
);

export default Avatar;
