import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const SuccessIcon: FC<IconProps> = (props) => (
  <Icon size="24" {...props}>
    <defs>
      <radialGradient
        id="success-icon-d"
        cx="68.759%"
        cy="0%"
        r="133.631%"
        fx="68.759%"
        fy="0%"
      >
        <stop offset="0%" stopColor="#59FF9F" />
        <stop offset="100%" stopColor="#26E1A4" />
      </radialGradient>
      <radialGradient
        id="success-icon-c"
        cx="73.371%"
        cy="-8.803%"
        r="137.711%"
        fx="73.371%"
        fy="-8.803%"
      >
        <stop offset="0%" stopColor="#60C3A7" />
        <stop offset="100%" stopColor="#32916E" />
      </radialGradient>
      <filter
        id="success-icon-a"
        width="200%"
        height="200%"
        x="-50%"
        y="-40%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.148898333 0 0 0 0 0.883361518 0 0 0 0 0.641910613 0 0 0 0.307719624 0"
        />
      </filter>
      <path
        id="success-icon-b"
        d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0Zm4.782 6.535-5.527 5.527L6.103 8.91l-1.414 1.414 4.566 4.566 6.941-6.94-1.414-1.415Z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(2 2)">
      <use
        xlinkHref="#success-icon-b"
        fill="#000"
        filter="url(#success-icon-a)"
      />
      <path
        fill="url(#success-icon-d)"
        stroke="url(#success-icon-c)"
        // @ts-ignore
        strokeLinejoin="square"
        d="M10 .5a9.47 9.47 0 0 1 6.718 2.782A9.47 9.47 0 0 1 19.5 10a9.47 9.47 0 0 1-2.782 6.718A9.47 9.47 0 0 1 10 19.5a9.47 9.47 0 0 1-6.718-2.782A9.47 9.47 0 0 1 .5 10a9.47 9.47 0 0 1 2.782-6.718A9.47 9.47 0 0 1 10 .5Zm4.782 5.328-5.527 5.527-3.152-3.152-2.121 2.12 5.273 5.274 7.649-7.648-2.122-2.121Z"
      />
    </g>
  </Icon>
);

export default SuccessIcon;
