import { FC, SVGProps } from "react";

interface IFileIconProps extends SVGProps<SVGSVGElement> {
  name: string;
}

const FileIcon: FC<IFileIconProps> = ({ name, ...props }) => {
  const ext = name.split(".").pop()?.slice(0, 4) || "N/A";

  return (
    <svg
      width="46"
      height="44"
      xmlns="http://www.w3.org/2000/svg"
      style={{ flexShrink: 0 }}
      {...props}
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="112.195%"
          id="file-icon-a"
        >
          <stop stopColor="#535252" offset="0%" />
          <stop stopColor="#3B3A3A" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="file-icon-b">
          <stop stopColor="#645F5F" offset="0%" />
          <stop stopColor="#444441" offset="100%" />
        </linearGradient>
        <filter
          x="-17.6%"
          y="-42.9%"
          width="135.3%"
          height="185.7%"
          filterUnits="objectBoundingBox"
          id="file-icon-c"
        >
          <feGaussianBlur stdDeviation="2" in="SourceGraphic" />
        </filter>
        <filter
          x="-35.7%"
          y="-28.6%"
          width="171.4%"
          height="171.4%"
          filterUnits="objectBoundingBox"
          id="file-icon-d"
        >
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation="1.5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.313210227 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <path
          d="m20 0 14 14h-8.872c-1.783 0-2.43-.186-3.082-.534a3.635 3.635 0 0 1-1.512-1.512c-.348-.652-.534-1.299-.534-3.082V0Z"
          id="file-icon-e"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M19.793.5 33.5 14.207V38c0 .966-.392 1.841-1.025 2.475A3.489 3.489 0 0 1 30 41.5H4a3.489 3.489 0 0 1-2.475-1.025A3.489 3.489 0 0 1 .5 38V4c0-.966.392-1.841 1.025-2.475A3.489 3.489 0 0 1 4 .5h15.793Z"
          fill="url(#file-icon-a)"
          stroke="url(#file-icon-b)"
          transform="translate(6 2)"
        />
        <text
          opacity=".483"
          filter="url(#file-icon-c)"
          fontFamily="Roboto-Bold, Roboto"
          fontSize="12"
          fontWeight="bold"
          fill="#75E5B4"
        >
          <tspan x="50%" textAnchor="middle" y="29">
            {ext.toUpperCase()}
          </tspan>
        </text>
        <text
          fontFamily="Roboto-Bold, Roboto"
          fontSize="12"
          fontWeight="bold"
          fill="#26E1A4"
        >
          <tspan
            x="50%"
            textAnchor="middle"
            y="29"
            style={{ userSelect: "none" }}
          >
            {ext.toUpperCase()}
          </tspan>
        </text>
        <g transform="translate(6 2)">
          <use
            fill="#000"
            filter="url(#file-icon-d)"
            xlinkHref="#file-icon-e"
          />
          <path
            stroke="#645F5F"
            d="M20.5 1.207 32.793 13.5h-7.665c-1.642 0-2.244-.153-2.846-.475a3.142 3.142 0 0 1-1.307-1.307c-.322-.602-.475-1.204-.475-2.846V1.207Z"
            // @ts-ignore
            strokeLinejoin="square"
            fill="#5C5B5B"
          />
        </g>
      </g>
    </svg>
  );
};

export default FileIcon;
