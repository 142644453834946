import jwtDecode from "jwt-decode";
import create from "zustand";
import { devtools, persist } from "zustand/middleware";

import { PROFILE_STORE_KEY } from "../constants/storage";
import { authenticate } from "../controllers/auth";
import { IProfile } from "../types/profile";

interface IProfileState {
  profile?: IProfile;
  accessToken?: string;
  setProfile: (profile: Required<IProfileState["profile"]>) => void;
  setNewProfile: (nickname: string) => Promise<void>;
}

export const useProfileStore = create<IProfileState>()(
  devtools(
    persist(
      (set) => ({
        profile: undefined,
        setProfile: (profile) => set({ profile }),
        setNewProfile: async (nickname: string) => {
          const { data: accessToken } = await authenticate(nickname);
          const profile = jwtDecode(accessToken) as IProfile;
          set({ accessToken, profile });
        },
      }),
      {
        name: PROFILE_STORE_KEY,
      }
    ),
    { enabled: process.env.NODE_ENV === "development" }
  )
);
