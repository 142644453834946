import { FC } from "react";
import { Box, HStack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { useRoomStore } from "../../stores/roomStore";
import Avatar from "../ui/Avatar";
import Message from "./Message";
import { useProfileStore } from "../../stores/profileStore";

const ChatEntiresList: FC = () => {
  const { t } = useTranslation();
  const entries = useRoomStore((state) => state.chatEntries);
  const userId = useProfileStore((state) => state.profile?.uid);

  return (
    <>
      {entries.map((entry, i) =>
        entry.type === "message" ? (
          <Message
            key={entry.entity.id}
            msg={entry.entity}
            isOwner={!!userId && entry.entity.user.id === userId}
          />
        ) : entry.type === "notification" ? (
          <HStack key={`${entry.entity.activity}_${i}`} spacing="2" pl="10">
            <Avatar
              nickname={entry.entity.user.nickname}
              colorHash={entry.entity.user.id}
              size="6"
            />
            <Text flexShrink="0" fontSize="sm" color="gray.500">
              {t(`room.chat.notification.label_${entry.entity.activity}`, {
                nickname: entry.entity.user.nickname,
              })}
            </Text>
            <Box flex="1" h="1px" bg="gray.600" />
          </HStack>
        ) : null
      )}
    </>
  );
};

export default ChatEntiresList;
