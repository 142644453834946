import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "webrtc-adapter";

import "./styles/global.scss";
import theme from "./config/theme";
import bootstrap from "./app/bootstrap";
import App from "./components/App";

bootstrap();

Sentry.init({
  dsn: "https://71de88b808484c2fb72ff71686ec7497@o4504442205569024.ingest.sentry.io/4504447250792448",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ChakraProvider>
  </StrictMode>
);
