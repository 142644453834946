import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const ChevronRightIcon: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeWidth="2.5"
      d="m5 2 10 8-10 8"
    />
  </Icon>
);

export default ChevronRightIcon;
