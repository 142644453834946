import { FC } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { IMember } from "../../types/room";
import Avatar from "./Avatar";

export interface IAvatarsStackProps extends BoxProps {
  users: IMember[];
  size?: number;
}

const AvatarsStack: FC<IAvatarsStackProps> = ({
  users,
  size = 32,
  ...rest
}) => (
  <Box position="relative" {...rest}>
    <Box width={`${size}px`} height={`${size}px`} />
    {users.map(({ id, nickname }, i) => {
      const transform = (x: number): number =>
        x === 0
          ? 0
          : x === 1
          ? size * 0.3
          : transform(x - 1) * 0.85 ** ((i * i) / 2) + transform(x - 1);
      const scale = Math.min(Math.max((5 - i) / 5, 0.5), 1);

      return (
        <Avatar
          key={id}
          colorHash={id}
          nickname={nickname}
          size={`${size}px`}
          boxShadow="0 2px 4px 0 rgba(0, 0, 0, 0.33)"
          position="absolute"
          top="0"
          transform={`translateX(${transform(i)}px) scale(${scale})`}
          zIndex={(users.length - i) * 2}
        />
      );
    })}
  </Box>
);

export default AvatarsStack;
