import { useEffect } from "react";

const useHtmlScript = (scriptId: string, html: string) => {
  useEffect(() => {
    const extractScript = /<script(.*?)>(.*?)<\/script>/gi;
    let match = null;
    while ((match = extractScript.exec(html))) {
      const [, attrs, content] = match;

      if (attrs) {
        const extractSrc = /src="(.*)"/gi;
        const [, src] = extractSrc.exec(attrs) || [];

        if (src) {
          try {
            const script = document.createElement("script");
            script.id = scriptId;
            script.setAttribute("type", "text/javascript");
            script.setAttribute("src", src);

            document.querySelector(`script#${scriptId}`)?.remove();
            document.head.appendChild(script);
          } catch {}
        }
      }

      if (content) {
        // eslint-disable-next-line no-eval
        window.eval(content);
      }
    }
  }, [scriptId, html]);
};

export default useHtmlScript;
