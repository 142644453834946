import { FC, useEffect } from "react";
import { Spinner, Tag, useBoolean, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { useIsRoomLoaded, useRoomStore } from "../../stores/roomStore";
import Panel from "../ui/Panel";
import Member from "./Member";
import JoinRequestsModal from "./JoinRequestsModal";
import useRoomOwner from "../../hooks/useRoomOwner";

const MembersPanel: FC = () => {
  const { t } = useTranslation();
  const members = useRoomStore((state) => state.members);
  const isRoomLoaded = useIsRoomLoaded();
  const requestsCount = useRoomStore(
    (state) => state.joinRequests?.length ?? 0
  );
  const isOwner = useRoomOwner();
  const [isRequestsModalOpen, setRequestsModalOpen] = useBoolean();

  useEffect(() => {
    if ((!isOwner || requestsCount === 0) && isRequestsModalOpen) {
      setRequestsModalOpen.off();
    }
  }, [isOwner, requestsCount]);

  return (
    <>
      <Panel
        title={t("room.members.title")}
        flex="1"
        actions={
          isOwner && requestsCount > 0 ? (
            <Tag as="button" onClick={setRequestsModalOpen.on}>
              {t("room.members.requests", { count: requestsCount })}
            </Tag>
          ) : undefined
        }
      >
        <VStack spacing="3" align="stretch">
          {isRoomLoaded ? (
            members.map((member) => <Member key={member.id} member={member} />)
          ) : (
            <Spinner mx="auto" my="4" />
          )}
        </VStack>
      </Panel>

      <JoinRequestsModal
        isOpen={isRequestsModalOpen}
        onClose={setRequestsModalOpen.off}
      />
    </>
  );
};

export default MembersPanel;
