import axios from "axios";
import i18n from "i18next";
import jwtDecode from "jwt-decode";
import { initReactI18next } from "react-i18next";
import log from "loglevel";

import defaultTranslation from "../locales/en/translation.json";
import { useProfileStore } from "../stores/profileStore";
import { refreshToken } from "../controllers/auth";
import { IProfile } from "../types/profile";
import { PROFILE_STORE_KEY } from "../constants/storage";

const bootstrap = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.interceptors.request.use((request) => {
    if (!request.url!.startsWith("/auth/new")) {
      const profileStoreRaw = localStorage.getItem(PROFILE_STORE_KEY);
      if (profileStoreRaw) {
        const {
          state: { accessToken },
        } = JSON.parse(profileStoreRaw) as {
          state: { accessToken: string };
        };
        request.headers!["Authorization"] = `Bearer ${accessToken}`;
      }
    }

    return request;
  });

  // log.setLevel(process.env.NODE_ENV === "production" ? log.levels.INFO : log.levels.DEBUG);
  log.setLevel(log.levels.DEBUG);

  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: defaultTranslation,
      },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: true,
    },
  });

  const { accessToken } = useProfileStore.getState();
  if (accessToken) {
    const { exp } = jwtDecode(accessToken) as { exp: number };
    if ((exp - 3 * 60 * 60) * 1000 < Date.now()) {
      (async () => {
        try {
          const { data: newToken } = await refreshToken();
          useProfileStore.setState({
            accessToken: newToken,
            profile: jwtDecode(newToken) as IProfile,
          });
        } catch (err) {
          log.error("Error refreshing user token", err);
          useProfileStore.setState({
            accessToken: undefined,
            profile: undefined,
          });
        }
      })();
    }
  }
};

export default bootstrap;
