import { FC, useEffect } from "react";
import {
  Box,
  Button,
  CloseButton,
  Fade,
  HStack,
  Icon,
  Progress,
  Spinner,
  StackProps,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { TSnackbarAlert, TSnackbarAlertStatus } from "../../types/snackbar";
import { ErrorIcon, SuccessIcon, WarningIcon } from "../icons";

interface SnackbarAlertProps extends StackProps {
  alert: TSnackbarAlert;
  onClose?: (id: string) => void;
}

const snackbarAlertIcons: Record<TSnackbarAlertStatus, FC> = {
  success: SuccessIcon,
  warning: WarningIcon,
  error: ErrorIcon,
};

const SnackbarAlert: FC<SnackbarAlertProps> = ({ alert, onClose, ...rest }) => {
  const { t } = useTranslation();
  const { id, content, duration, isClosable } = alert;
  const StatusIcon =
    alert.type === "status" ? snackbarAlertIcons[alert.status] : null;

  useEffect(() => {
    let timeout: ReturnType<Window["setTimeout"]>;

    if (onClose && typeof duration === "number") {
      timeout = window.setTimeout(() => {
        onClose(id);
      }, duration);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [id, onClose]);

  return (
    <Fade in>
      <HStack
        p="2.5"
        spacing="2"
        border="1px"
        borderColor="gray.700"
        bg="gray.800"
        boxShadow={{ lg: "0 3px 7px 0 rgba(0, 0, 0, 0.32)" }}
        borderRadius="md"
        mt="3"
        {...rest}
      >
        {StatusIcon && <Icon as={StatusIcon} w="1.5rem" h="auto" />}
        {alert.type === "progress" && typeof alert.value === "undefined" && (
          <Spinner size="md" />
        )}

        <Box flex="1">
          <Text
            dangerouslySetInnerHTML={{ __html: content }}
            css={`
              i {
                color: var(--chakra-colors-gray-300);
                font-weight: var(--chakra-fontWeights-medium);
              }
            `}
          />
          {alert.type === "progress" && typeof alert.value === "number" && (
            <Progress value={alert.value} isAnimated hasStripe mt="2" />
          )}
        </Box>

        {alert.type === "question" && (
          <HStack spacing="2" flexShrink="0" flexWrap="nowrap">
            <Button onClick={() => alert.cb(true)} size="sm" variant="outline">
              {t("common.accept")}
            </Button>
            <Button
              onClick={() => alert.cb(false)}
              size="sm"
              variant="outline"
              colorScheme="red"
            >
              {t("common.decline")}
            </Button>
          </HStack>
        )}

        {onClose && isClosable && (
          <CloseButton onClick={() => onClose(id)} size="sm" flexShrink="0" />
        )}
      </HStack>
    </Fade>
  );
};

export default SnackbarAlert;
