import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const CloudIcon: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M9.994462 0 19 4.954603v10.095575L10.00534 20 1 15.050178V4.954603L9.994462 0Zm-.000028 2.367488L3.076896 6.178702v7.645936l6.92723 3.805682 6.918924-3.807065v-7.64317L9.994434 2.367488Zm1.92757 3.936258c1.193053 0 2.160214 1.039164 2.160214 2.321038 0 1.084662-.577089 2.017697-1.11842 2.687835l-.288042.33879-.07865.084459-.204617.20213-.732843.69236-.35704.348739-.37949.388037-.184681.205325-.355368.4325-.44938.581914-.46001-.581914-.355368-.4325-.184682-.205325-.561402-.56739-.90797-.861745-.204617-.202132-.07865-.084459c-.600445-.674654-1.406462-1.744751-1.406462-3.026624 0-1.281874.96716-2.321038 2.160215-2.321038.841328 0 1.570322.516768 1.92703 1.270998l.065799.154766.067403-.154766c.356708-.75423 1.085702-1.270998 1.92703-1.270998Z"
    />
  </Icon>
);

export default CloudIcon;
