import create from "zustand";
import { devtools, persist } from "zustand/middleware";

import { TView } from "../types/view";

interface IViewState {
  activeTab: TView;
  setActiveTab: (activeTab: TView) => void;
}

export const useViewStore = create<IViewState>()(
  devtools(
    persist(
      (set) => ({
        activeTab: "files",
        setActiveTab: (activeTab) => set({ activeTab }),
      }),
      {
        name: "view-store",
      }
    ),
    { enabled: process.env.NODE_ENV === "development" }
  )
);
