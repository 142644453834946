import { FC } from "react";
import { Box, Text } from "@chakra-ui/react";

import Avatar, { IAvatarProps } from "./Avatar";

export interface IPropsVCard extends Omit<IAvatarProps, "colorHash"> {
  uid: string;
  align?: "left" | "right";
}

const VCard: FC<IPropsVCard> = ({ uid, nickname, align = "left" }) => (
  <Box
    display="flex"
    alignItems="center"
    flexDir={align === "left" ? "row" : "row-reverse"}
  >
    <Avatar
      nickname={nickname}
      colorHash={uid}
      {...(align === "left" ? { mr: "3" } : { ml: "3" })}
    />

    <Box textAlign={align}>
      <Text fontSize="sm" fontWeight="500" lineHeight="4">
        {nickname}
      </Text>
      <Text fontSize="xs" lineHeight="3" color="gray.600" marginTop="1">
        #{uid}
      </Text>
    </Box>
  </Box>
);

export default VCard;
