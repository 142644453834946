import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const EnterIcon: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd">
      <path d="m7.988 17-4-4 4-4" />
      <path d="M4.606 13H16.5V5.583h-4.149" />
    </g>
  </Icon>
);

export default EnterIcon;
