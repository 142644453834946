import { FC } from "react";
import { Box, Flex, HStack, Icon, Link, Text, VStack } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import MembersPanel from "./MembersPanel";
import UploadPanel from "./UploadPanel";
import SharePanel from "./SharePanel";
import LogoGlyph from "../ui/LogoGlyph";
import { ExternalLinkIcon, InfoIcon } from "../icons";

const Sidebar: FC = () => {
  const { t } = useTranslation();

  return (
    <VStack
      py="4"
      spacing="4"
      align="stretch"
      flexShrink="0"
      w="full"
      maxWidth="320px"
      minHeight="728px"
    >
      <SharePanel />
      <MembersPanel />
      <UploadPanel />

      <HStack
        spacing="3"
        px="3"
        py="2"
        borderRadius="md"
        color="#F2B324"
        bgColor="#423822"
        border="1px"
        borderColor="#61470D"
      >
        <Icon as={InfoIcon} w="auto" h="auto" />
        <Box>
          <Text fontWeight="medium">{t("room.sidebar.beta.label")}</Text>
          <Text fontSize="sm">
            <Link href={t("room.sidebar.beta.href")}>
              {t("room.sidebar.beta.link")}
              <Icon as={ExternalLinkIcon} verticalAlign="text-top" ml="0.5" />
            </Link>
          </Text>
        </Box>
      </HStack>

      <Flex
        justify="space-between"
        align="center"
        fontSize="sm"
        color="gray.500"
      >
        <Link as={RouterLink} to="/">
          <LogoGlyph width="135px" height="auto" />
        </Link>

        <HStack spacing="2">
          <Link href="mailto:contact@fileswap.org">
            {t("room.sidebar.contact")}
          </Link>
        </HStack>
      </Flex>
    </VStack>
  );
};

export default Sidebar;
