import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const CopyIcon: FC<IconProps> = (props) => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M10 0a4 4 0 0 1 4 4h-2a2 2 0 0 0-1.85-1.995L10 2H4a2 2 0 0 0-1.995 1.85L2 4v8a2 2 0 0 0 1.85 1.995L4 14h2v2H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h6Z"
        fill="currentColor"
        fillRule="nonzero"
      />
      <rect
        stroke="currentColor"
        strokeWidth="2"
        x="7"
        y="5"
        width="12"
        height="14"
        rx="4"
      />
    </g>
  </Icon>
);

export default CopyIcon;
