import { FC } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import SetupProfileForm from "./SetupProfileForm";

type ISetupProfileModalProps = Omit<ModalProps, "children" | "onClose"> &
  Partial<Pick<ModalProps, "onClose">>;

const SetupProfileModal: FC<ISetupProfileModalProps> = ({
  onClose,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      scrollBehavior="outside"
      onClose={onClose ? onClose : () => {}}
      {...rest}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("profile.form.title")}</ModalHeader>
        {onClose && <ModalCloseButton />}

        <ModalBody>
          <SetupProfileForm />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SetupProfileModal;
