import { FC } from "react";
import { Box, BoxProps } from "@chakra-ui/react";

const PongLoader: FC<BoxProps> = (props) => (
  <Box
    position="relative"
    overflow="hidden"
    w="27px"
    h="5px"
    borderRadius="3px"
    bg="brand.800"
    {...props}
  >
    <Box
      position="absolute"
      w="9px"
      h="full"
      borderRadius="inherit"
      bg="brand.500"
      animation="ping-pong 0.75s ease-in-out infinite alternate"
    />
  </Box>
);

export default PongLoader;
