import { FC, useContext, useEffect } from "react";
import { useBoolean, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { useRoomStore } from "../../stores/roomStore";
import RoomSocketConnectionContext from "../../contexts/RoomSocketConnectionContext";
import SnackbarAlert from "../common/SnackbarAlert";
import { createPortal } from "react-dom";
import { SNACKBAR_FILE_REQUESTS_CONTAINER_ID } from "../../constants/dom";

const SnackbarFileRequests: FC = () => {
  const { t } = useTranslation();
  const fileAccessRequests = useRoomStore((state) => state.fileAccessRequests);
  const removeFileAccessRequest = useRoomStore(
    (state) => state.removeFileAccessRequest
  );
  const { responseFileAccess } = useContext(RoomSocketConnectionContext);
  const [mounted, setMounted] = useBoolean();

  useEffect(() => {
    setMounted.on();
  }, []);

  return mounted
    ? createPortal(
        <Box>
          {fileAccessRequests.map((req) => (
            <SnackbarAlert
              key={req.id}
              alert={{
                id: req.id,
                type: "question",
                cb: (accepted) => {
                  removeFileAccessRequest(req.id);
                  responseFileAccess(req, accepted ? "granted" : "denied");
                },
                content: t("alerts.fileAccessRequest", {
                  nickname: req.client.nickname,
                  fileId: req.fileId,
                }),
              }}
              onClose={removeFileAccessRequest}
            />
          ))}
        </Box>,
        document.getElementById(SNACKBAR_FILE_REQUESTS_CONTAINER_ID)!
      )
    : null;
};

export default SnackbarFileRequests;
