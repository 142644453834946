import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const DeleteFileIcon: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1h6a1 1 0 0 1 0 2H2a1 1 0 1 1 0-2h6V2Zm9 4v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6h14Zm-7 2a1 1 0 0 0-.993.883L9 9v7a1 1 0 0 0 1.993.117L11 16V9a1 1 0 0 0-1-1ZM6 8a1 1 0 0 0-.993.883L5 9v7a1 1 0 0 0 1.993.117L7 16V9a1 1 0 0 0-1-1Zm8 0a1 1 0 0 0-.993.883L13 9v7a1 1 0 0 0 1.993.117L15 16V9a1 1 0 0 0-1-1Z"
    />
  </Icon>
);

export default DeleteFileIcon;
