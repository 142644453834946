import React, { memo } from "react";
import { Box, BoxProps } from "@chakra-ui/react";

import useHtmlScript from "../../hooks/useHtmlScript";

interface AdBannerProps extends BoxProps {
  scriptId: string;
  html: string;
}

const AdBanner: React.FC<AdBannerProps> = ({ scriptId, html }) => {
  useHtmlScript(scriptId, html);

  return <Box dangerouslySetInnerHTML={{ __html: html }} />;
};

export default memo(AdBanner);
