import { extendTheme, StyleFunctionProps } from "@chakra-ui/react";
import { transparentize } from "@chakra-ui/theme-tools";

import Frame from "../components/ui/Frame";

const theme = extendTheme({
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  styles: {
    global: {
      "html, body": {
        height: "100%",
      },
      body: {
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: "#212121",
      },
      "#root": {
        width: "100%",
        minHeight: "100vh",
      },
    },
  },
  sizes: {
    "4xs": "10rem",
  },
  colors: {
    brand: {
      50: "#f0fff7",
      100: "#cfffe8",
      200: "#a6ffd8",
      300: "#7afac7",
      400: "#4eedb3",
      500: "#26E1A4",
      600: "#16ba89",
      700: "#0a946f",
      800: "#026e55",
      900: "#014739",
    },
    gray: {
      50: "#FAF7F7",
      100: "#DBDBDB",
      200: "#DBDBDB",
      300: "#B4B0B0",
      400: "#999999",
      500: "#999999",
      600: "#6A6A6A",
      700: "#484848",
      800: "#343434",
      900: "#2C2B2B",
    },
  },
  radii: {
    sm: "0.25rem",
    base: "0.375rem",
    md: "0.5rem",
    lg: "0.75rem",
    xl: "1rem",
  },
  components: {
    Frame,
    Input: {
      defaultProps: {
        colorScheme: "brand",
      },
      sizes: {
        md: {
          field: {
            height: "44px",
            paddingX: "4",
            borderRadius: "md",
          },
        },
      },
      variants: {
        outline: {
          field: {
            background: "gray.900",
            borderColor: "gray.700",
            _hover: {
              borderColor: "gray.600",
            },
            _focus: {
              boxShadow: "none",
              borderColor: "brand.500",
            },
            _placeholder: {
              color: "gray.300",
            },
            _invalid: {
              boxShadow: "none",
              borderColor: "red.500",
            },
          },
        },
      },
    },
    Button: {
      defaultProps: {
        colorScheme: "brand",
      },
      variants: {
        solid: ({ colorScheme }: StyleFunctionProps) => {
          return colorScheme === "brand"
            ? {
                bg: "brand.500",
                color: "gray.800",
                _hover: {
                  bg: "brand.400",
                },
                _active: {
                  bg: "brand.600",
                },
              }
            : {};
        },
        outline: ({ colorScheme }: StyleFunctionProps) => {
          return colorScheme === "brand"
            ? {
                bgColor: transparentize("brand.500", 0.1),
                color: "brand.500",
                borderColor: "brand.500",
                fontWeight: "medium",
                _hover: {
                  bgColor: transparentize("brand.500", 0.15),
                },
                _active: {
                  bgColor: transparentize("brand.500", 0.2),
                },
              }
            : {};
        },
        ghost: {
          color: "brand.500",
          bgColor: transparentize("brand.500", 0.2),
          _hover: {
            bgColor: transparentize("brand.500", 0.275),
          },
          _active: {
            bgColor: transparentize("brand.500", 0.15),
          },
        },
        "icon-ghost": {
          color: "brand.500",
          bgColor: "transparent",
          _hover: {
            bgColor: "transparent",
          },
          _active: {
            bgColor: transparentize("brand.500", 0.1),
          },
        },
      },
    },
    Checkbox: {
      defaultProps: {
        colorScheme: "brand",
      },
    },
    Radio: {
      defaultProps: {
        colorScheme: "brand",
      },
    },
    Tag: {
      variants: {
        subtle: {
          container: {
            bg: "gray.900",
            color: "brand.500",
            border: "1px",
            borderColor: "brand.800",
            fontSize: "xs",
            fontWeight: "medium",
            lineHeight: "3",
            minHeight: "auto",
            px: "0.375rem",
            py: "0.25rem",
            borderRadius: "sm",
          },
        },
      },
    },
    Tabs: {
      defaultProps: {
        colorScheme: "brand",
      },
    },
    FormLabel: {
      baseStyle: {
        color: "gray.300",
      },
    },
    Modal: {
      baseStyle: {
        dialogContainer: {
          px: "4",
        },
        dialog: {
          bg: "gray.800",
          borderRadius: "lg",
          border: "1px",
          borderColor: "gray.700",
        },
        closeButton: {
          top: "4",
          right: "4",
        },
        header: {
          px: {
            base: "4",
            md: "6",
          },
        },
        body: {
          px: {
            base: "4",
            md: "6",
          },
        },
        footer: {
          px: {
            base: "4",
            md: "6",
          },
        },
      },
    },
    Menu: {
      baseStyle: {
        list: {
          py: "1",
          borderRadius: "md",
          bg: "gray.700",
          borderColor: "gray.600",
          minW: "4xs",
          zIndex: "100",
        },
      },
    },
    Switch: {
      baseStyle: {
        track: {
          p: "0",
          outline: "none",
          bg: "gray.900",
          border: "1px",
          borderRadius: "sm",
          borderColor: "gray.700",
          _checked: {
            bg: "gray.900",
          },
        },
        thumb: {
          w: "1.25rem",
          h: "1.25rem",
          mt: "-0.125rem",
          mr: "-0.062.5rem",
          bg: "gray.600",
          boxShadow: "none",
          bgImage: `url("data:image/svg+xml,%3Csvg width='13' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1 4.506 3.496 3.496L11.498 1' stroke='%23999999' stroke-width='2' fill='none' fill-rule='evenodd'/%3E%3C/svg%3E")`,
          bgRepeat: "no-repeat",
          bgPosition: "center",
          _checked: {
            bg: "brand.500",
            boxShadow: "0 0 0 1px var(--chakra-colors-brand-600)",
            bgImage: `url("data:image/svg+xml,%3Csvg width='13' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1 4.506 3.496 3.496L11.498 1' stroke='%23FFF' stroke-width='2' fill='none' fill-rule='evenodd'/%3E%3C/svg%3E")`,
            bgRepeat: "no-repeat",
            bgPosition: "center",
          },
        },
      },
    },
    Spinner: {
      baseStyle: {
        color: "brand.500",
      },
    },
    Progress: {
      defaultProps: {
        colorScheme: "brand",
        size: "sm",
      },
      baseStyle: {
        filledTrack: {
          backgroundColor: "brand.500",
          borderRadius: "md",
        },
        track: {
          borderRadius: "md",
        },
      },
    },
  },
});

export default theme;
