import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const InfoIcon: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="m11.567596 1.363346.096505.132805L19.927066 13.8906a2 2 0 0 1 .3359 1.1094c0 1.054362-.815878 1.918165-1.850738 1.994514L18.262966 17H11v3H9v-3H1.737034a2 2 0 0 1-1.1094-.3359c-.873105-.58207-1.140894-1.731842-.640172-2.63334l.085472-.14016L8.335899 1.49615a2 2 0 0 1 .5547-.5547c.873105-.582069 2.03743-.387039 2.676997.421896ZM10 2.605551 1.737034 15h16.525932L10 2.605551ZM10 12c.552285 0 1 .447715 1 1s-.447715 1-1 1-1-.447715-1-1 .447715-1 1-1Zm0-6c.552285 0 1 .447715 1 1v3c0 .552285-.447715 1-1 1s-1-.447715-1-1V7c0-.552285.447715-1 1-1Z"
    />
  </Icon>
);

export default InfoIcon;
