import { FC, useContext } from "react";
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { useRoomStore } from "../../stores/roomStore";
import Frame from "../ui/Frame";
import VCard from "../ui/VCard";
import RoomSocketConnectionContext from "../../contexts/RoomSocketConnectionContext";

type IJoinRequestsModalProps = Omit<ModalProps, "children">;

const JoinRequestsModal: FC<IJoinRequestsModalProps> = (props) => {
  const { t } = useTranslation();
  const requests = useRoomStore((state) => state.joinRequests);
  const { responseToJoinRequest } = useContext(RoomSocketConnectionContext);

  return (
    <Modal scrollBehavior="outside" {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("room.requests.modal.title")}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <VStack spacing="2" pb="2" align="stretch">
            {requests?.map((req) => {
              const { id, client } = req;

              return (
                <Frame
                  key={id}
                  as={HStack}
                  justifyContent="space-between"
                  p="2"
                  borderRadius="md"
                >
                  <VCard uid={client.id} nickname={client.nickname} />

                  <HStack spacing="2">
                    <Button
                      onClick={() => responseToJoinRequest(req, "accept")}
                      size="sm"
                      variant="outline"
                    >
                      {t("common.accept")}
                    </Button>
                    <Button
                      onClick={() => responseToJoinRequest(req, "decline")}
                      size="sm"
                      variant="outline"
                      colorScheme="red"
                    >
                      {t("common.decline")}
                    </Button>
                  </HStack>
                </Frame>
              );
            })}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default JoinRequestsModal;
