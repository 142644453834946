import { FC } from "react";
import {
  Button,
  VStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Spinner,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type IRoomWaitingResponseModalProps = Omit<ModalProps, "children" | "onClose"> &
  Partial<Pick<ModalProps, "onClose">>;

const RoomWaitingResponseModal: FC<IRoomWaitingResponseModalProps> = ({
  onClose,
  ...rest
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal
      scrollBehavior="outside"
      onClose={onClose ? onClose : () => {}}
      {...rest}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("room.waitingResponse.title")}</ModalHeader>
        {onClose && <ModalCloseButton />}

        <ModalBody>
          <VStack spacing="8" my="2">
            <Spinner size="xl" />

            <Button w="full" onClick={() => navigate("/")}>
              {t("common.goBack")}
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RoomWaitingResponseModal;
