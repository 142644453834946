import { FC } from "react";

import Icon, { SmartIconProps } from "./Icon";

const IpfsSmartIcon: FC<SmartIconProps> = ({ isActive, ...rest }) => (
  <Icon width="32px" height="32px" viewBox="0 0 32 32" {...rest}>
    <defs>
      <linearGradient
        id="ipfs-smart-icon-c"
        x1="50%"
        x2="50%"
        y1="-17.541054%"
        y2="65.180299%"
      >
        <stop offset="0%" stopColor="#20C494" />
        <stop offset="100%" stopColor="#008C63" />
      </linearGradient>
      <linearGradient
        id="ipfs-smart-icon-g"
        x1="27.180509%"
        x2="74.312808%"
        y1="-5.822543%"
        y2="78.834402%"
      >
        <stop offset="0%" stopColor="#C9C9C9" />
        <stop offset="100%" stopColor="#6B6969" />
      </linearGradient>
      <filter
        id="ipfs-smart-icon-a"
        width="192.3%"
        height="185.7%"
        x="-46.2%"
        y="-42.9%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.148898333   0 0 0 0 0.883361518   0 0 0 0 0.641910613  0 0 0 0.299306163 0"
        />
      </filter>
      <path
        id="ipfs-smart-icon-b"
        d="m17 21.592 4.016443 2.23011c-.010861.094593-.016443.190791-.016443.288293 0 1.081652.686927 2.002863 1.64833 2.351184L22.649 30.999 17 27.887224V21.592Zm12.999.001L30 27.849939 24.35 30.991l.00167-4.529413C25.313073 26.113267 26 25.192055 26 24.110403c0-.097502-.005582-.1937-.016443-.288293L29.999 21.593ZM23.503877 17 29.151 20.17l-3.892722 2.163198c-.45164-.446861-1.072736-.722795-1.758278-.722795-.685036 0-1.305719.275526-1.757277.721805L17.801 20.144 23.503877 17Z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use
        xlinkHref="#ipfs-smart-icon-b"
        fill="#000"
        filter="url(#ipfs-smart-icon-a)"
      />
      <use
        xlinkHref="#ipfs-smart-icon-b"
        fill={isActive ? "#26E1A4" : "#C3C3C3"}
      />
      <path
        fill={isActive ? "url(#ipfs-smart-icon-c)" : "url(#ipfs-smart-icon-g)"}
        d="M14.002222 26v2H8c-2.209139 0-4-1.790861-4-4V6c0-2.209139 1.790861-4 4-4h10.5L26 9.683406V16l-2-1v-3h-5.4c-1.43594 0-2.6-1.16406-2.6-2.6V4H8c-1.054362 0-1.918165.815878-1.994514 1.850738L6 6v18c0 1.054362.815878 1.918165 1.850738 1.994514L8 26h6.002222Z"
      />
    </g>
  </Icon>
);

export default IpfsSmartIcon;
