import { FC } from "react";

import Icon, { IconProps } from "./Icon";

const DeleteFileIcon: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="m12 0 6 6.147V10a1 1 0 0 1-2 0V8h-4a2 2 0 0 1-2-2V2H6a2 2 0 0 0-1.995 1.85L4 4v12a2 2 0 0 0 1.85 1.995L6 18h4a1 1 0 0 1 .117 1.993L10 20H6a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h6Zm1.879 12.618 2.12 2.12 2.122-2.12a1 1 0 0 1 1.415 1.414l-2.122 2.12 2.122 2.122a1 1 0 1 1-1.415 1.415L16 17.567l-2.121 2.122a1 1 0 0 1-1.415-1.415l2.12-2.121-2.12-2.121a1 1 0 0 1 1.415-1.414Z"
    />
  </Icon>
);

export default DeleteFileIcon;
