import { Box, BoxProps } from "@chakra-ui/react";
import { FC } from "react";

const Frame: FC<BoxProps> = (props) => (
  <Box
    bg="gray.800"
    border="1px"
    borderColor="gray.700"
    borderRadius="lg"
    {...props}
  />
);

export default Frame;
