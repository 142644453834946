import { FC, useContext, useMemo } from "react";
import {
  Box,
  Fade,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Text,
  useBoolean,
  useClipboard,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { useRoomStore } from "../../stores/roomStore";
import useMediaQueryDesktop from "../../hooks/useMediaQueryDesktop";
import { IFile, IMember } from "../../types/room";
import Avatar from "../ui/Avatar";
import PongLoader from "../ui/PongLoader";
import AvatarsStack from "../ui/AvatarsStack";
import MeatballsMenuButton from "../ui/MeatballsMenuButton";
import DownloadIcon from "../icons/DownloadIcon";
import RoomSocketConnectionContext from "../../contexts/RoomSocketConnectionContext";
import { useProfileStore } from "../../stores/profileStore";
import { CopyIcon, DeleteFileIcon } from "../icons";
import { useAddStatusAlert } from "../../stores/snackbarStore";
import useRoomOwner from "../../hooks/useRoomOwner";
import FileIcon from "../common/FileIcon";
const prettysize = require("prettysize");

export interface IFileItemProps {
  file: IFile;
  downloaders?: IMember[];
}

const FileItem: FC<IFileItemProps> = ({
  file: { id, name, size, ownerId, magnetUri, ipfsHash },
  downloaders,
}) => {
  const { t } = useTranslation();
  const members = useRoomStore((state) => state.members);
  const owner = useMemo(
    () => members && members.find((member) => member.id === ownerId),
    [ownerId, members]
  );
  const userId = useProfileStore((state) => state.profile?.uid);
  const isOwner = userId && owner?.id === userId;
  const isRoomOwner = useRoomOwner();
  const isDesktop = useMediaQueryDesktop();
  const [isMouseOver, setMouseOver] = useBoolean();
  const { downloadFile, deleteFile } = useContext(RoomSocketConnectionContext);
  const addAlert = useAddStatusAlert();
  const { onCopy: copyMagnetUri } = useClipboard(magnetUri || "");
  const { onCopy: copyIpfsHash } = useClipboard(ipfsHash || "");

  const handleDownload = async () => {
    try {
      await downloadFile(id);
    } catch {}
  };

  const handleDelete = async () => {
    try {
      await deleteFile(id);

      addAlert({ status: "success", content: t("alerts.success.deleted") });
    } catch {
      addAlert({ status: "error", content: t("alerts.error.default") });
    }
  };

  return (
    <Box
      p="2"
      bg="gray.900"
      borderWidth="1px"
      borderColor="gray.800"
      borderRadius="md"
      width="176px"
      position="relative"
      onMouseEnter={setMouseOver.on}
      onMouseLeave={setMouseOver.off}
    >
      <Flex justifyContent="space-between">
        <HStack spacing="1" alignItems="center">
          <Avatar
            nickname={owner ? owner.nickname : "-"}
            colorHash={owner?.id}
            size="4"
          />
          <Text fontSize="xs" lineHeight="3">
            {owner ? owner.nickname : "N/A"}
          </Text>
        </HStack>

        <Menu placement="bottom-end">
          <MeatballsMenuButton />
          <MenuList>
            {!isOwner && (
              <MenuItem icon={<DownloadIcon />} onClick={handleDownload}>
                {t("common.download")}
              </MenuItem>
            )}
            {magnetUri && (
              <MenuItem
                icon={<CopyIcon />}
                onClick={() => {
                  copyMagnetUri();
                  addAlert({
                    status: "success",
                    content: t("alerts.success.copied"),
                  });
                }}
              >
                {t("room.files.item.copyMagnet")}
              </MenuItem>
            )}
            {ipfsHash && (
              <MenuItem
                icon={<CopyIcon />}
                onClick={() => {
                  copyIpfsHash();
                  addAlert({
                    status: "success",
                    content: t("alerts.success.copied"),
                  });
                }}
              >
                {t("room.files.item.copyHash")}
              </MenuItem>
            )}
            {(isOwner || isRoomOwner) && (
              <MenuItem icon={<DeleteFileIcon />} onClick={handleDelete}>
                {t("common.delete")}
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Flex>

      <Flex justify="center" align="center" mt="3" mb="2.5" overflow="hidden">
        <FileIcon name={name} />

        {downloaders && downloaders.length > 0 && (
          <>
            <PongLoader ml="1" mr="2.5" flexShrink="0" />
            <AvatarsStack users={downloaders.slice(0, 4)} flexShrink="0" />
          </>
        )}
      </Flex>

      <Text noOfLines={1} textAlign="center" fontWeight="medium" lineHeight="5">
        {name}
      </Text>
      <Text
        mt="1"
        noOfLines={1}
        textAlign="center"
        fontSize="xs"
        lineHeight="3"
        color="gray.300"
      >
        {prettysize(size, { places: 2 })}
      </Text>

      {/* {isDesktop && ( */}
      <Box
        position="absolute"
        right="3"
        bottom="0"
        transform="translateY(30%)"
        zIndex="2"
      >
        <Fade in={isMouseOver || !isDesktop}>
          <Box
            bg="gray.700"
            border="1px"
            borderColor="gray.600"
            borderRadius="base"
            lineHeight="3"
          >
            <IconButton
              aria-label="download"
              icon={<DownloadIcon />}
              onClick={handleDownload}
              variant="icon-ghost"
              p="2"
              h="auto"
              minW="auto"
            />
          </Box>
        </Fade>
      </Box>
      {/* )} */}
    </Box>
  );
};

export default FileItem;
