import { FC, ReactElement, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBoolean } from "@chakra-ui/react";

import RoomSocketConnectionContext from "../../contexts/RoomSocketConnectionContext";
import { useAddStatusAlert } from "../../stores/snackbarStore";
import UploadFilesModal from "./UploadFilesModal";
import useRoomCanUpload from "../../hooks/useRoomCanUpload";

export interface IUploadInputProviderProps {
  children: (props: {
    onTrigger: () => void;
    onFiles: (files: File[]) => void;
    isLoading: boolean;
    isDisabled: boolean;
  }) => ReactElement;
}

const UploadInputProvider: FC<IUploadInputProviderProps> = ({ children }) => {
  const { t } = useTranslation();
  const addAlert = useAddStatusAlert();
  const canUpload = useRoomCanUpload();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isAdding, setAdding] = useBoolean();
  const [isFilesModalOpen, setFilesModalOpen] = useBoolean();
  const { addFile } = useContext(RoomSocketConnectionContext);
  const [files, setFiles] = useState<File[]>([]);

  const handleFiles = (files: File[]) => {
    setFiles(files);
    setFilesModalOpen.on();
  };

  const uploadFiles = async (isProtected: boolean) => {
    setFilesModalOpen.off();
    setAdding.on();

    try {
      for (const file of files) {
        await addFile(file, isProtected);
      }

      addAlert({ status: "success", content: t("alerts.success.uploaded") });
    } catch {
      addAlert({ status: "error", content: t("alerts.error.default") });
    } finally {
      setAdding.off();
    }
  };

  return (
    <>
      {canUpload && (
        <input
          ref={inputRef}
          type="file"
          multiple
          onChange={(e) => handleFiles(Array.from(e.target.files!))}
          style={{ display: "none" }}
        />
      )}

      {children({
        onTrigger: () => {
          if (inputRef.current) {
            inputRef.current.value = "";
            inputRef.current.click();
          }
        },
        onFiles: handleFiles,
        isLoading: isAdding,
        isDisabled: !canUpload,
      })}

      {canUpload && (
        <UploadFilesModal
          files={files}
          onSubmit={uploadFiles}
          isOpen={isFilesModalOpen}
          onClose={setFilesModalOpen.off}
        />
      )}
    </>
  );
};

export default UploadInputProvider;
