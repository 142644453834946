import { FC } from "react";

import Panel from "../ui/Panel";
import UploadArea from "./UploadArea";

const UploadPanel: FC = () => (
  <Panel flexShrink="0">
    <UploadArea />
  </Panel>
);

export default UploadPanel;
