import React, { memo, useMemo } from "react";

import useFetchAdBanners from "../../hooks/useFetchAdBanners";
import { useAdsStore } from "../../stores/adsStore";
import { shuffle } from "../../utils/array";
import AdBanner from "../common/AdBanner";
import Slider, { SliderProps } from "../ui/Slider";

const AdsSlider: React.FC<SliderProps> = (props) => {
  const allBanners = useAdsStore((state) => state.adBanners);
  const banners = useMemo(
    () => (allBanners ? shuffle(allBanners) : []),
    [allBanners]
  );

  useFetchAdBanners();

  return banners.length > 0 ? (
    <Slider {...props}>
      {banners.map((banner) => (
        <AdBanner key={banner.id} scriptId={banner.id} html={banner.html} />
      ))}
    </Slider>
  ) : null;
};

export default memo(AdsSlider);
