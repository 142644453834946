import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { HStack, StackProps, Link } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const HomeNavLinks: FC<StackProps> = (props) => {
  const { t } = useTranslation();
  const navLinks = t("home.header.navLinks", { returnObjects: true }) as {
    label: string;
    href: string;
  }[];

  return (
    <HStack spacing="8" {...props}>
      {navLinks.map(({ label, href }, i) =>
        (href.startsWith("/") && !href.startsWith("//")) ||
        href.startsWith("#") ? (
          <Link
            key={i}
            as={RouterLink}
            to={href}
            onClick={() => {
              document.getElementById(href.slice(1))?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }}
            fontSize="medium"
            color="gray.500"
            whiteSpace="nowrap"
          >
            {label}
          </Link>
        ) : (
          <Link
            key={i}
            href={href}
            fontSize="medium"
            color="gray.500"
            whiteSpace="nowrap"
          >
            {label}
          </Link>
        )
      )}
    </HStack>
  );
};

export default HomeNavLinks;
